import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import type { AxiosError } from "axios";
import type { DateTime } from "luxon";
import { DateText } from "@cpt/components";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import { isTrial } from "utils/isTrial";
import type { CloudLicensingChannelTypes, CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { cancelCloudSubscription, isTrialLicensingChannel } from "client/api/CloudSubscriptionApi";
import { cancelCloudTrial } from "client/api/TrialApi";
import { AlertSeverity, useAlerts } from "components/AlertProvider/AlertProvider";
import { ErrorAlert } from "components/alert/ErrorAlert";

type CloudSubscriptionCancelPlanDialogProps = React.PropsWithChildren<{
  open: boolean;
  channelType: CloudLicensingChannelTypes;
  onClose: () => void;
  cloudSubscription: CloudSubscriptionDto;
}>;

export function CloudSubscriptionCancelPlanDialog(props: CloudSubscriptionCancelPlanDialogProps) {
  const { update, refresh } = useEntity<CloudSubscriptionDto>();
  const { open, onClose, cloudSubscription, channelType } = props;
  const { sendNewAlert } = useAlerts();

  const productTypeName = isTrial(channelType) ? "trial" : "subscription";

  const [isCancelling, setIsCancelling] = useState<boolean>(false);

  const [error, setError] = useState<AxiosError | undefined>(undefined);

  const cancelPlan = async () => {
    setIsCancelling(true);

    try {
      if (isTrialLicensingChannel(cloudSubscription.licensingChannel)) {
        await cancelCloudTrial(cloudSubscription.licensingChannel.trialId);
        if (refresh) refresh();
      } else {
        const changedCloudSubscription = await cancelCloudSubscription(cloudSubscription);
        update(changedCloudSubscription);
      }

      sendNewAlert(`Cloud ${productTypeName} cancelled`, AlertSeverity.Success);
      handleOnClose(true);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setError(e);
      } else {
        console.error(e);
      }
    } finally {
      setIsCancelling(false);
    }
  };

  const handleOnClose = (force = false) => {
    if (isCancelling && !force) {
      return;
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleOnClose()}
      aria-labelledby="cancel-subscription-dialog-title"
      aria-describedby="cancel-subscription-dialog-description"
    >
      <DialogTitle id="cancel-subscription-dialog-title">Cancel {productTypeName}?</DialogTitle>
      <DialogContent id="cancel-subscription-dialog-description">
        {error && <ErrorAlert error={error} />}
        <DialogContentText>{props.children}</DialogContentText>
        <DialogContentText sx={{ mt: 2 }}>
          If you change your mind within 90 days, you can re-subscribe, and we will reactivate your instance from the
          secure backups.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOnClose()} variant="outlined" disabled={isCancelling} autoFocus>
          Close
        </Button>
        <LoadingButton
          onClick={cancelPlan}
          variant="contained"
          color="error"
          loading={isCancelling}
          loadingIndicator={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="inherit" size={16} sx={{ mr: 1 }} />
              {"Cancelling..."}
            </Box>
          }
        >
          Cancel {productTypeName}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export const CloudSubscriptionCancelPlanDialogText = ({
  channelType,
  endDate,
}: {
  channelType: CloudLicensingChannelTypes;
  endDate: DateTime;
}) => {
  const subscriptionVerb = isTrial(channelType) ? "trialing" : "subscribed";
  return (
    <span>
      You are currently {subscriptionVerb} until <DateText date={endDate} fontWeight="bold" />. If you cancel now, your
      instance will be immediately deactivated. After 90 days, your data will be automatically deleted. If you want your
      data deleted sooner, email <a href="mailto:support@octopus.com">support@octopus.com</a>, and we'll delete your
      data immediately.
    </span>
  );
};
