import React from "react";
import { Grid, Typography } from "@mui/material";
import { useContactSalesDialog } from "components/Dialogs/ContactSalesDialog/ContactSalesDialogProvider";
import { LinkButton } from "components/LinkButton/LinkButton";

export function ChangePlanFinanceLine() {
  const { showDialog } = useContactSalesDialog();
  return (
    <Grid container data-testid={"change-plan-finance-line"}>
      <Grid item sm={3} sx={{ padding: 3 }}>
        <Typography fontWeight={700}>Change plan</Typography>
      </Grid>
      <Grid item sm={6} sx={{ padding: 3 }}>
        <Typography>Contact our sales team to make changes to your subscription</Typography>
      </Grid>
      <Grid item sm={3} sx={{ textAlign: "right", padding: 3 }}>
        <LinkButton onClick={() => showDialog({ reason: "modify-subscription" })}>Get in touch</LinkButton>
      </Grid>
    </Grid>
  );
}
