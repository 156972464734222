import { dangerPercentage, warningPercentage } from "./fileUsageThresholds";

export type AlertLevel = "none" | "warning" | "danger";

export function getAlertLevel(usageInGb: number, limitInGb: number): AlertLevel {
  const percentageUsed = (usageInGb / limitInGb) * 100;
  if (percentageUsed >= dangerPercentage) {
    return "danger";
  }
  if (percentageUsed >= warningPercentage) {
    return "warning";
  }
  return "none";
}
