import React from "react";
import type { PropsWithChildren } from "react";
import type { SxProps, Theme } from "@mui/material";
import { Container, Paper } from "@mui/material";

export function PaperLayout(props: PropsWithChildren<{ elevation?: number; sx?: SxProps<Theme> }>) {
  const { children, elevation = 3, sx } = props;
  return (
    <Container disableGutters={true} sx={sx}>
      <Paper elevation={elevation} square={false}>
        {children}
      </Paper>
    </Container>
  );
}
