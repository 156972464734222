import React from "react";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Typography } from "@mui/material";
import { RoutesMap } from "RoutesMap";
import { GreenButton } from "components/Button/GreenButton";
import { themeTokens } from "components/Theme/theme";
import Rocket from "resources/images/svgs/rocket.svg";

export interface TrialFulfillmentSuccessProps {
  href: string;
  className?: string;
  style?: React.CSSProperties;
}

export function TrialFulfillmentSuccess(props: TrialFulfillmentSuccessProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap="32px"
      className={props.className}
      style={props.style}
    >
      <Box component="img" src={Rocket} alt={"Success"} sx={{ maxWidth: { xs: "256px", md: "256px" } }} />
      <Typography variant="h4">Your Octopus is ready!</Typography>
      <GreenButton href={props.href} endIcon={<ChevronRightIcon />} sx={{ minWidth: "8em", fontSize: "1.2em" }}>
        Let's Go
      </GreenButton>
      <Typography mt={4}>
        Need to manage your account? Visit the{" "}
        <Link to={RoutesMap.root} target={"_blank"} style={{ color: themeTokens.color.text.link.default }}>
          Control Center
        </Link>
      </Typography>
    </Box>
  );
}
