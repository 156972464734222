import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import useAsyncRequest from "hooks/useAsyncRequest";
import { getCloudSubscriptionBySerial } from "client/api/CloudSubscriptionApi";

export function CloudSubscriptionLoadViaSerial() {
  const { serialId } = useParams();

  const { data, loading, error, refresh } = useAsyncRequest(getCloudSubscriptionBySerial, serialId || "");

  const navigate = useNavigate();

  if (data) {
    navigate(`/cloud-subscriptions/${data.id}`);
  }

  if (error) {
    return <Alert severity={"error"}>{error.message}</Alert>;
  }

  return <></>;
}
