import Cookies from "js-cookie";
import { envConfig } from "utils/envConfig";

export const getAccessToken = () => {
  if (envConfig.Environment === "LocalMockAuth") {
    return "mock-access-token";
  }
  const accessToken = Cookies.get("CommercialBillingPortalOctopusIdAuth");

  if (!accessToken) {
    window.location.href = `/auth?returnUrl=${window.location.pathname}${window.location.search}`;
  }

  return accessToken;
};
