import * as React from "react";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { designSystemColors } from "@cpt/shared";
import { themeTokens } from "components/Theme/theme";
import { dangerPercentage, warningPercentage } from "./fileUsageThresholds";

const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: 10,
  borderRadius: 5,
  width: 200,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: getTrackColor(value ?? 0),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: getBarColor(value ?? 0),
  },
}));

export default function FileUsageBar({ percentageUsed }: { percentageUsed: number }) {
  return <BorderLinearProgress variant="determinate" value={percentageUsed} role="resource-usage" />;
}

function getTrackColor(percentageUsed: number) {
  if (percentageUsed >= dangerPercentage) {
    return themeTokens.color.chip.filled.background.danger;
  }
  if (percentageUsed >= warningPercentage) {
    return designSystemColors.orange["200"];
  }
  return themeTokens.color.chip.filled.background.info;
}

function getBarColor(percentageUsed: number) {
  if (percentageUsed >= dangerPercentage) {
    return themeTokens.color.callout.border.danger;
  }
  if (percentageUsed >= warningPercentage) {
    return designSystemColors.orange["400"];
  }
  return themeTokens.color.callout.border.info;
}
