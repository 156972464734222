import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { DateText } from "@cpt/components";
import type { CloudSubscriptionDto, ZuoraCloudSubscriptionLicensingChannelDto } from "client/api/CloudSubscriptionApi";
import { ChangePlanFinanceLine } from "components/ChangePlanFinanceLine/ChangePlanFinanceLine";
import { GridDivider } from "components/GridDivider/GridDivider";
import { LicensedEntitlementsView } from "components/LicensedEntitlementsView/LicensedEntitlementsView";
import { OrdersFinanceLine } from "components/OrdersFinanceLine/OrdersFinanceLine";
import { themeTokens } from "components/Theme/theme";
import type { LicensingChannelViewProps } from "areas/cloudSubscriptions/detail/components/finance/LicensingChannelViewProps";

type EntitlementViewProps = { cloudSubscription: CloudSubscriptionDto };
function EntitlementView(props: EntitlementViewProps) {
  const { cloudSubscription } = props;
  return <LicensedEntitlementsView license={cloudSubscription} />;
}

export function ZuoraLicensingChannelView(props: LicensingChannelViewProps<ZuoraCloudSubscriptionLicensingChannelDto>) {
  const { channel, cloudSubscription } = props;
  const planStatusPrefix = channel.cancelAtPeriodEnd ? "Cancels on" : "License expiry";
  return (
    <>
      <Grid item sm={3} sx={{ padding: 3 }}>
        <Typography fontWeight={700}>Platform and licensing</Typography>
      </Grid>
      <Grid item sm={9} sx={{ padding: 3 }}>
        <Stack spacing={0}>
          <Typography>Cloud</Typography> {/* TODO: get actual plan name (sc-94081) */}
          <Typography sx={{ color: themeTokens.color.text.secondary }}>
            {planStatusPrefix} <DateText date={channel.nextRenewalDate} />
          </Typography>
        </Stack>
      </Grid>
      <GridDivider />
      <ChangePlanFinanceLine />
      <GridDivider />
      <EntitlementView cloudSubscription={cloudSubscription} />
      <GridDivider />
      <OrdersFinanceLine cloudSubscription={cloudSubscription} />
    </>
  );
}
