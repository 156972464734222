import React from "react";
import { Alert } from "@mui/material";
import { ActionDialog } from "components/ActionDialog/ActionDialog";

interface DeleteSubscriptionGroupDialogProps {
  groupName: string;
  open: boolean;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
}

export function DeleteSubscriptionGroupDialog(props: DeleteSubscriptionGroupDialogProps) {
  return (
    <ActionDialog
      title={"Delete subscription group"}
      description={`Are you sure you want to delete "${props.groupName}"?`}
      show={props.open}
      confirmText={"Delete"}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
    >
      <Alert sx={{ marginY: 2 }} severity={"warning"}>
        This will remove all existing user access for this subscription group
      </Alert>
    </ActionDialog>
  );
}
