import axiosClient from "client/axios-client";

const BASE_URL = "contact-requests";

export type ContactRequestSource = "ControlCenterCloud" | "ControlCenterSelfHosted" | "Dashboard";

type CreateContactRequestDto = {
  name: string;
  email: string;
  phone: string;
  reason: string;
  message: string;
  source: ContactRequestSource;
  licenseSerial?: string;
  projectQuantity?: string;
};

export const createContactRequest = async (request: CreateContactRequestDto): Promise<void> => {
  await axiosClient.post(`${BASE_URL}/sales`, request);
};
