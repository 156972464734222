import React from "react";
import { Grid, Stack, styled } from "@mui/material";
import { colorScales } from "@octopusdeploy/design-system-tokens";
import { GridDivider } from "components/GridDivider/GridDivider";
import { themeTokens } from "components/Theme/theme";

const InstallationStepTitle = styled("span")`
  font-weight: 700;
  font-size: 17px;
  color: ${themeTokens.color.text.primary};
`;

const InstallationStepTextContainer = styled("span")`
  color: ${colorScales.slate[600]};
  font-weight: 400;
  font-size: 16px;
`;

export function InstallationStepText(props: { children: React.ReactNode }) {
  return (
    <Grid item xs={12}>
      <InstallationStepTextContainer>{props.children}</InstallationStepTextContainer>
    </Grid>
  );
}

interface InstallationStepProps {
  stepNumber: number;
  label: string;
  children: React.ReactNode;
}

export function InstallationStep(props: InstallationStepProps) {
  const { label, children, stepNumber } = props;
  return (
    <Grid container item paddingLeft={2}>
      <Grid item xs="auto" paddingRight={2}>
        <InstallationStepTitle>{stepNumber}.</InstallationStepTitle>
      </Grid>
      <Grid item container xs={11}>
        <Grid item xs={12}>
          <InstallationStepTitle>{label}</InstallationStepTitle>
        </Grid>
        <Grid container item rowSpacing={2} paddingTop={2}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}

interface InstallGuideProps {
  children: React.ReactNode;
}
export function InstallGuide(props: InstallGuideProps) {
  const { children } = props;
  return (
    <Stack divider={<GridDivider />} spacing={2}>
      {children}
    </Stack>
  );
}
