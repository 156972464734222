import React from "react";
import { Route } from "react-router-dom";
import { cloudSubscriptionUserGrantsApi } from "client/api/UserGrantApi";
import { ProtectedRoute } from "components/authorization/ProtectedRoute";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { InviteAcceptPage } from "pages/InviteAcceptPage";
import { CloudSubscriptionLayout } from "areas/cloudSubscriptions/CloudSubscriptionLayout";
import { CloudSubscriptionsRoutesMap } from "areas/cloudSubscriptions/CloudSubscriptionRoutesMap";
import { CloudSubscriptionConfiguration } from "areas/cloudSubscriptions/detail/CloudSubscriptionConfiguration";
import { CloudSubscriptionFinance } from "areas/cloudSubscriptions/detail/CloudSubscriptionFinance";
import { CloudSubscriptionLoadViaSerial } from "areas/cloudSubscriptions/detail/CloudSubscriptionLoadViaSerial";
import { CloudSubscriptionOverview } from "areas/cloudSubscriptions/detail/CloudSubscriptionOverview";
import { CloudSubscriptionUpgrade } from "areas/cloudSubscriptions/detail/CloudSubscriptionUpgrade";
import { CloudSubscriptionUsers } from "areas/cloudSubscriptions/detail/CloudSubscriptionUsers";

export const cloudSubscriptionRoutes = () => {
  return (
    <Route path={CloudSubscriptionsRoutesMap.root}>
      <Route
        path={CloudSubscriptionsRoutesMap.detail(":cloudSubscriptionId").root}
        element={<CloudSubscriptionLayout />}
      >
        <Route index element={<CloudSubscriptionOverview />} />
        <Route
          path={CloudSubscriptionsRoutesMap.detail(":cloudSubscriptionId").finance}
          element={
            <ProtectedRoute
              permission={RbacPermissions.CloudSubscription.ViewBilling}
              element={<CloudSubscriptionFinance />}
            />
          }
        />
        <Route
          path={CloudSubscriptionsRoutesMap.detail(":cloudSubscriptionId").upgrade}
          element={
            <ProtectedRoute
              permission={RbacPermissions.CloudSubscription.ManageBilling}
              element={<CloudSubscriptionUpgrade />}
            />
          }
        />
        <Route
          path={CloudSubscriptionsRoutesMap.detail(":cloudSubscriptionId").users}
          element={
            <ProtectedRoute
              permission={RbacPermissions.CloudSubscription.ViewAccessControl}
              element={<CloudSubscriptionUsers userGrantsApi={cloudSubscriptionUserGrantsApi} />}
            />
          }
        />
        <Route
          path={CloudSubscriptionsRoutesMap.detail(":cloudSubscriptionId").configuration}
          element={
            <ProtectedRoute
              permission={RbacPermissions.CloudSubscription.ViewConfiguration}
              element={<CloudSubscriptionConfiguration />}
            />
          }
        />
      </Route>
      <Route
        path={CloudSubscriptionsRoutesMap.loadBySerial(":serialId").root}
        element={<CloudSubscriptionLoadViaSerial />}
      />
      <Route
        path={CloudSubscriptionsRoutesMap.inviteAccept(":encodedInvite")}
        element={<InviteAcceptPage userGrantsApi={cloudSubscriptionUserGrantsApi} />}
      />
    </Route>
  );
};
