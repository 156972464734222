import React from "react";
import { Card, styled, Typography } from "@mui/material";
import PopoverHelp from "components/PopoverHelp/PopoverHelp";
import FileUsageBar from "./FileUsageBar";
import { FileUsageWarningChip } from "./FileUsageWarningChip";
import { FileUsageWarningContent } from "./FileUsageWarningContent";
import { InfoIcon } from "./InfoIcon";
import { ResourceUsageCopy } from "./ResourceUsageCopy";
import { getAlertLevel } from "./getAlertLevel";

type ResourceUsageCardProps = {
  limitInGb?: number;
  usageInGb?: number;
};

export function ResourceUsageCard({ usageInGb, limitInGb }: ResourceUsageCardProps) {
  if (limitInGb === undefined || usageInGb === undefined) return null;
  const percentageUsed = (usageInGb / limitInGb) * 100;
  const alertLevel = getAlertLevel(usageInGb, limitInGb);

  return (
    <Card sx={{ padding: "16px", flexGrow: 1, display: "flex", alignItems: "center", flexDirection: "column" }}>
      <Heading>
        <span style={{ marginRight: "8px" }}>Resource Usage</span>
        <PopoverHelp
          trigger={"click"}
          placement={"bottom-end"}
          button={alertLevel == "none" ? <InfoIcon /> : <FileUsageWarningChip alertLevel={alertLevel} />}
        >
          <FileUsageWarningContent alertLevel={alertLevel} />
        </PopoverHelp>
      </Heading>

      <Typography margin="16px 0 12px">
        <ResourceUsageCopy limitInGb={limitInGb} usageInGb={usageInGb} />
      </Typography>
      <FileUsageBar percentageUsed={percentageUsed} />
    </Card>
  );
}

const Heading = styled("div")({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "flex-end",
});
