export enum UpgradePlan {
  Trial = "trial",
  Cloud_Monthly_LegacyFree = "oc-monthly-legacy-free",
  Cloud_Monthly_Professional = "oc-monthly-professional",
  Cloud_Monthly_Community = "oc-monthly-community",
  Cloud_Annually_Starter = "oc-annually-starter",
  Cloud_Annually_Professional = "oc-annually-professional",
  Cloud_Annually_Enterprise = "oc-annually-enterprise",
  Server_Annually_Starter = "os-annually-starter",
  Server_Annually_Professional_Oct_21 = "os-annually-professional-oct21-legacy",
  Server_Annually_Professional = "os-annually-professional",
  Server_Annually_Enterprise = "os-annually-enterprise",
}
