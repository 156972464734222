import React from "react";
import { Button, Container, Grid, styled } from "@mui/material";
import { useContactSalesDialog } from "components/Dialogs/ContactSalesDialog/ContactSalesDialogProvider";
import IconMachine from "resources/images/svgs/icon_machine_boxed.svg";
import IconSupport from "resources/images/svgs/icon_support_boxed.svg";
import IconTam from "resources/images/svgs/icon_tam_boxed.svg";
import IconTenant from "resources/images/svgs/icon_tenant_boxed.svg";

const SectionTitle = styled("h2")(
  ({ theme }) => `
  margin: 0;
  margin-bottom: 1.8rem;
  text-align: center;
  font-size: 1.8rem;
  font-weight: ${theme.typography.fontWeightBold};
`
);

const AddOnCard = styled("div")(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    height: 100%;
    background: ${theme.palette.background.paper};
    border-radius: 9px;
  `
);

const AddOnCardHeader = styled("div")(
  ({ theme }) => `
    display: flex;
    padding: 16px;
    border-bottom: 1px solid #E6E8EA;
  `
);

const AddOnCardIconWrapper = styled("div")(
  ({ theme }) => `
    width: 53px;
  `
);

const AddOnCardTitle = styled("p")(
  ({ theme }) => `
    margin: 0px;
    margin-left: 20px;
    margin-bottom: 5px;
    align-self: center;
    font-size: 1.25rem;
    font-family: ${theme.typography.fontFamily};
    font-weight: ${theme.typography.fontWeightBold};
    line-height: 1.3;
  `
);

const AddOnCardDesc = styled("p")(
  ({ theme }) => `
    margin-bottom: 0.5rem;
    font-family: ${theme.typography.fontFamily};
    font-weight: ${theme.typography.fontWeightRegular};
    line-height: 1.6;
    color: #586978;
  `
);

const AddOnCardPrice = styled("p")(
  ({ theme }) => `
    margin-bottom: 0;
    font-size: 1.25rem;
    font-family: ${theme.typography.fontFamily};
    font-weight: ${theme.typography.fontWeightBold};
    line-height: 1.6;
  `
);

const AddOnCardPricePer = styled("span")(
  ({ theme }) => `
    font-size: 1rem;
  `
);

const AddOnCardContactSalesHeading = styled("p")(
  ({ theme }) => `
    margin-top: calc(.75rem + .25rem);
    margin-bottom: 0;
    font-size: 1rem;
    font-family: ${theme.typography.fontFamily};
    font-weight: ${theme.typography.fontWeightBold};
    line-height: 1.6;
  `
);

const AddOnCardBody = styled("div")(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    padding: 16px;
    padding-top: 0px;
  `
);

const AddOnsCallToActionSection = styled("div")(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    margin-top: 14px;
  `
);

export const AddOns = () => {
  const { showDialog } = useContactSalesDialog();
  return (
    <Container disableGutters sx={{ paddingTop: "40px", paddingBottom: "40px", background: "#ECF0F4" }}>
      <SectionTitle>Add-ons</SectionTitle>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12}>
          <Grid container spacing={1} padding={0}>
            <Grid item xs={12} md={3}>
              <AddOnCard>
                <AddOnCardHeader>
                  <AddOnCardIconWrapper>
                    <img src={IconTenant} width="53" height="53" />
                  </AddOnCardIconWrapper>
                  <AddOnCardTitle>Tenants</AddOnCardTitle>
                </AddOnCardHeader>
                <AddOnCardBody>
                  <AddOnCardPrice>
                    $72 <AddOnCardPricePer>per tenant / year</AddOnCardPricePer>
                  </AddOnCardPrice>
                  <AddOnCardDesc>
                    Eliminate pipeline sprawl by modelling the physical locations you deploy to, like retail stores, or
                    customer-specific cloud resources and configuration.
                  </AddOnCardDesc>
                </AddOnCardBody>
              </AddOnCard>
            </Grid>
            <Grid item xs={12} md={3}>
              <AddOnCard>
                <AddOnCardHeader>
                  <AddOnCardIconWrapper>
                    <img src={IconMachine} width="53" height="53" />
                  </AddOnCardIconWrapper>
                  <AddOnCardTitle>Machines</AddOnCardTitle>
                </AddOnCardHeader>
                <AddOnCardBody>
                  <AddOnCardPrice>
                    $72 <AddOnCardPricePer>per machine / year</AddOnCardPricePer>
                  </AddOnCardPrice>
                  <AddOnCardDesc>
                    Deploy to Linux, Windows, and other application hosts easily with our bi-directional Tentacle
                    deployment agent and SSH endpoints. Volume pricing available.
                  </AddOnCardDesc>
                </AddOnCardBody>
              </AddOnCard>
            </Grid>
            <Grid item xs={12} md={3}>
              <AddOnCard id="addon-priority-support">
                <AddOnCardHeader>
                  <AddOnCardIconWrapper>
                    <img src={IconSupport} width="53" height="53" />
                  </AddOnCardIconWrapper>
                  <AddOnCardTitle>Priority Support</AddOnCardTitle>
                </AddOnCardHeader>
                <AddOnCardBody>
                  <AddOnCardContactSalesHeading>Contact Sales for details</AddOnCardContactSalesHeading>
                  <AddOnCardDesc>
                    Priority Support is a quick response addition to your Octopus Enterprise license. We aim to resolve
                    your high-priority issues in the fastest time possible.
                  </AddOnCardDesc>
                </AddOnCardBody>
              </AddOnCard>
            </Grid>
            <Grid item xs={12} md={3}>
              <AddOnCard id="addon-tam">
                <AddOnCardHeader>
                  <AddOnCardIconWrapper>
                    <img src={IconTam} width="53" height="53" />
                  </AddOnCardIconWrapper>
                  <AddOnCardTitle>TAM Services</AddOnCardTitle>
                </AddOnCardHeader>
                <AddOnCardBody>
                  <AddOnCardContactSalesHeading>Contact Sales for details</AddOnCardContactSalesHeading>
                  <AddOnCardDesc>
                    Keep your Octopus instance and DevOps processes performing at their best with expert advice from our
                    Technical Account Managers (TAMs).
                  </AddOnCardDesc>
                </AddOnCardBody>
              </AddOnCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AddOnsCallToActionSection>
        <Button
          variant="outlined"
          sx={{ width: "180px" }}
          onClick={() => showDialog({ reason: "modify-subscription" })}
        >
          Contact Sales
        </Button>
      </AddOnsCallToActionSection>
    </Container>
  );
};
