import React from "react";
import type { ReactNode } from "react";
import ReplayIcon from "@mui/icons-material/Replay";
import { Typography, Alert, AlertTitle, Link, Button, styled } from "@mui/material";
import { ExternalUriMap } from "utils/ExternalUriMap";

const RetryButton = styled(Button)`
  text-transform: none;
  background-color: rgba(26, 119, 202, 0.06);
  color: #1a77ca;
  font-weight: 700;
  line-height: 24px;
  height: 44px;

  &.MuiButton-root:hover,
  &.MuiButton-root:focus {
    background-color: rgba(26, 119, 202, 0.16);
  }
`;

export const ErrorMessageWithTryAgainButtonOrContactSales = ({ retry }: { retry?: () => void }) => {
  return (
    <>
      <Typography variant="subtitle1" gutterBottom component="div">
        Please try again. If you need help, please contact our friendly{" "}
        <Link target="_blank" rel="noopener" href="mailto:sales@octopus.com">
          sales
        </Link>{" "}
        team
      </Typography>
      {retry && (
        <RetryButton onClick={retry}>
          <ReplayIcon />
          Retry
        </RetryButton>
      )}
    </>
  );
};

export const ErrorMessageTryAgainOrContactSupport = () => {
  return (
    <Typography variant="subtitle1" gutterBottom component="div">
      Please try again. If you need help, please contact our friendly{" "}
      <Link
        underline={"none"}
        fontWeight={500}
        target="_blank"
        rel="noopener"
        href={ExternalUriMap.octopusComSupportRootPath}
      >
        support
      </Link>{" "}
      team
    </Typography>
  );
};

export const ErrorMessageContactSupport = () => {
  return (
    <Typography variant="subtitle1" gutterBottom component="div">
      Please contact our friendly{" "}
      <Link
        underline={"none"}
        fontWeight={500}
        target="_blank"
        rel="noopener"
        href={ExternalUriMap.octopusComSupportRootPath}
      >
        support
      </Link>{" "}
      team
    </Typography>
  );
};

export const CustomErrorAlert = ({
  title = "Something went wrong!",
  message,
}: {
  title?: ReactNode;
  message: ReactNode;
}) => {
  return (
    <Alert severity="error">
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  );
};
