import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { envConfig } from "utils/envConfig";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { changeCloudSubscriptionDnsPrefix } from "client/api/CloudSubscriptionApi";
import { SubmittableForm } from "components/form/SubmittableForm";

type CloudSubscriptionNewDnsPrefixDialogProps = {
  cloudSubscription?: CloudSubscriptionDto;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  open: boolean;
};

type CloudSubscriptionNewDnsPrefixDialogSubmitArgs = {
  dnsPrefix: string;
};

const dnsPrefixOptions = {
  required: {
    value: true,
    message: "Please enter new URL",
  },
};

export function CloudSubscriptionNewDnsPrefixDialog(props: CloudSubscriptionNewDnsPrefixDialogProps) {
  const { cloudSubscription, onClose, onConfirm, open } = props;

  if (!cloudSubscription) return <></>;

  const changeDnsPrefix = async (args: CloudSubscriptionNewDnsPrefixDialogSubmitArgs) => {
    await changeCloudSubscriptionDnsPrefix(cloudSubscription.id, args.dnsPrefix);
    await onConfirm();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        Change instance URL
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item xs={12}>
            <Alert severity="warning">
              Changing the URL of your instance will cause it to go offline momentarily.
              <br /> It will be back up and running typically within 3 - 10 minutes.
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <SubmittableForm submitAction={changeDnsPrefix} defaultValues={{ dnsPrefix: "" }}>
              {(formController) => {
                const { register, formState } = formController;
                return (
                  <Grid item xs={12}>
                    <TextField
                      id={"dns-prefix"}
                      required
                      fullWidth
                      label={"New URL"}
                      InputProps={{
                        endAdornment: <InputAdornment position={"end"}>.{envConfig.OctopusCloudDomain}</InputAdornment>,
                      }}
                      error={!!formState?.errors?.dnsPrefix}
                      helperText={formState?.errors?.dnsPrefix?.message}
                      {...register("dnsPrefix", dnsPrefixOptions)}
                    />
                  </Grid>
                );
              }}
            </SubmittableForm>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
