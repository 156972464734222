import React from "react";
import { Box, Grid, Typography } from "@mui/material";

type PricingCardTitleProps = {
  title: string;
};

export function PricingCardTitle(props: PricingCardTitleProps) {
  const { title } = props;

  return (
    <>
      <Grid item xs={12}>
        <Box
          display="flex"
          sx={{ flexDirection: { xs: "column", md: "row" }, alignItems: { xs: "start", md: "center" } }}
        >
          <Typography fontWeight="fontWeightBold" component="h2" color={"#0D80D8"} fontSize={"1.375rem"}>
            {title}
          </Typography>
        </Box>
      </Grid>
    </>
  );
}
