import { useEffect, useState } from "react";

export function usePageTitle(initialTitle: string) {
  const [title, setTitle] = useState<string>(initialTitle);

  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  return setTitle;
}
