import React from "react";
import { Route } from "react-router-dom";
import { ProtectedRoute } from "components/authorization/ProtectedRoute";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { ServerLicenseLayout } from "areas/serverLicenses/ServerLicenseLayout";
import { ServerLicensesRoutesMap } from "areas/serverLicenses/ServerLicensesRoutesMap";
import { ServerLicenseFinance } from "areas/serverLicenses/detail/ServerLicenseFinance";
import { ServerLicenseLoadViaSerial } from "areas/serverLicenses/detail/ServerLicenseLoadViaSerial";
import { ServerLicenseOverview } from "areas/serverLicenses/detail/ServerLicenseOverview";
import { ServerLicenseUpgrade } from "areas/serverLicenses/detail/ServerLicenseUpgrade";

export const serverLicenseRoutes = () => {
  return (
    <Route path={ServerLicensesRoutesMap.root}>
      <Route path={ServerLicensesRoutesMap.detail(":serverLicenseId").root} element={<ServerLicenseLayout />}>
        <Route index element={<ServerLicenseOverview />} />
        <Route
          path={ServerLicensesRoutesMap.detail(":serverLicenseId").finance}
          element={
            <ProtectedRoute permission={RbacPermissions.ServerLicense.ViewBilling} element={<ServerLicenseFinance />} />
          }
        />
        <Route
          path={ServerLicensesRoutesMap.detail(":serverLicenseId").upgrade}
          element={
            <ProtectedRoute
              permission={RbacPermissions.ServerLicense.ManageBilling}
              element={<ServerLicenseUpgrade />}
            />
          }
        />
      </Route>
      <Route path={ServerLicensesRoutesMap.loadBySerial(":serialId").root} element={<ServerLicenseLoadViaSerial />} />
    </Route>
  );
};
