import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton } from "@mui/material";
import type { DateTime } from "luxon";
import { Duration } from "luxon";
import { isDurationGreaterThan } from "utils/Validation/isDurationGreaterThan";
import { nameof } from "utils/nameof";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { updateCloudSubscriptionOutageWindow } from "client/api/CloudSubscriptionApi";
import { FormTimePicker } from "components/form/FormTimePicker";
import { SubmittableForm } from "components/form/SubmittableForm";

type CloudSubscriptionOutageWindowDialogProps = {
  cloudSubscription?: CloudSubscriptionDto;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  open: boolean;
};

type CloudSubscriptionOutageWindowUpdateInputs = {
  outageWindowStart: DateTime;
  outageWindowEnd: DateTime;
};

const baseTimeOptions = {
  required: {
    value: true,
    message: "Please enter or select a time.",
  },
  validate: {
    validDate: (v: DateTime) => v.isValid || "Please enter a valid time.",
  },
};

export function CloudSubscriptionOutageWindowDialog(props: CloudSubscriptionOutageWindowDialogProps) {
  const { cloudSubscription, onClose, onConfirm, open } = props;

  if (!cloudSubscription) return <></>;

  const handleFormSubmit = async (inputs: CloudSubscriptionOutageWindowUpdateInputs) => {
    await updateCloudSubscriptionOutageWindow(cloudSubscription.id, inputs.outageWindowStart, inputs.outageWindowEnd);
    await onConfirm();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        Set maintenance window
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item xs={12}>
            <DialogContentText>
              Let us know a suitable window where you are unlikely to be using your Octopus instance, and we'll use this
              time to perform updates to the Octopus Deploy software, so you can take advantage of fixes and
              enhancements.
            </DialogContentText>
          </Grid>
          <Grid item xs={12}>
            <Alert severity="warning">A minimum of 2 hours is required.</Alert>
          </Grid>
          <Grid item xs={12}>
            <SubmittableForm
              submitAction={handleFormSubmit}
              defaultValues={{
                outageWindowStart: cloudSubscription.outageWindowStart.toLocal(),
                outageWindowEnd: cloudSubscription.outageWindowEnd.toLocal(),
              }}
            >
              {(formController) => {
                const { watch } = formController;
                const currentOutageWindowStart = watch("outageWindowStart");
                const currentOutageWindowEnd = watch("outageWindowEnd");

                return (
                  <>
                    <Grid item xs={6}>
                      <FormTimePicker
                        label="Maintenance window start"
                        name={nameof<CloudSubscriptionOutageWindowUpdateInputs>("outageWindowStart")}
                        defaultValue={cloudSubscription.outageWindowStart.toLocal()}
                        rules={{
                          ...baseTimeOptions,
                          deps: [nameof<CloudSubscriptionOutageWindowUpdateInputs>("outageWindowEnd")],
                          validate: {
                            ...baseTimeOptions.validate,
                            lessThanEndTime: (v: DateTime) => {
                              return (
                                !currentOutageWindowEnd ||
                                isDurationGreaterThan(
                                  v,
                                  currentOutageWindowEnd,
                                  Duration.fromObject({
                                    hours: 1,
                                    minute: 59,
                                  })
                                ) ||
                                "Maintenance window start time should be at least 2 hours before the maintenance window ends."
                              );
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormTimePicker
                        label="Maintenance window end"
                        name={nameof<CloudSubscriptionOutageWindowUpdateInputs>("outageWindowEnd")}
                        defaultValue={cloudSubscription.outageWindowEnd.toLocal()}
                        rules={{
                          ...baseTimeOptions,
                          deps: [nameof<CloudSubscriptionOutageWindowUpdateInputs>("outageWindowStart")],
                          validate: {
                            ...baseTimeOptions.validate,
                            greaterThanStartTime: (v: DateTime) => {
                              return (
                                !currentOutageWindowStart ||
                                isDurationGreaterThan(
                                  currentOutageWindowStart,
                                  v,
                                  Duration.fromObject({
                                    hours: 1,
                                    minutes: 59,
                                  })
                                ) ||
                                "Maintenance window end time should be at least 2 hours after the maintenance window starts."
                              );
                            },
                          },
                        }}
                      />
                    </Grid>
                  </>
                );
              }}
            </SubmittableForm>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
