import React, { useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { LinkButton } from "components/LinkButton/LinkButton";
import { PolicyAuthorized } from "components/authorization/PolicyAuthorized";
import type { CloudSubscriptionProps } from "areas/cloudSubscriptions/CloudSubscriptionProps";
import { ChangeCustomerSpecifiedMachineLimitDialog } from "areas/cloudSubscriptions/detail/components/finance/ChangeCustomerSpecifiedMachineLimitDialog";

export function hasCustomerSpecifiedMachineLimit(cloudSubscription: CloudSubscriptionDto): boolean {
  return !!cloudSubscription.customerSpecifiedMachineLimit && cloudSubscription.customerSpecifiedMachineLimit > 0;
}

type CustomerSpecifiedMachineLimitLineProps = CloudSubscriptionProps & {
  editable: boolean;
};

export function CustomerSpecifiedMachineLimitLine(props: CustomerSpecifiedMachineLimitLineProps) {
  const { cloudSubscription, editable } = props;
  const [showChangeLimitDialog, setShowChangeLimitDialog] = useState<boolean>(false);

  const buttonPrefix = hasCustomerSpecifiedMachineLimit(cloudSubscription) ? "Change" : "Set";

  const pluralSuffix =
    !props.cloudSubscription.customerSpecifiedMachineLimit || props.cloudSubscription.customerSpecifiedMachineLimit > 1
      ? "s"
      : undefined;

  return (
    <>
      <Grid item sm={3} sx={{ padding: 3 }}>
        <Typography fontWeight={700}>Maximum targets</Typography>
      </Grid>
      <Grid item sm={5} sx={{ padding: 3 }}>
        <Typography data-testid="machine-limit-value">
          {cloudSubscription.customerSpecifiedMachineLimit || "Unlimited"} deployment target{pluralSuffix}
        </Typography>
      </Grid>
      <Grid item sm={4} sx={{ textAlign: "right", padding: 3 }}>
        <Stack spacing={1} sx={{ alignItems: "flex-end" }}>
          {editable && (
            <PolicyAuthorized
              assetId={cloudSubscription.id}
              subscriptionGroupId={cloudSubscription.subscriptionGroup?.id}
              permission={"CloudSubscription.ManageBilling"}
            >
              <LinkButton onClick={() => setShowChangeLimitDialog(true)}>{buttonPrefix} maximum targets</LinkButton>
              <ChangeCustomerSpecifiedMachineLimitDialog
                cloudSubscription={cloudSubscription}
                open={showChangeLimitDialog}
                onClose={() => setShowChangeLimitDialog(false)}
              />
            </PolicyAuthorized>
          )}
        </Stack>
      </Grid>
    </>
  );
}
