import React from "react";
import { Checkbox, styled } from "@mui/material";
import type { CheckboxProps } from "@mui/material";
import { CheckIcon } from "areas/checkout/components/CheckIcon";

const StyledCheckbox = styled(Checkbox)`
  border: solid #0d80d8;

  &.MuiCheckbox-sizeMedium {
    border-width: 0.769px;
    border-radius: 4.209px;
    width: 20px;
    height: 20px;

    .MuiSvgIcon-root {
      width: 12px;
      height: 9px;
    }
  }
  &.MuiCheckbox-sizeLarge {
    border-width: 1px;
    border-radius: 5.472px;
    width: 26px;
    height: 26px;

    .MuiSvgIcon-root {
      width: 15px;
      height: 11px;
    }
  }
  &.Mui-checked {
    background-color: #0d80d8;
  }
`;

export function CheckoutCheckbox(props: CheckboxProps) {
  return <StyledCheckbox icon={<></>} checkedIcon={<CheckIcon />} {...props} />;
}
