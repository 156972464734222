const root = "/server-licenses";

export const ServerLicensesRoutesMap = {
  root: root,
  detail: (serverLicenseId: string) => ({
    root: `${root}/${serverLicenseId}`,
    finance: `${root}/${serverLicenseId}/finance`,
    upgrade: `${root}/${serverLicenseId}/upgrade`,
  }),
  loadBySerial: (serialId: string) => ({
    root: `${root}/serial/${serialId}`,
  }),
};
