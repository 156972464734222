import React from "react";
import { Typography } from "@mui/material";
import { FileUsageWarning } from "./FileUsageWarning";
import { ResourceUsageCopy } from "./ResourceUsageCopy";
import { getAlertLevel } from "./getAlertLevel";

type FileUsageLineItemProps = {
  usageInGb: number;
  limitInGb: number;
};

export function FileUsageLineItem({ usageInGb, limitInGb }: FileUsageLineItemProps) {
  return (
    <>
      <Typography>
        <ResourceUsageCopy usageInGb={usageInGb} limitInGb={limitInGb} />
      </Typography>
      <FileUsageWarning alertLevel={getAlertLevel(usageInGb, limitInGb)} />
    </>
  );
}
