import { DateTime } from "luxon";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { CloudSubscriptionStatus } from "./CloudSubscriptionStatus";

// Only cloud subscriptions that are cancelled or have no stripe licensing channel can be upgraded
export function canUpgradeCloudSubscription(canManageBilling: Boolean, cloudSubscription?: CloudSubscriptionDto) {
  if (!cloudSubscription) {
    return false;
  }
  if (!canManageBilling) {
    return false;
  }
  if (cloudSubscription?.cloudSubscriptionStatus == CloudSubscriptionStatus.Terminated) {
    return false;
  }
  if (cloudSubscription?.cloudSubscriptionStatus === CloudSubscriptionStatus.Cancelled) {
    return true;
  }

  switch (cloudSubscription.licensingChannel.channelType) {
    case "LegacyBraintreeCloudSubscriptionLicensingChannelDto":
    case "ImportedTrialCloudSubscriptionLicensingChannelDto":
      return true;
    case "StripeSubscriptionCloudSubscriptionLicensingChannelDto":
      return false;
    case "SalesforceContractedCloudSubscriptionLicensingChannelDto":
      if (cloudSubscription.licensingChannel.expiryDate < DateTime.now()) {
        return true;
      } else {
        return false;
      }
      break;
    default:
      return false;
  }
}
