import type { ZuoraCallbackPageErrorParams, ZuoraCallbackPageSuccessParams } from "./ZuoraCallbackPageTypes";

export const PaymentEventTypes = {
  PaymentSuccessful: "paymentSuccessful",
  PaymentFailure: "paymentFailure",
} as const;

export type PaymentEventTypes = (typeof PaymentEventTypes)[keyof typeof PaymentEventTypes];

export type EventPaymentFailure = CustomEvent<
  Pick<ZuoraCallbackPageErrorParams, "responseFrom" | "errorCode" | "errorField" | "errorMessage"> & {
    searchParams: string;
  }
>;

export type EventPaymentSuccessful = CustomEvent<
  Pick<
    ZuoraCallbackPageSuccessParams,
    | "responseFrom"
    | "refId"
    | "signature"
    | "tenantId"
    | "token"
    | "timestamp"
    | "pageId"
    | "fieldPassthrough1"
    | "fieldPassthrough2"
    | "fieldPassthrough3"
    | "fieldPassthrough4"
    | "fieldPassthrough5"
  >
>;
