export const formatStreetAddress = (address: {
  streetName: string;
  streetNumber?: string;
  countryCode: string;
}): string => {
  /** List of countrycodes for countries that have the street name first, followed by the street number */
  const nameThenNumberCountries = [
    "AL",
    "AT",
    "AX",
    "BA",
    "BE",
    "BQ",
    "BV",
    "CH",
    "CL",
    "CV",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ET",
    "FI",
    "FO",
    "GE",
    "GL",
    "GR",
    "GW",
    "HN",
    "HR",
    "HT",
    "IL",
    "IS",
    "JO",
    "LI",
    "LR",
    "LT",
    "ME",
    "MK",
    "NL",
    "NO",
    "PL",
    "PS",
    "PT",
    "PW",
    "PY",
    "RO",
    "RS",
    "SD",
    "SE",
    "SI",
    "SJ",
    "TJ",
    "TR",
    "UY",
    "AW",
    "BI",
    "BO",
    "CF",
    "CM",
    "CW",
    "EH",
    "ER",
    "GD",
    "GQ",
    "KI",
    "LC",
    "LY",
    "ML",
    "MO",
    "QA",
    "SB",
    "SS",
    "ST",
    "SX",
    "TL",
    "VC",
    "VU",
    "WS",
    "AF",
    "BW",
    "KP",
    "NI",
    "SO",
    "SR",
    "TD",
    "AR",
    "BR",
    "CO",
    "DR",
    "ES",
    "IT",
    "MX",
  ];
  if (nameThenNumberCountries.includes(address.countryCode)) {
    return `${address.streetName} ${address.streetNumber ?? ""}`.trim();
  }
  return `${address.streetNumber ?? ""} ${address.streetName}`.trim();
};
