import React, { useState } from "react";
import { Box, Button, Link, styled } from "@mui/material";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import type { ServerLicenseDto } from "client/api/ServerLicenseApi";
import { deleteSubscriptionGroup, updateSubscriptionGroup } from "client/api/SubscriptionGroupApi";
import type { SubscriptionGroupDto } from "client/api/common/SubscriptionGroupDto";
import { CollapsibleList } from "components/CollapsibleLayout/CollapsibleList";
import { CloudSubscription } from "components/Dashboard/CloudSubscriptions/CloudSubscription";
import { ServerLicense } from "components/Dashboard/ServerLicenses/ServerLicense";
import { RenameDialog } from "components/Dialogs/RenameDialog";
import { Overflow, OverflowMenu } from "components/OverflowMenu/OverflowMenu";
import { PolicyAuthorized } from "components/authorization/PolicyAuthorized";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { DeleteSubscriptionGroupDialog } from "areas/dashboard/components/SubscriptionGroups/DeleteSubscriptionGroupDialog";
import { SubscriptionHeader } from "areas/dashboard/components/SubscriptionHeader";
import { AccessControlDialog } from "./AccessControlDialog";

interface GroupedSubscriptionsProps {
  subscriptionGroup: SubscriptionGroupDto;
  cloudSubscriptions?: CloudSubscriptionDto[];
  serverSubscriptions?: ServerLicenseDto[];
  refresh: () => void;
  doNotAutoExpand?: boolean;
}

const SubscriptionsContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 16px;
  @media (min-width: 1200px) {
    grid-template-columns: 140px 1fr;
  }
`;

const SubscriptionsListContainer = styled("div")`
  margin-left: 16px;
  margin-right: 16px;
  @media (min-width: 1200px) {
    margin-left: 0px;
  }
`;

export function GroupedSubscriptions({
  cloudSubscriptions,
  serverSubscriptions,
  subscriptionGroup,
  refresh,
  doNotAutoExpand,
}: GroupedSubscriptionsProps) {
  const [showRenameGroupDialog, setShowRenameGroupDialog] = useState(false);
  const [showAccessControl, setShowAccessControl] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleGroupRename = async (value: string) => {
    if (value !== subscriptionGroup.name) {
      await updateSubscriptionGroup({
        id: subscriptionGroup.id,
        name: value,
      });
    }

    setShowRenameGroupDialog(false);
    if (refresh) {
      refresh();
    }
  };

  const handleGroupDelete = async () => {
    await deleteSubscriptionGroup(subscriptionGroup.id);

    setShowDeleteDialog(false);
    if (refresh) {
      refresh();
    }
  };
  const renameItem = (
    <PolicyAuthorized
      key={`SubscriptionGroup-${subscriptionGroup.id}-Rename`}
      permission={RbacPermissions.SubscriptionGroup.Rename}
      assetId={subscriptionGroup.id}
      subscriptionGroupId={undefined}
    >
      <Link
        underline={"none"}
        fontWeight={500}
        fontSize={"14px"}
        sx={{ padding: "10px" }}
        onClick={() => setShowRenameGroupDialog(true)}
      >
        Rename
      </Link>
    </PolicyAuthorized>
  );
  const accessControlItem = (
    <PolicyAuthorized
      key={`SubscriptionGroup-${subscriptionGroup.id}-AccessControl`}
      permission={RbacPermissions.SubscriptionGroup.ViewAccessControl}
      assetId={subscriptionGroup.id}
      subscriptionGroupId={undefined}
    >
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => setShowAccessControl(true)}
        sx={{ textTransform: "none" }}
      >
        Access Control
      </Button>
    </PolicyAuthorized>
  );

  const leftActions = [renameItem];
  const rightActions = [accessControlItem];
  const overflowMenuItems = [];

  const canDeleteGroup = !cloudSubscriptions?.length && !serverSubscriptions?.length;
  if (canDeleteGroup) {
    const deleteGroupItem = Overflow.policyAuthorizedMenuItem(
      RbacPermissions.SubscriptionGroup.Delete,
      subscriptionGroup.id,
      undefined,
      "Delete",
      () => setShowDeleteDialog(true)
    );
    overflowMenuItems.push(deleteGroupItem);
  }

  return (
    <>
      <RenameDialog
        value={subscriptionGroup.name}
        title={"Rename subscription group"}
        open={showRenameGroupDialog}
        handleClose={() => setShowRenameGroupDialog(false)}
        submitRename={handleGroupRename}
      />
      <AccessControlDialog
        open={showAccessControl}
        onClose={() => setShowAccessControl(false)}
        subscriptionGroup={subscriptionGroup}
      />
      <DeleteSubscriptionGroupDialog
        groupName={subscriptionGroup.name}
        open={showDeleteDialog}
        onConfirm={handleGroupDelete}
        onCancel={() => setShowDeleteDialog(false)}
      />

      <CollapsibleList
        title={subscriptionGroup.name}
        eyebrowTitle={"Subscription Group"}
        initiallyOpen={!doNotAutoExpand}
        leftActions={leftActions}
        rightActions={rightActions}
        overflowMenu={<OverflowMenu items={overflowMenuItems} hideWhenEmpty />}
      >
        <Box sx={{ paddingBottom: 1 }}>
          <SubscriptionsContainer>
            <div>
              {cloudSubscriptions && cloudSubscriptions.length > 0 && (
                <SubscriptionHeader title={"Cloud"} totalCount={cloudSubscriptions?.length} />
              )}
            </div>
            <SubscriptionsListContainer>
              {cloudSubscriptions?.map((entity, index) => (
                <CloudSubscription key={entity.id} cloudSubscription={entity} />
              ))}
            </SubscriptionsListContainer>
          </SubscriptionsContainer>
          <SubscriptionsContainer>
            <div>
              {serverSubscriptions && serverSubscriptions.length > 0 && (
                <SubscriptionHeader title={"Server"} totalCount={serverSubscriptions?.length} />
              )}
            </div>
            <SubscriptionsListContainer>
              {serverSubscriptions?.map((entity, index) => (
                <ServerLicense key={entity.id} serverLicense={entity} />
              ))}
            </SubscriptionsListContainer>
          </SubscriptionsContainer>
        </Box>
      </CollapsibleList>
    </>
  );
}
