import React from "react";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, IconButton, Typography } from "@mui/material";
import { DateTime, Duration } from "luxon";
import { FontAwesomeSvgIcon } from "@cpt/components";
import useLocalStorage from "@cpt/hooks/useLocalStorage";
import { designSystemColors } from "@cpt/shared";
import type { ProductType } from "shared/ProductType";

type StripeUpgradeSuccessAlertProps = {
  id: string;
  type: ProductType;
};

type UpgradedStripeProduct = {
  upgradedAt: string;
  productName: string | undefined;
};

export type UpgradedStripeProducts = Record<string, UpgradedStripeProduct>;
export const UPGRADED_STRIPE_PRODUCTS_KEY = "upgraded_stripe_products";

export function StripeUpgradeSuccessAlert(props: StripeUpgradeSuccessAlertProps) {
  const { id, type } = props;

  const [upgradedStripeProducts, setUpgradedStripeProducts] = useLocalStorage<UpgradedStripeProducts>(
    UPGRADED_STRIPE_PRODUCTS_KEY,
    {}
  );

  const removeFromLocalStorage = () => {
    const updated = {
      ...upgradedStripeProducts,
    };

    delete updated[id];
    setUpgradedStripeProducts(updated);
  };

  //if there is no record for this id, don't render anything
  const upgradeData = upgradedStripeProducts[id];
  if (!upgradeData) {
    return null;
  }

  //if we upgrade more than 1 day ago, remove from local storage and don't render
  const diffFromNow = DateTime.now().diff(DateTime.fromISO(upgradeData.upgradedAt), "milliseconds").as("milliseconds");
  const oneDay = Duration.fromObject({ days: 1 }).as("milliseconds");
  if (diffFromNow > oneDay) {
    removeFromLocalStorage();
    return null;
  }

  const productType = type === "CloudSubscription" ? "subscription" : "license";

  return (
    <Alert
      sx={{ backgroundColor: designSystemColors.green[200] }}
      icon={
        <FontAwesomeSvgIcon
          icon={faCheckCircle}
          sx={{ paddingTop: "4px", fontSize: "14px", color: designSystemColors.green[700] }}
        />
      }
      action={
        <IconButton aria-label="close" size="small" onClick={removeFromLocalStorage}>
          <CloseIcon />
        </IconButton>
      }
    >
      <Typography fontWeight="bold">
        Your {productType} has been upgraded to {upgradeData.productName}.
      </Typography>
      <Typography fontWeight="bold">Happy deployments!</Typography>
    </Alert>
  );
}
