import { unauthenticatedAxiosClient } from "client/axios-client";

export interface PurchaseIntentDto {
  cloudSubscriptionId?: string;
  serverLicenseId?: string;
}

const BASE_URL = "purchase-intents";

export const getPurchaseIntent = async (intentId: string, isFirstCheckRequest: boolean): Promise<PurchaseIntentDto> => {
  const response = await unauthenticatedAxiosClient.get<PurchaseIntentDto>(
    `${BASE_URL}/${intentId}?firstCheckOfCompletion=${isFirstCheckRequest}`
  );
  return response.data;
};
