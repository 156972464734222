import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, CircularProgress, Grid, Link, Typography } from "@mui/material";
import { getDefaultDelays } from "pages/utils/get-default-delays";
import BackgroundBlob from "../resources/images/svgs/background_blob.svg";

type PollingPageProps = {
  query: (hasSentFirstRequest: boolean) => Promise<boolean>;
  inProgressTitle: string;
  errorTitle: string;
  errorMessage: string;
  svgImage: string;
  svgImageAlt: string;
};

// This is the base page for showing progress while polling
export function PollingPage(props: PollingPageProps) {
  const { query, inProgressTitle, errorTitle, errorMessage, svgImage, svgImageAlt } = props;
  const defaultDelays = useMemo(() => getDefaultDelays(), []);
  const pollDelay = useRef<number>(defaultDelays.subsequent);
  const [hasStartedPolling, setHasStartedPolling] = useState<boolean>(false);
  const hasSentFirstRequest = useRef<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const pollTimerRef = useRef(0);

  const pollingCallback = useCallback(async () => {
    const response = await query(!hasSentFirstRequest.current);
    if (!response) {
      hasSentFirstRequest.current = true;
      pollTimerRef.current = window.setTimeout(() => pollingCallback(), pollDelay.current);
    }
  }, [query, hasSentFirstRequest, pollDelay]);

  useEffect(() => {
    if (!hasStartedPolling) {
      // we wait and initial delay (default 2.5s) seconds until the 1st API call so the user doesn't see a flash of screen (and it looks like we are doing something)
      pollTimerRef.current = window.setTimeout(pollingCallback, defaultDelays.initial);
      //once we've started polling, we don't want to ever re-trigger this
      setHasStartedPolling(true);
    }
  }, [pollingCallback, defaultDelays, hasStartedPolling, setHasStartedPolling]);

  // After 1 min, show an error message and drop the interval to every 30s to reduce database load
  useEffect(() => {
    const id = window.setTimeout(() => {
      pollDelay.current = defaultDelays.long;
      setShowError(true);
    }, defaultDelays.changeToError);
    return () => window.clearTimeout(id);
  }, [defaultDelays]);

  // if there is any timeout running clear it on unmount
  useEffect(() => {
    return () => window.clearTimeout(pollTimerRef.current);
  }, []);

  const inProgressMessageBlock = (
    <Box>
      <Box display="flex" flexDirection={{ xs: "column", md: "row" }} alignItems={{ xs: "start", md: "center" }}>
        <CircularProgress />
        <Typography variant="h4" ml={{ xs: 0, md: 2 }} mt={{ xs: 1, md: 0 }}>
          {inProgressTitle}
        </Typography>
      </Box>
      <Typography mt={4}>This will just take a couple of seconds. Please don't leave this page.</Typography>
    </Box>
  );

  const errorMessageBlock = (
    <Box maxWidth={"576px"}>
      <Box display="flex" flexDirection={{ xs: "column", md: "row" }} alignItems={{ xs: "start", md: "center" }}>
        <ErrorOutlineIcon fontSize="large" sx={{ color: "#E5B203" }} />
        <Typography variant="h5" ml={{ xs: 0, md: 2 }} mt={{ xs: 1, md: 0 }} sx={{ fontWeight: 500 }}>
          {errorTitle}
        </Typography>
      </Box>
      <Typography mt={3}>{errorMessage}</Typography>
      <Typography mt={3} sx={{ color: "#586978" }}>
        For more information, check{" "}
        <Link target="_blank" rel="noopener" href="https://status.octopus.com/">
          Octopus system status
        </Link>
        , email{" "}
        <Link target="_blank" rel="noopener" href="mailto:sales@octopus.com">
          sales@octopus.com
        </Link>{" "}
        or phone{" "}
        <Link target="_blank" rel="noopener" href="tel:+15128230256">
          +1 512-823-0256
        </Link>{" "}
      </Typography>
    </Box>
  );

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" overflow="hidden">
      <Grid container spacing={0} justifyContent="center">
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems={{ xs: "center", md: "end" }}
          sx={{ pr: { xs: 2, lg: 12 }, pl: { xs: 2, lg: 0 } }}
        >
          {showError ? errorMessageBlock : inProgressMessageBlock}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ position: "relative", pl: { xs: 2, lg: 12 }, pr: { xs: 2, lg: 0 }, pt: { xs: 6, md: 0 } }}
        >
          <Box
            display="flex"
            flexDirection={{ xs: "row", md: "column" }}
            justifyContent="center"
            sx={{
              position: { xs: "relative", md: "absolute" },
              height: { xs: "inherit", md: "100%" },
              zIndex: 1000,
            }}
          >
            <Box component="img" src={svgImage} alt={svgImageAlt} sx={{ maxWidth: { xs: "100%", md: "inherit" } }} />
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <img src={BackgroundBlob} alt="Background blob" style={{ zIndex: 500 }} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
