export const ResponseFrom = {
  SubmitPage: "Response_From_Submit_Page",
  "3DS": "Response_From_3D_Validation",
} as const;

export type ResponseFrom = (typeof ResponseFrom)[keyof typeof ResponseFrom] | (string & {});

export type ZuoraCallbackPageSuccessParams = {
  success: true;
  /** The payment method Id */
  refId: string;
} & ZuoraCallbackPageBaseParams;

export type ZuoraCallbackPageErrorParams = {
  success: false;
  errorCode?: string;
  errorField?: string;
  errorMessage: string;
} & ZuoraCallbackPageBaseParams;

type ZuoraCallbackPageBaseParams = {
  success: boolean;
  responseFrom: ResponseFrom;
  /** rawGatewayInfo is a stringified object */
  rawGatewayInfo?: string;
  tenantId: string;
  token: string;
  timestamp: string;
  pageId: string;
  /** Verifies the values originated from Zuora. See [Validate the digital Signature](https://knowledgecenter.zuora.com/Zuora_Payments/Payment_Page_and_Payment_Link/Payment_Pages_2.0/EB_Advanced_Integration_of_Payment_Pages_2.0/Validate_the_Digital_Signature_for_Payment_Pages_2.0) */
  signature: string;
  fieldPassthrough1?: string;
  fieldPassthrough2?: string;
  fieldPassthrough3?: string;
  fieldPassthrough4?: string;
  fieldPassthrough5?: string;
};

export type ZuoraCallbackPageParams = ZuoraCallbackPageSuccessParams | ZuoraCallbackPageErrorParams;
