import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { CircularProgress, Grid, keyframes, styled } from "@mui/material";
import { themeTokens } from "components/Theme/theme";

const Container = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 46px;
  padding: 8px 12px 8px 12px;
  gap: 8px;
`;
const LoadingContainer = styled(Container)`
  background: ${themeTokens.color.callout.background.info.default};
`;
const ErrorContainer = styled(Container)`
  background: ${themeTokens.color.callout.background.danger.default};
`;

const LoadingText = styled("div")`
  font-size: 16px;
  font-weight: 400;
  color: ${themeTokens.color.callout.text.info};
`;

const ErrorText = styled("div")`
  font-size: 16px;
  font-weight: 400;
  color: ${themeTokens.color.callout.text.danger};
`;

const spin = keyframes`
to { transform: rotate(360deg); }
`;

const SmallSpinner = styled("div")`
  animation: ${spin} 1.5s linear infinite;
  height: 20px;
`;

type LoadingOverlayProps = React.PropsWithChildren<{
  loading?: boolean;
  small?: boolean;
  error?: Error;
  loadingLabel?: string;
  secondaryLoadingLabel?: string;
  errorLabel?: string;
}>;
function LoadingPlaceholder(props: LoadingOverlayProps) {
  const { children, loading, error, loadingLabel, secondaryLoadingLabel, errorLabel, small = false } = props;

  return (
    <>
      {!loading && !error && children}
      {loading && !error && (
        <LoadingContainer
          item
          xs
          sx={{ flexDirection: !small ? "column" : "row", justifyContent: !small ? "center" : "flex-start" }}
        >
          <CircularProgress size={small ? "20px" : undefined} />
          {loadingLabel && <LoadingText>{loadingLabel ?? "Loading"}</LoadingText>}
          {secondaryLoadingLabel && <LoadingText>{secondaryLoadingLabel}</LoadingText>}
        </LoadingContainer>
      )}

      {error && !loading && (
        <ErrorContainer item xs sx={{ justifyContent: !small ? "center" : "flex-start" }}>
          <ErrorOutlineIcon sx={{ width: "20px", height: "20px", color: themeTokens.color.callout.icon.danger }} />{" "}
          <ErrorText>{errorLabel ?? error.message}</ErrorText>
        </ErrorContainer>
      )}
    </>
  );
}

export default LoadingPlaceholder;
