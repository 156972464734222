import React from "react";
import { Grid, TextField } from "@mui/material";
import { ExternalUriMap } from "utils/ExternalUriMap";
import { ExternalLink } from "components/ExternalLink/ExternalLink";
import LoadingPlaceholder from "components/LoadingPlaceholder";
import { FormattedTextArea } from "components/form/FormattedTextArea";
import {
  InstallationStep,
  InstallationStepText,
  InstallGuide,
} from "areas/serverLicenses/detail/components/ServerLicenseInstallation/InstallGuide";

function GetHelmInstallCommand(licenseKeyXml: string, storageClass: string) {
  const base64LicenseKey = window.btoa(licenseKeyXml);
  let helmCommand = `helm upgrade my-octopus-instance oci://ghcr.io/octopusdeploy/octopusdeploy-helm \\\n`;
  helmCommand += `--install \\\n`;
  helmCommand += `--namespace octopus-deploy \\\n`;
  helmCommand += `--create-namespace \\\n`;
  helmCommand += `--set octopus.acceptEula="Y" \\\n`;
  helmCommand += `--set mssql.enabled="true" \\\n`;
  helmCommand += `--set octopus.licenseKeyBase64="${base64LicenseKey}" \\\n`;

  if (storageClass && storageClass.trim().length > 0) {
    helmCommand += `--set global.storageClass="${storageClass}" \\\n`;
  }

  helmCommand += `--atomic`;

  return helmCommand;
}

interface KubernetesInstallGuideProps {
  licenseXml?: string;
  loading?: boolean;
  error?: Error;
}
export function KubernetesInstallGuide(props: KubernetesInstallGuideProps) {
  const { licenseXml, loading, error } = props;
  const [storageClass, setStorageClass] = React.useState<string>("");
  const handleStorageClassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStorageClass(event.target.value);
  };

  return (
    <InstallGuide>
      <InstallationStep stepNumber={1} label="Provide an optional Storage Class">
        <Grid item xs={12}>
          <TextField
            size="small"
            sx={{ maxWidth: "240px" }}
            fullWidth
            onChange={handleStorageClassChange}
            value={storageClass}
          />
        </Grid>
        <InstallationStepText>
          The Storage Class and your license key will automatically get added to the Helm command below.
        </InstallationStepText>
      </InstallationStep>

      <InstallationStep stepNumber={2} label="Install Octopus with Helm">
        <InstallationStepText>
          Run this Helm command to install Octopus server in your Kubernetes cluster.
        </InstallationStepText>
        <Grid item xs={12}>
          <LoadingPlaceholder
            small
            loading={loading}
            error={error}
            loadingLabel="Loading Helm command"
            errorLabel="Error loading Helm command"
          >
            <FormattedTextArea
              value={GetHelmInstallCommand(licenseXml ?? "", storageClass)}
              language="bash"
              hideLineNumbers={true}
            />
          </LoadingPlaceholder>
        </Grid>
        <InstallationStepText>
          The command installs Octopus Server and its dependencies, like SQL Server, in a way that’s suitable for
          evaluation. Read the docs for more configuration options.
        </InstallationStepText>
        <Grid item xs={12}>
          <ExternalLink href={ExternalUriMap.githubHelmChartOctopusRootPath}>Explore the docs</ExternalLink>
        </Grid>
      </InstallationStep>

      <InstallationStep stepNumber={3} label="Configure ingress resources">
        <InstallationStepText>
          Octopus typically needs two ingress resources for web access and Polling Tentacle communication.
        </InstallationStepText>
        <Grid item xs={12}>
          <ExternalLink href={ExternalUriMap.githubHelmChartOctopusIngressDocsPath}>Read the guide</ExternalLink>
        </Grid>
      </InstallationStep>

      <InstallationStep stepNumber={4} label="Your first Kubernetes deployment">
        <InstallationStepText>
          Start using your new Octopus instance by doing your first deployment to Kubernetes.
        </InstallationStepText>
        <Grid item xs={12}>
          <ExternalLink href={ExternalUriMap.octopusComDocsFirstKubernetesDeploymentPath}>Read the guide</ExternalLink>
        </Grid>
      </InstallationStep>
    </InstallGuide>
  );
}
