import React, { useEffect, useState } from "react";
import { Alert, CircularProgress, Stack } from "@mui/material";
import type { AxiosError } from "axios";
import { ErrorAlert } from "components/alert/ErrorAlert";

interface LoadablePageProps {
  loading?: boolean;
  error?: AxiosError;
}

export function LoadablePage({ loading, error, children }: React.PropsWithChildren<LoadablePageProps>) {
  const [shouldShowLoading, setShouldShowLoading] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldShowLoading(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  if (loading)
    return (
      <>
        <Stack direction="row" justifyContent="center" sx={!shouldShowLoading ? { display: "none" } : {}}>
          <CircularProgress sx={{ mt: 4, mb: 4 }} />
        </Stack>
      </>
    );
  if (error) {
    if (error.response?.status === 401) {
      return (
        <Alert severity={"error"}>
          You are unauthorized. Please ensure you are logged in and have access to this resource.
        </Alert>
      );
    }
    return <ErrorAlert error={error} />;
  }

  return <>{children}</>;
}
