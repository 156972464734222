import React from "react";
import { Route, Routes as _Routes } from "react-router-dom";
import { RoutesMap } from "RoutesMap";
import { AuthenticatedLayout } from "shared/AuthenticatedLayout";
import { subscriptionGroupUserGrantsApi } from "client/api/UserGrantApi";
import { StripTrailingSlash } from "components/StripTrailingSlash";
import { InviteAcceptPage } from "pages/InviteAcceptPage";
import { NotFoundPage } from "pages/NotFoundPage";
import { StripePaymentCompletingPage } from "pages/StripePaymentCompletingPage";
import { StripeSetupRenewalCompletingPage } from "pages/StripeSetupRenewalCompletingPage";
import { StripeUpgradeCompletingPage } from "pages/StripeUpgradeCompletingPage";
import { TrialFulfillmentPollingPage } from "pages/TrialFulfillmentPollingPage";
import { ZuoraCallbackPage } from "pages/ZuoraCallbackPage";
import { cloudSubscriptionRoutes } from "areas/cloudSubscriptions/CloudSubscriptionRoutes";
import { DashboardLayout } from "areas/dashboard/DashboardLayout";
import { purchasingRoutes } from "areas/purchasing/PurchasingRoutes";
import { serverLicenseRoutes } from "areas/serverLicenses/ServerLicensesRoutes";

export function Routes() {
  return (
    <>
      <StripTrailingSlash />
      <_Routes>
        {/* This must go before the root route so that this is directly matched */}
        <Route path={RoutesMap.completingPayment} element={<StripePaymentCompletingPage />} />
        <Route path={RoutesMap.completingSetup} element={<StripeSetupRenewalCompletingPage />} />
        <Route path={RoutesMap.completingUpgrade} element={<StripeUpgradeCompletingPage />} />
        <Route path={RoutesMap.completingTrialFulfillment} element={<TrialFulfillmentPollingPage />} />
        <Route path={RoutesMap.notFound} element={<NotFoundPage />} />
        <Route path={RoutesMap.zuoraCallbackPage} element={<ZuoraCallbackPage />} />
        <Route path={RoutesMap.root} element={<AuthenticatedLayout />}>
          <Route index element={<DashboardLayout />} />
          {purchasingRoutes()}
          {cloudSubscriptionRoutes()}
          {serverLicenseRoutes()}
          <Route
            path={RoutesMap.inviteAccept(":encodedInvite").root}
            element={<InviteAcceptPage userGrantsApi={subscriptionGroupUserGrantsApi} />}
          />
          <Route path={RoutesMap.fallback} element={<NotFoundPage />} />
        </Route>
      </_Routes>
    </>
  );
}
