import React from "react";
import { Grid, styled, Typography } from "@mui/material";
import ErrorImage from "resources/images/error.png";

const ImageContainer = styled("img")`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.35em;
`;

export function NotFoundPage() {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <ImageContainer src={ErrorImage} alt="octopus deploy error image" />
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" variant="h4" gutterBottom>
          Could not find the resource you requested.
        </Typography>
        <Typography align="center" variant="subtitle1">
          The resource you requested does not exist, or you may not have permission to access it.
        </Typography>
      </Grid>
    </Grid>
  );
}
