import type { PropsWithChildren, ReactNode, SyntheticEvent } from "react";
import React, { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, Stack, Typography } from "@mui/material";
import { designSystemColors } from "@cpt/shared";

type CollapsibleListProps = PropsWithChildren<{
  title: string;
  eyebrowTitle: string;
  initiallyOpen?: boolean;
  leftActions?: ReactNode;
  rightActions?: ReactNode;
  overflowMenu?: ReactNode;
}>;

export function CollapsibleList(props: CollapsibleListProps) {
  const expandIconSize = "medium";
  const { title, eyebrowTitle, initiallyOpen, leftActions, rightActions, overflowMenu, children } = props;
  const [show, setShow] = useState<boolean>(initiallyOpen || false);

  const handleClick = () => {
    setShow(!show);
  };

  const stopPropagation = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <>
      <div
        onClick={handleClick}
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
          padding: "16px 16px 16px 10px",
          color: designSystemColors.grey["600"],
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {show && <ExpandMoreIcon fontSize={expandIconSize} sx={{ color: designSystemColors.blue["500"] }} />}
          {!show && <ChevronRightIcon fontSize={expandIconSize} sx={{ color: designSystemColors.blue["500"] }} />}
          <Typography
            variant={"h3"}
            fontWeight={600}
            padding="4px"
            fontSize="1.25rem"
            color={designSystemColors.grey["800"]}
          >
            <Typography fontWeight={400} fontSize={13} letterSpacing={"0.05rem"} color={designSystemColors.grey["600"]}>
              {eyebrowTitle}
            </Typography>
            {title}{" "}
            {leftActions && (
              <Typography sx={{ display: "inline" }} onClick={stopPropagation}>
                {leftActions}
              </Typography>
            )}
          </Typography>
        </Box>
        <Stack onClick={stopPropagation} direction="row" spacing={2} alignItems="center">
          {rightActions && (
            <Stack direction="row" spacing={2} alignItems="center">
              {rightActions}
            </Stack>
          )}
          {overflowMenu && <Box onClick={stopPropagation}>{overflowMenu}</Box>}
        </Stack>
      </div>
      <Collapse in={show} timeout="auto">
        {children}
      </Collapse>
    </>
  );
}
