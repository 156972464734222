import React from "react";
import { Typography, styled } from "@mui/material";
import { designSystemColors } from "@cpt/shared";

export type SubscriptionHeaderProps = {
  title: string;
  totalCount: number | undefined;
};

const Header = styled(Typography)(
  ({ theme }) => `
    padding: 12px 16px 10px 22px;
    color: ${designSystemColors.grey["800"]};
    font-size: 0.875rem;
    font-weight: ${theme.typography.fontWeightBold};
    margin-left: 16px;
    margin-right: 16px;
    @media (min-width: 1200px) {
      border-top: 2px solid;
      border-color: ${designSystemColors.grey["200"]};
      margin-right: 0px;
    }
`
);

export function SubscriptionHeader(props: SubscriptionHeaderProps) {
  const { title } = props;

  return <Header>{title}</Header>;
}
