import React from "react";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Alert, Link, Typography } from "@mui/material";
import { FontAwesomeSvgIcon } from "@cpt/components";

export function PlansChangedInfoBanner() {
  return (
    <Alert
      severity={"info"}
      icon={<FontAwesomeSvgIcon icon={faExclamationCircle} sx={{ color: "#2A5C9D" }} />}
      sx={{
        border: "1px solid #D4DCE3",
        background: "#EEF5FC",
        borderRadius: "9px",
      }}
    >
      <Typography sx={{ color: "#2A5C9D", fontSize: "0.875rem", lineHeight: 1.5 }}>
        <strong>The below plans have new entitlements for projects, tenants and machines.</strong> We recommend reading
        the{" "}
        <Link href="#faq" sx={{ color: "#2A5C9D", fontWeight: "bold", textDecoration: "underline" }}>
          FAQ section
        </Link>{" "}
        to ensure you understand the change.
      </Typography>
    </Alert>
  );
}
