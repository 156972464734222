import React from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import type { LinkProps } from "@mui/material";
import { Link as MuiLink, styled } from "@mui/material";
import { themeTokens } from "components/Theme/theme";

const StyledLink = styled(MuiLink)`
  margin-right: 4px;
  cursor: pointer;
  text-decoration: none;
  color: ${themeTokens.color.text.link.default};
  &:hover {
    color: ${themeTokens.color.text.link.hover};
  }
  &:focus {
    outline-color: revert;
  }
`;

// eslint-disable-next-line jsdoc/require-param
/** A link to an external resource */
export function ExternalLink({ href, children, fontSize = "inherit", fontWeight = 500, textAlign }: LinkProps) {
  return (
    <StyledLink
      variant="body2"
      href={href}
      target="_blank"
      rel="noopener"
      fontSize={fontSize}
      fontWeight={fontWeight}
      textAlign={textAlign}
    >
      {children}
      <OpenInNewIcon sx={{ height: "16px", width: "16px", position: "relative", top: "3px", left: "4px" }} />
    </StyledLink>
  );
}
