import React, { createContext, useContext, useEffect, useState } from "react";
import type { PropsWithChildren } from "react";

export interface EntityProviderState<T> {
  entity: T | undefined;
  refresh?: () => void | Promise<void>;
  update: (entity: T) => void;
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const EntityProviderContext = createContext<EntityProviderState<any>>({
  entity: undefined,
  refresh: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  update: () => {},
});

export const useEntity = <T,>(): EntityProviderState<T> => {
  return useContext(EntityProviderContext);
};

export type EntityProviderProps<T> = PropsWithChildren<{
  entity: T;
  refresh?: () => void | Promise<void>;
}>;

export function EntityProvider<T>(props: EntityProviderProps<T>) {
  const { entity, refresh, children } = props;

  const [stateValue, setStateValue] = useState<T>(entity);

  useEffect(() => {
    setStateValue(entity);
  }, [entity]);

  return (
    <EntityProviderContext.Provider value={{ entity: stateValue, refresh, update: setStateValue }}>
      {children}
    </EntityProviderContext.Provider>
  );
}
