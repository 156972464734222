import React from "react";
import { styled, Typography, Link } from "@mui/material";
import { ExternalUriMap } from "utils/ExternalUriMap";
import { ExternalLink } from "components/ExternalLink/ExternalLink";
import type { AlertLevel } from "./getAlertLevel";

type FileUsageWarningContentPaidProps = {
  alertLevel: AlertLevel;
};

export function FileUsageWarningContent({ alertLevel }: FileUsageWarningContentPaidProps) {
  const heading = getHeading(alertLevel);

  return (
    <>
      {heading && (
        <Typography fontWeight={700} fontSize="14px">
          {heading}
        </Typography>
      )}

      {alertLevel === "none" ? <Info /> : <HowTo />}

      <Recommendations />

      <ExternalLink href={ExternalUriMap.octopusComAcceptableUsagePolicyPath} fontSize="14px" textAlign={"right"}>
        Acceptable usage policy
      </ExternalLink>
    </>
  );
}

const List = styled("ul")({
  listStyleType: "disc",
  paddingLeft: "1rem",
});

const BoldLink = styled(Link)({
  fontWeight: 500,
});

function getHeading(alertLevel: AlertLevel) {
  switch (alertLevel) {
    case "warning":
      return "You are close to the resource usage limit";
    case "danger":
      return "Immediate action is required to continue deployments";
    case "none":
      return null;
  }
}

const Recommendations = () => (
  <Typography fontSize="14px">
    We recommend implementing stricter{" "}
    <BoldLink href={ExternalUriMap.octopusComDocsRetentionPolicyPath} underline="none" target="_blank" rel="noopener">
      retention policies
    </BoldLink>{" "}
    for releases and packages and using external package repositories like{" "}
    <BoldLink href="https://feedz.io/" underline="none" target="_blank" rel="noopener">
      Feedz
    </BoldLink>{" "}
    or{" "}
    <BoldLink href="https://www.myget.org/" underline="none" target="_blank" rel="noopener">
      MyGet
    </BoldLink>{" "}
    to help manage your usage.
  </Typography>
);

const HowTo = () => (
  <>
    <Typography fontSize="14px">
      To free up space, please review these resources in your Octopus Cloud instance for anything that can be removed:
    </Typography>
    <List>
      <li>Artifacts</li>
      <li>Task logs</li>
      <li>Packages</li>
      <li>Package cache</li>
    </List>
  </>
);

const Info = () => (
  <Typography fontSize="14px">
    <strong>Resource usage</strong> indicates the storage space used by the artifacts, task logs, packages and package
    cache in your Octopus Cloud instance
  </Typography>
);
