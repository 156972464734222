export enum CloudSubscriptionStatus {
  None = "None",
  Subscribed = "Subscribed",
  Lapsed = "Lapsed",
  Cancelled = "Cancelled",
  Terminated = "Terminated",
  Unused = "Unused",
  Abandoned = "Abandoned",
}

export function IsSubscribedOrLapsed(status: CloudSubscriptionStatus) {
  return status === CloudSubscriptionStatus.Subscribed || status === CloudSubscriptionStatus.Lapsed;
}
