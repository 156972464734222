import React from "react";
import { SvgIcon } from "@mui/material";
import { themeTokens } from "components/Theme/theme";

export function InfoIcon() {
  return (
    <SvgIcon
      viewBox={"0 0 24 24"}
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: "20px",
        height: "20px",
        color: themeTokens.color.callout.text.info,
        backgroundColor: themeTokens.color.chip.filled.background.info,
        borderRadius: "50%",
      }}
    >
      <path d="M 11 7 L 13 7 L 13 9 L 11 9 L 11 7 Z M 11 11 L 13 11 L 13 17 L 11 17 L 11 11 Z"></path>
    </SvgIcon>
  );
}
