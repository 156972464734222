export type ZuoraCallback = (response: {
  success: boolean;
  errorCode: string;
  errorMessage: string;
  responseFrom: string;
}) => void;

/** Non-exhaustive list of params. See {@link https://knowledgecenter.zuora.com/Zuora_Payments/Payment_Page_and_Payment_Link/Payment_Pages_2.0/F_Client_Parameters_for_Payment_Pages_2.0 | Client Parameters for Payment Pages 2.0} */
type ZuoraRenderParams = {
  /** The hosted payment page id */
  id: string;
  /** Public key for encryption */
  key: string;
  /** Signature generated by the rsa-signatures request */
  signature: string;
  /** Token generated by the rsa-signatures request */
  token: string;
  /** Unique Id of your Zuora tenant */
  tenantId: number;
  /** The hosted payment page URL */
  url: string;
  style: "inline" | "overlay";
  /** Flag to indicate that page submission is handled by Zuora or by the client site */
  submitEnabled: "true" | "false";
  /** The initial authorization amount. This value overrides the default authorization amount set for the specific payment gateway */
  authorizationAmount?: number;
  /** Indicates whether to perform a duplication check when you create a payment method, default is false */
  checkDuplicated?: boolean;
  /** Locale ID of the payment page form, eg. "en", "fr_CA" */
  locale?: string;
  /** Name of the payment gateway as set up in Payment Settings. Overrides the default gateway you set up during the Payment Page configuration. */
  paymentGateway?: string;
  /** Overrides the credit card "Accepted Types" set up for your payment gateway */
  param_supportedTypes?: string;
  /** Defaults to basic, advanced has stronger security. See {@link https://knowledgecenter.zuora.com/Zuora_Payments/Payment_Page_and_Payment_Link/Payment_Pages_2.0/EB_Advanced_Integration_of_Payment_Pages_2.0/Validate_the_Digital_Signature_for_Payment_Pages_2.0 | Validate the Digital Signature for Payment Pages 2.0} */
  signatureType?: "basic" | "advanced";
} & Record<string, unknown>;

/** See {@link https://knowledgecenter.zuora.com/Zuora_Payments/Payment_Page_and_Payment_Link/Payment_Pages_2.0/KA_Error_Handling_for_Payment_Pages_2.0/Customize_Error_Messages_for_Payment_Pages_2.0#Render_Payment_Pages_2.0_with_Custom_Error_Handling | Custom Error Messages for Payment Pages 2.0} */
export type ZuoraClientErrorMessageCallback = (
  /**
   * In client side validation, a Payment Pages form field name will be returned.
   * See {@link https://knowledgecenter.zuora.com/Zuora_Payments/Payment_Page_and_Payment_Link/Payment_Pages_2.0/G_Payment_Pages_2.0_Form_Fields | Payment Pages 2.0 Form Fields} for the field names used in Payment Pages.
   */
  key: string,
  /**
   * Error code returned when the client-side credit card validation fails or the CAPTCHA challenge is not resolved.
   * - 001: The required field is not completed.
   * - 002: The card number is invalid.
   * - 003: The card type is invalid.
   * - 004: The CVV number is invalid.
   * - 007: The end-user doesn't resolve the challenge of Google reCAPTCHA Enterprise Interactive Test version.
   * - unknown: All client-side errors other than the five above.
   */
  code: "001" | "002" | "003" | "004" | "007" | "unknown",
  message: string,
  rawGatewayInfo: unknown
) => void;

export type Zuora = {
  /** Render the hosted payment page */
  render: (params: ZuoraRenderParams, prepopulateFields: Record<string, unknown>, callback: ZuoraCallback) => void;
  /** For rendering the hosted payment page with customized client-side errors */
  renderWithErrorHandler: (
    params: ZuoraRenderParams,
    prepopulateFields: Record<string, unknown>,
    callback: ZuoraCallback,
    clientErrorMessageCallback: ZuoraClientErrorMessageCallback,
    width?: number | null,
    height?: number | null,
    additionalFunction?: () => void
  ) => void;
  /** For handling server-side errors, the given callback is invoked, then cleared on iframe "load" event */
  runAfterRender: (serverErrorMessageCallback: () => void) => void;
  /**
   * Send error messages to the Hosted Payment page, for customizing client-side errors and overwriting messages generated by server-side validation
   * @param key The error key - may be a fieldName or "error"
   * @param errorMessage Customized error message to display in Payment Page. Note that this error message will not be localized in Payment Pages 2.0 form.
   */
  sendErrorMessageToHpm: (key: string, errorMessage: string) => void;
  setEventHandler: (
    eventName: "onSubmit" | "onloadCallback" | (string & {}),
    callback: (response: unknown) => void
  ) => void;
  /** Submits the hosted payment page form */
  submit: () => void;
} & Record<string, unknown>;

export const isZuoraLibrary = (obj: unknown): obj is Zuora => {
  return (
    typeof obj === "object" && obj !== null && "render" in obj && "renderWithErrorHandler" in obj && "submit" in obj
  );
};
