export type EnvConfig = {
  WebAppUrl: string;
  ApiUrl: string;
  AppVersion: string;
  Environment: string;
  IsProdEnvironment: boolean;
  OctopusCloudDomain: string;
  OctopusComDomain: string;
  AppInsightsConnectionString: string;
  ClientIpAddress?: string;
};

export const envConfig: EnvConfig = {
  WebAppUrl: process.env.WEB_APP_URL || "NO_WEB_APP_URL_DEFINED",
  ApiUrl: process.env.API_URL || "NO_API_URL_DEFINED",
  AppVersion: process.env.APP_VERSION || "0.0.0-local-dev",
  Environment: process.env.ENVIRONMENT || "Local",
  IsProdEnvironment: process.env.ENVIRONMENT?.localeCompare("Production") === 0,
  OctopusCloudDomain: process.env.OCTOPUS_CLOUD_DOMAIN || "NO_CLOUD_DOMAIN_DEFINED",
  OctopusComDomain: process.env.OCTOPUS_COM_DOMAIN || "NO_OCTOPUS_COM_DEFINED",
  AppInsightsConnectionString:
    process.env.PUBLIC_WEB_APP_APP_INSIGHTS_CONNECTION_STRING || "NO_APP_INSIGHTS_CONNECTION_STRING_DEFINED",
  /** In local dev can override the ClientIpAddress to support geolocation */
  ClientIpAddress: process.env.CLIENT_IP_ADDRESS || undefined,
};
