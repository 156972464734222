import React, { useMemo, useState } from "react";
import { Button, Grid, TableContainer, Typography, Alert, Stack } from "@mui/material";
import type { AxiosError } from "axios";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import useAsyncRequest from "hooks/useAsyncRequest";
import { CloudSubscriptionStatus, IsSubscribedOrLapsed } from "utils/CloudSubscription/CloudSubscriptionStatus";
import { LoadablePage } from "shared/LoadablePage";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { inviteUserToCloudSubscription } from "client/api/CloudSubscriptionApi";
import type { CloudSubscriptionOrSubscriptionGroupUserGrantDto } from "client/api/UserGrantApi";
import type { BaseUserGrantsApi } from "client/api/UserGrantApi";
import { InviteUserToCloudSubscription } from "components/ActionDialog/InviteUserToCloudSubscription";
import { UserGrantsTable } from "components/UserGrantTable/UserGrantsTable";
import { UserGrantsInviteTable } from "components/UserGrantsInviteTable/UserGrantsInviteTable";
import { ErrorAlert } from "components/alert/ErrorAlert";
import { PolicyAuthorized } from "components/authorization/PolicyAuthorized";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { CloudSubscriptionHeader } from "areas/cloudSubscriptions/components/CloudSubscriptionHeader";

export type BaseUserGrantsViewProps<TUserGrant extends CloudSubscriptionOrSubscriptionGroupUserGrantDto> = {
  userGrantsApi: BaseUserGrantsApi<TUserGrant>;
};

type UserGrantsDetailViewProps<TUserGrant extends CloudSubscriptionOrSubscriptionGroupUserGrantDto> =
  BaseUserGrantsViewProps<TUserGrant>;

export type CloudSubscriptionInactiveBannerProps = {
  isActive: boolean;
};

export function CloudSubscriptionInactiveBanner(props: CloudSubscriptionInactiveBannerProps) {
  const { isActive } = props;
  if (isActive) return <></>;
  return (
    <Stack sx={{ width: "100%" }} paddingBottom={1}>
      <Alert severity={"warning"}>This cloud subscription is not active</Alert>
    </Stack>
  );
}

export function CloudSubscriptionUsers<TUserGrant extends CloudSubscriptionOrSubscriptionGroupUserGrantDto>(
  props: UserGrantsDetailViewProps<TUserGrant>
) {
  const { entity: cloudSubscription } = useEntity<CloudSubscriptionDto>();
  const { hasPermissionForAsset } = useUserContext();

  const { userGrantsApi } = props;

  const getUserGrantsRequest = useMemo(() => (id: string) => userGrantsApi.listUserGrants(id), [userGrantsApi]);
  const getInvitesRequest = useMemo(() => (id: string) => userGrantsApi.getInvites(id), [userGrantsApi]);

  const canDeleteGrant = (userGrant: TUserGrant) => {
    return (
      hasPermissionForAsset(
        RbacPermissions.CloudSubscription.ManageAccessControl,
        cloudSubscription?.id,
        cloudSubscription?.subscriptionGroup?.id
      ) &&
      (userGrant.grantType === "CloudSubscriptionUserGrant" ||
        userGrant.grantType === "CloudSubscriptionUserGrantProxy")
    );
  };

  const canRevokeInvite = () => {
    return hasPermissionForAsset(
      RbacPermissions.CloudSubscription.ManageAccessControl,
      cloudSubscription?.id,
      cloudSubscription?.subscriptionGroup?.id
    );
  };

  const cloudSubscriptionStatus = cloudSubscription?.cloudSubscriptionStatus || "None";
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isActive = IsSubscribedOrLapsed(CloudSubscriptionStatus[cloudSubscriptionStatus]);

  const {
    data: userGrantsData,
    loading: loadingUserGrants,
    error: userGrantsError,
    refresh: refreshUserGrants,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  } = useAsyncRequest(getUserGrantsRequest, cloudSubscription?.id as string);
  const {
    data: invitesData,
    loading: loadingInvites,
    error: invitesError,
    refresh: refreshInvites,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  } = useAsyncRequest(getInvitesRequest, cloudSubscription?.id as string);

  const loading = loadingUserGrants || loadingInvites;
  const refreshAll = async () => {
    refreshInvites();
    refreshUserGrants();
  };

  const [showUserDialog, setShowUserDialog] = useState<boolean>(false);

  const [deleteError, setDeleteError] = useState<AxiosError>();

  const { userInfo } = useUserContext();

  const actions = [];
  if (isActive) {
    actions.push(
      <PolicyAuthorized
        permission={RbacPermissions.CloudSubscription.ManageAccessControl}
        assetId={cloudSubscription?.id}
        subscriptionGroupId={cloudSubscription?.subscriptionGroup?.id}
        key={cloudSubscription?.id}
      >
        <Button
          key={"CloudSubscriptionHeaderInviteUser"}
          variant={"contained"}
          size={"small"}
          onClick={() => setShowUserDialog(true)}
        >
          Invite user
        </Button>
      </PolicyAuthorized>
    );
  }

  return (
    <>
      <CloudSubscriptionHeader pageName={"Access control"} actions={actions} />
      {deleteError && <ErrorAlert error={deleteError} />}
      <LoadablePage loading={loading} error={userGrantsError ?? invitesError}>
        <Grid container spacing={2}>
          <CloudSubscriptionInactiveBanner isActive={isActive} />
          <Grid item xs={6} textAlign={"right"}></Grid>
          {invitesData?.entities && invitesData?.entities.length > 0 && (
            <Grid item xs={12}>
              <Typography sx={{ padding: "0 16px", fontWeight: "bold" }}>Invitations</Typography>
              <TableContainer>
                <UserGrantsInviteTable
                  userGrantInvites={invitesData?.entities}
                  grantsApi={userGrantsApi}
                  assetId={cloudSubscription?.id || ""}
                  canRevokeInvite={canRevokeInvite}
                  refresh={refreshInvites}
                  onDeleteError={setDeleteError}
                />
              </TableContainer>
            </Grid>
          )}
          <Grid item xs={12}>
            <TableContainer>
              <UserGrantsTable
                grants={userGrantsData?.directUserGrants}
                grantsApi={userGrantsApi}
                assetId={cloudSubscription?.id || ""}
                canDeleteGrant={canDeleteGrant}
                refresh={refreshUserGrants}
                onDeleteError={setDeleteError}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </LoadablePage>
      <InviteUserToCloudSubscription
        show={showUserDialog}
        dialogTitle={"Invite user to " + cloudSubscription?.friendlyName}
        onCancel={() => setShowUserDialog(false)}
        onSubmit={async (email, firstName, lastName, team) => {
          await inviteUserToCloudSubscription(
            cloudSubscription?.id,
            email,
            `${firstName} ${lastName}`,
            `${userInfo?.firstName} ${userInfo?.lastName}`,
            team
          );
          await refreshAll();
          setShowUserDialog(false);
        }}
      />
    </>
  );
}
