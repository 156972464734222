import React from "react";
import { Box, styled } from "@mui/material";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import type { ServerLicenseDto } from "client/api/ServerLicenseApi";
import { CloudSubscription } from "components/Dashboard/CloudSubscriptions/CloudSubscription";
import { ServerLicense } from "components/Dashboard/ServerLicenses/ServerLicense";
import { SubscriptionHeader } from "./SubscriptionHeader";

interface UngroupedSubscriptionsProps {
  cloudSubscriptions?: CloudSubscriptionDto[];
  serverSubscriptions?: ServerLicenseDto[];
}

const SubscriptionsContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 16px;
  @media (min-width: 1200px) {
    grid-template-columns: 140px 1fr;
  }
`;

const SubscriptionsListContainer = styled("div")`
  margin-left: 16px;
  margin-right: 16px;
  @media (min-width: 1200px) {
    margin-left: 0px;
  }
`;

export function UngroupedSubscriptions(props: UngroupedSubscriptionsProps) {
  const { cloudSubscriptions, serverSubscriptions } = props;
  return (
    <>
      <Box sx={{ paddingBottom: 1 }}>
        <SubscriptionsContainer>
          <div>
            {cloudSubscriptions && cloudSubscriptions.length > 0 && (
              <SubscriptionHeader title={"Cloud"} totalCount={cloudSubscriptions?.length} />
            )}
          </div>
          <SubscriptionsListContainer>
            {cloudSubscriptions?.map((entity, index) => (
              <CloudSubscription key={entity.id} cloudSubscription={entity} />
            ))}
          </SubscriptionsListContainer>
        </SubscriptionsContainer>
        <SubscriptionsContainer>
          <div>
            {serverSubscriptions && serverSubscriptions.length > 0 && (
              <SubscriptionHeader title={"Server"} totalCount={serverSubscriptions?.length} />
            )}
          </div>
          <SubscriptionsListContainer>
            {serverSubscriptions?.map((entity, index) => (
              <ServerLicense key={entity.id} serverLicense={entity} />
            ))}
          </SubscriptionsListContainer>
        </SubscriptionsContainer>
      </Box>
    </>
  );
}
