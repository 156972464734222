import React from "react";
import type { PropsWithChildren } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { getAccessToken } from "client/api/Authentication";

type AuthProviderProps = PropsWithChildren<{}>;

export function AuthProvider(props: AuthProviderProps) {
  const { children } = props;
  const accessToken = getAccessToken();

  return (
    <>
      {accessToken ? (
        children
      ) : (
        <Grid item xs={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "2rem" }}>
          <CircularProgress />
          <p>Loading user data...</p>
        </Grid>
      )}
    </>
  );
}
