import React from "react";
import { Card, Grid, Tab, Tabs, Typography } from "@mui/material";
import { themeTokens } from "components/Theme/theme";
import { LinuxInstallGuide } from "areas/serverLicenses/detail/components/ServerLicenseInstallation/LinuxInstallGuide";
import { WindowsInstallGuide } from "areas/serverLicenses/detail/components/ServerLicenseInstallation/WindowsInstallGuide";
import { KubernetesInstallGuide } from "./KubernetesInstallGuide";

interface ServerLicenseInstallationProps {
  licenseXml?: string;
  loading?: boolean;
  error?: Error;
}

export function ServerLicenseInstallation(props: ServerLicenseInstallationProps) {
  const { licenseXml, loading, error } = props;
  const [value, setValue] = React.useState(0);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const TabContent = [
    <WindowsInstallGuide />,
    <KubernetesInstallGuide licenseXml={licenseXml} loading={loading} error={error} />,
    <LinuxInstallGuide licenseXml={licenseXml} loading={loading} error={error} />,
  ];

  return (
    <Card
      sx={{
        paddingTop: 2,
        paddingBottom: 2,
      }}
    >
      <Grid item xs={12} paddingLeft={2}>
        <Typography sx={{ color: themeTokens.color.text.secondary, marginBottom: 1 }}>
          Install Octopus Server
        </Typography>
      </Grid>
      <Grid item xs={12} marginBottom={2} paddingLeft={2}>
        <Tabs value={value} onChange={handleTabChange}>
          <Tab label="Windows" />
          <Tab label="Kubernetes" />
          <Tab label="Docker" />
        </Tabs>
      </Grid>
      <Grid role="tabpanel" item xs={12}>
        {TabContent[value]}
      </Grid>
    </Card>
  );
}
