import React from "react";
import { Route } from "react-router-dom";
import { PurchasingRoutesMap } from "areas/purchasing/PurchasingRoutesMap";
import { Checkout } from "./Checkout";

export const purchasingRoutes = () => {
  return (
    <>
      <Route path={PurchasingRoutesMap.payment} element={<Checkout />} />
    </>
  );
};
