import React from "react";
import { Container } from "@mui/material";
import { CountriesProvider } from "contexts/CountriesProvider/CountriesProvider";
import { EntityProvider } from "contexts/EntityProvider/EntityProvider";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import type { AsyncResponse } from "hooks/useAsyncRequest";
import useAsyncRequest from "hooks/useAsyncRequest";
import { LoadablePage } from "shared/LoadablePage";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { getCloudSubscriptionById } from "client/api/CloudSubscriptionApi";
import type { ServerLicenseDto } from "client/api/ServerLicenseApi";
import { getServerLicenseById } from "client/api/ServerLicenseApi";
import type { NavItem } from "components/NavigationSidebarLayout/NavigationSidebarLayout";
import NavigationSidebarLayout from "components/NavigationSidebarLayout/NavigationSidebarLayout";
import { PaperLayout } from "components/PaperLayout/PaperLayout";
import { NotFoundPage } from "pages/NotFoundPage";
import { getNavLinks as getCloudNavLinks } from "areas/cloudSubscriptions/CloudSubscriptionLayout";
import { BackToDashboard } from "areas/dashboard/components/BackToDashboard";
import { getNavLinks as getServerNavLinks } from "areas/serverLicenses/ServerLicenseLayout";

function CheckoutLayoutShared({
  children,
  subscription,
  refresh,
  navLinks,
  loading,
  error,
}: {
  children: React.ReactNode;
  subscription: CloudSubscriptionDto | ServerLicenseDto | undefined;
  refresh: AsyncResponse<unknown>["refresh"];
  navLinks: NavItem[];
  loading: boolean;
  error: AsyncResponse<unknown>["error"];
}) {
  if (error && error.response?.status === 404) {
    return <NotFoundPage />;
  }

  return (
    <EntityProvider entity={subscription} refresh={refresh}>
      <CountriesProvider>
        <NavigationSidebarLayout navLinks={navLinks} preNavbarComponent={<BackToDashboard />} navLinksVisible={true}>
          <PaperLayout>
            <LoadablePage loading={loading} error={error}>
              <Container maxWidth={false} disableGutters={true}>
                {children}
              </Container>
            </LoadablePage>
          </PaperLayout>
        </NavigationSidebarLayout>
      </CountriesProvider>
    </EntityProvider>
  );
}

function CheckoutLayoutCloud({ children, subscriptionId }: { children?: React.ReactNode; subscriptionId: string }) {
  const {
    data: cloudSubscription,
    loading,
    error,
    refresh,
  } = useAsyncRequest(getCloudSubscriptionById, subscriptionId);
  const { hasPermissionForAsset } = useUserContext();
  const navLinks = (cloudSubscription && getCloudNavLinks({ cloudSubscription, hasPermissionForAsset })) || [];

  return (
    <CheckoutLayoutShared
      subscription={cloudSubscription}
      refresh={refresh}
      navLinks={navLinks}
      loading={loading}
      error={error}
    >
      {children}
    </CheckoutLayoutShared>
  );
}

function CheckoutLayoutServer({ children, subscriptionId }: { children?: React.ReactNode; subscriptionId: string }) {
  const { data: serverLicense, loading, error, refresh } = useAsyncRequest(getServerLicenseById, subscriptionId);
  const { hasPermissionForAsset } = useUserContext();
  const navLinks = (serverLicense && getServerNavLinks({ serverLicense, hasPermissionForAsset })) || [];

  return (
    <CheckoutLayoutShared
      subscription={serverLicense}
      refresh={refresh}
      navLinks={navLinks}
      loading={loading}
      error={error}
    >
      {children}
    </CheckoutLayoutShared>
  );
}

export function CheckoutLayout({
  children,
  subscriptionId,
  subscriptionType,
}: {
  children?: React.ReactNode;
  subscriptionId: string;
  subscriptionType: "cloud" | "selfhosted";
}) {
  if (subscriptionType === "cloud") {
    return <CheckoutLayoutCloud subscriptionId={subscriptionId}>{children}</CheckoutLayoutCloud>;
  } else if (subscriptionType === "selfhosted") {
    return <CheckoutLayoutServer subscriptionId={subscriptionId}>{children}</CheckoutLayoutServer>;
  } else {
    return null;
  }
}
