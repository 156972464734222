/*
 Provides the ability to get an array of the keys from a Record<K,T> in the correct type
 */
export function recordKeys<K extends PropertyKey, T>(obj: Record<K, T>) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return Object.keys(obj) as K[];
}
/*
 Provides the ability to get an array of the entries from a Record<K,T> in the correct types
 */
export function recordEntries<K extends PropertyKey, T>(obj: Record<K, T>) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return Object.entries(obj) as [K, T][];
}
