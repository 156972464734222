import React from "react";
import { Link as RouterLink } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton, Link, Paper, Typography } from "@mui/material";
import { DateTime, Duration } from "luxon";
import useLocalStorage from "@cpt/hooks/useLocalStorage";
import { ExternalUriMap } from "utils/ExternalUriMap";
import type { ProductType } from "shared/ProductType";
import SuccessRocket from "../../resources/images/svgs/success_rocket.svg";

type UpgradeSuccessBannerProps = {
  id: string;
  type: ProductType;
  financePageRoute: string;
};

export type UpgradedProducts = Record<string, string>;
export const UPGRADED_PRODUCTS_KEY = "upgraded_products";

export function UpgradeSuccessBanner(props: UpgradeSuccessBannerProps) {
  const { id, type, financePageRoute } = props;

  const [upgradedProducts, setUpgradedProducts] = useLocalStorage<UpgradedProducts>(UPGRADED_PRODUCTS_KEY, {});

  const removeFromLocalStorage = () => {
    const updated = {
      ...upgradedProducts,
    };

    delete updated[id];

    //remove this item from
    setUpgradedProducts(updated);
  };

  //if there is no record for this id, don't render anything
  const upgradeDate = upgradedProducts[id];
  if (!upgradeDate) {
    return null;
  }

  //if we upgrade more than 1 day ago, remove from local storage and don't render
  const diffFromNow = DateTime.now().diff(DateTime.fromISO(upgradeDate), "milliseconds").as("milliseconds");
  const oneDay = Duration.fromObject({ days: 1 }).as("milliseconds");
  if (diffFromNow > oneDay) {
    removeFromLocalStorage();
    return null;
  }

  const productLong = type === "CloudSubscription" ? "Cloud Subscription" : "Server License";
  const productShort = type === "CloudSubscription" ? "subscriptions" : "licenses";

  return (
    <Paper sx={{ p: 2, position: "relative" }}>
      <IconButton
        aria-label="close"
        size="small"
        sx={{ position: "absolute", top: "16px", right: "16px" }}
        onClick={removeFromLocalStorage}
      >
        <CloseIcon />
      </IconButton>
      <Grid container>
        <Grid item xs>
          <Typography variant="h6" fontWeight="bold" mb={2} mr={{ xs: 4, lg: 0 }}>
            <CheckCircleIcon color={"success"} sx={{ verticalAlign: "sub", mr: 1 }} />
            Your {productLong} has been upgraded, happy deployments!
          </Typography>
          <Typography mb={2}>
            We've sent an email containing the purchase receipt and invoice to the email address you provided in Stripe.
            You can also access these documents on the{" "}
            <RouterLink to={financePageRoute} style={{ fontWeight: 500 }}>
              Finance
            </RouterLink>{" "}
            page.
          </Typography>
          <Typography>
            This {productLong} has been moved to Control Center V2. If you have {productShort} that haven't been
            purchased or renewed recently, they may be in{" "}
            <Link
              underline={"none"}
              fontWeight={500}
              href={`${ExternalUriMap.octopusComControlCenterV1RootPath}`}
              target="_blank"
            >
              Control Center V1
            </Link>
            .
          </Typography>
        </Grid>
        <Grid item xs="auto" ml={1} display={{ xs: "none", lg: "inherit" }}>
          <img src={SuccessRocket} alt={"Happy deployments!"} />
        </Grid>
      </Grid>
    </Paper>
  );
}
