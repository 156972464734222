import { Duration } from "luxon";
import type { DateTime } from "luxon";

/*
Calculate the duration between start and end time and check if its greater than the minDuration.
Assumes both startTime and endTime have the same date.
If start time is greater than end time, add one extra day to the end time 
For example, for start time 11pm, end time 1am,
then the duration is 1am tomorrow - 11pm = 2 hrs.  
*/
export function isDurationGreaterThan(startTime: DateTime, endTime: DateTime, minDuration: Duration) {
  if (endTime >= startTime) {
    return endTime > startTime.plus(minDuration);
  } else {
    // add one extra day
    return endTime.plus(Duration.fromObject({ days: 1 })) > startTime.plus(minDuration);
  }
}
