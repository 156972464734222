import React, { createContext, useContext, useEffect, useState } from "react";
import type { PropsWithChildren } from "react";
import type { AlertColor } from "@mui/material";
import { Snackbar } from "@mui/material";
import type { AlertProps } from "@mui/material/Alert";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export interface AlertProviderState {
  sendNewAlert: (message: string, severity?: AlertSeverity) => void;
}

const AlertProviderContext = createContext<AlertProviderState>({
  sendNewAlert: (message: string, severity?: AlertSeverity) => {
    return;
  },
});

export const useAlerts = (): AlertProviderState => {
  return useContext(AlertProviderContext);
};

type AlertProviderProps = PropsWithChildren<{
  sendNewAlert?: (message: string, severity?: AlertSeverity) => void;
}>;
export enum AlertSeverity {
  Error = "error",
  Warning = "Warning",
  Info = "info",
  Success = "success",
}

export interface AlertMessage {
  message: string;
  severity: AlertSeverity;
  key: number;
}

export function AlertProvider(props: AlertProviderProps) {
  const { children } = props;
  const [alerts, setAlerts] = useState<readonly AlertMessage[]>([]);
  const [messageInfo, setMessageInfo] = useState<AlertMessage | undefined>(undefined);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (alerts.length && !messageInfo) {
      const firstItem = alerts[0];

      if (!firstItem) {
        return;
      }
      setMessageInfo({ ...firstItem });
      setAlerts((prev) => prev.slice(1));
      setOpen(true);
    } else if (alerts.length && messageInfo && open) {
      // Close an active alert when a new one is added
      setOpen(false);
    }
  }, [alerts, messageInfo, open]);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const createNewAlert = (message: string, severity: AlertSeverity = AlertSeverity.Info) => {
    setAlerts((prev) => [...prev, { message, severity, key: new Date().getTime() }]);
  };

  return (
    <AlertProviderContext.Provider value={{ sendNewAlert: createNewAlert }}>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        key={messageInfo?.key}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
      >
        {/* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */}
        <Alert onClose={handleClose} severity={messageInfo?.severity as AlertColor} sx={{ width: "100%" }}>
          {messageInfo?.message}
        </Alert>
      </Snackbar>
    </AlertProviderContext.Provider>
  );
}
