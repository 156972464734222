import React from "react";
import { QuestionMark } from "@mui/icons-material";
import ArticleIcon from "@mui/icons-material/Article";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MailIcon from "@mui/icons-material/Mail";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SupportIcon from "@mui/icons-material/Support";
import { Box, Button, IconButton, Link, Popover, Tooltip, Typography } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { ExternalUriMap } from "utils/ExternalUriMap";
import CopyToClipboardWrapper from "../CopyToClipboardWrapper";

export function Help() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton
        sx={{
          backgroundColor: "#CDE4F7",
          color: "#0F4778",
          ml: 2,
          w: 3,
          h: 3,
          "&:hover": {
            backgroundColor: "#87BFEC",
            color: "#0F4778",
          },
        }}
        color="primary"
        onClick={handleClick}
      >
        <QuestionMark sx={{ width: "0.8rem", height: "0.8rem" }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        elevation={3}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 36,
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ width: "300px" }}>
          <Typography sx={{ p: 2, pb: 1, fontWeight: "medium" }}>Help</Typography>
          <Typography sx={{ pl: 2, pr: 2, fontSize: 14, color: "text.secondary" }}>
            Resources for managing your Octopus subscriptions.
          </Typography>
          <MenuList>
            <MenuItem
              component={Link}
              href={ExternalUriMap.octopusComDocsManagingOctopusSubscriptionsPath}
              target="_blank"
            >
              <ListItemIcon>
                <ArticleIcon sx={{ fontSize: 24 }} />
              </ListItemIcon>
              <ListItemText>Visit docs</ListItemText>
              <Typography color="text.secondary" sx={{ ml: 2 }}>
                <OpenInNewIcon sx={{ fontSize: 12 }} />
              </Typography>
            </MenuItem>
            <MenuItem component={Link} href="mailto:support@octopus.com" target="_blank">
              <ListItemIcon>
                <MailIcon sx={{ fontSize: 24 }} />
              </ListItemIcon>
              <ListItemText>Email support</ListItemText>
              <Typography color="text.secondary" sx={{ ml: 2 }}>
                <OpenInNewIcon sx={{ fontSize: 12 }} />
              </Typography>
            </MenuItem>
            <MenuItem component={Link} href={ExternalUriMap.octopusComSupportFilesRootPath} target="_blank">
              <ListItemIcon>
                <SupportIcon sx={{ fontSize: 24 }} />
              </ListItemIcon>
              <ListItemText>Upload support files</ListItemText>
              <Typography color="text.secondary" sx={{ ml: 2 }}>
                <OpenInNewIcon sx={{ fontSize: 12 }} />
              </Typography>
            </MenuItem>
          </MenuList>
          <Box sx={{ p: 2, pt: 1 }}>
            <CopyToClipboardWrapper value={"support@octopus.com"}>
              <Tooltip title="Copy to clipboard">
                <Button variant="text" size="small" startIcon={<ContentCopyIcon />}>
                  support@octopus.com
                </Button>
              </Tooltip>
            </CopyToClipboardWrapper>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
