import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { styled, Typography } from "@mui/material";
import { designSystemColors } from "@cpt/shared";
import PopoverHelp from "components/PopoverHelp/PopoverHelp";
import { FileUsageWarningContent } from "./FileUsageWarningContent";
import type { AlertLevel } from "./getAlertLevel";

type FileUsageWarningProps = {
  alertLevel: AlertLevel;
};

export function FileUsageWarning({ alertLevel }: FileUsageWarningProps) {
  if (alertLevel === "none") return null;
  const label = alertLevel === "warning" ? "You are close to the resource usage limit" : "Immediate action required";

  return (
    <Wrapper>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "14px",
          color: alertLevel === "warning" ? designSystemColors.orange["600"] : designSystemColors.red["600"],
          display: "inline",
          marginRight: "5px",
        }}
      >
        {label}
      </Typography>
      <PopoverHelp trigger={"click"} placement={"right-start"} button={<Info alertLevel={alertLevel} />}>
        <FileUsageWarningContent alertLevel={alertLevel} />
      </PopoverHelp>
    </Wrapper>
  );
}

const Info = styled(InfoIcon, {
  shouldForwardProp: (prop) => prop !== "alertLevel",
})<{ alertLevel: AlertLevel }>(({ theme, alertLevel }) => {
  let iconBg = alertLevel === "warning" ? designSystemColors.orange["600"] : designSystemColors.red["600"];
  // We'll be inserting iconBg into a URL, so we need to escape the # character
  // See https://codepen.io/gunnarbittersmann/pen/BoovjR for more info
  iconBg = iconBg.replace("#", "%23");

  return {
    color: alertLevel === "warning" ? designSystemColors.orange["200"] : designSystemColors.red["200"],
    backgroundImage: `
  url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><rect fill='${iconBg}' x='0' y='0' width='100%' height='100%'/></svg>")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    fontSize: "24px",
    ["&:focus .focusVisible"]: {
      outlineColor: theme.palette.primary.main,
    },
  };
});

const Wrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
