import { Button, styled } from "@mui/material";
import { designSystemColors } from "@cpt/shared";

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const GreenButton = styled(Button)(
  ({ theme }) => `
    text-transform: none;
    color: #fff;
    background-color: ${designSystemColors.green["500"]};
    &:hover {
      background-color: #25d284;
      color: #fff;
      box-shadow: 2px 2px 10px #afffc7;
    }
  `
) as typeof Button;
