import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, Grid } from "@mui/material";
import { RoutesMap } from "RoutesMap";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import useAsyncRequest from "hooks/useAsyncRequest";
import { isCloudSubscriptionUserGrantDto, isSubscriptionGroupUserGrantDto } from "utils/UserGrantsTypeHelpers";
import type { BaseUserGrantsApi } from "client/api/UserGrantApi";
import type { UserGrantDto } from "client/api/UserGrantApi";
import { ErrorAlert } from "components/alert/ErrorAlert";
import { createNavigationAlert } from "components/alert/NavigationAlert";
import { CloudSubscriptionsRoutesMap } from "areas/cloudSubscriptions/CloudSubscriptionRoutesMap";

export type InviteAcceptPageProps<TUserGrant extends UserGrantDto> = {
  userGrantsApi: BaseUserGrantsApi<TUserGrant>;
};
export function InviteAcceptPage<TUserGrant extends UserGrantDto>(props: InviteAcceptPageProps<TUserGrant>) {
  let { encodedInvite } = useParams();
  encodedInvite = encodedInvite ?? "";

  const { userGrantsApi } = props;

  const getRequest = useMemo(
    () => (encodedInvite: string) => userGrantsApi.acceptUserGrantInvite(encodedInvite),
    [userGrantsApi]
  );
  const { data, error } = useAsyncRequest(getRequest, encodedInvite);
  const navigate = useNavigate();

  const { userInfo, refresh } = useUserContext();

  useEffect(() => {
    let isCleaningUp = false;

    if (data && !isCleaningUp) {
      if (isCloudSubscriptionUserGrantDto(data)) {
        navigate(
          CloudSubscriptionsRoutesMap.detail(data.cloudSubscriptionId).root,
          createNavigationAlert({
            content: `Hi ${userInfo?.firstName ?? "there"}, you now have access to the <b>${
              data.cloudSubscriptionName
            }</b> cloud instance.`,
            severity: "success",
          })
        );
      } else if (isSubscriptionGroupUserGrantDto(data)) {
        navigate(
          RoutesMap.root,
          createNavigationAlert({
            content: `Hi ${userInfo?.firstName ?? "there"}, you now have access to the <b>${
              data.subscriptionGroupName
            }</b> subscription group.`,
            severity: "success",
          })
        );
      }
      refresh();
    }

    return () => {
      isCleaningUp = true;
    };
  }, [navigate, data, refresh, userInfo]);

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <Grid item xs={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "2rem" }}>
      <CircularProgress />
      <p>Accepting invitation...</p>
    </Grid>
  );
}
