import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Link, Typography } from "@mui/material";
import { RoutesMap } from "RoutesMap";

export interface TrialFulfillmentErrorProps {
  error: string;
}

export function TrialFulfillmentError(props: TrialFulfillmentErrorProps) {
  return (
    <Box>
      <Box display="flex" flexDirection={{ xs: "column", md: "row" }} alignItems={{ xs: "start", md: "center" }}>
        <ErrorOutlineIcon fontSize="large" sx={{ color: "#E5B203" }} />
        <Typography variant="h5" ml={{ xs: 0, md: 2 }} mt={{ xs: 1, md: 0 }} sx={{ fontWeight: 500 }}>
          {props.error}
        </Typography>
      </Box>
      <Typography mt={3} sx={{ color: "#586978" }}>
        For more information, email{" "}
        <Link target="_blank" rel="noopener" href="mailto:sales@octopus.com">
          sales@octopus.com
        </Link>{" "}
        or phone{" "}
        <Link target="_blank" rel="noopener" href="tel:+15128230256">
          +1 512-823-0256
        </Link>{" "}
      </Typography>
      <Typography mt={3}>
        Need to manage your account? Visit the{" "}
        <Link href={RoutesMap.root} target={"_blank"}>
          Control Center
        </Link>
      </Typography>
    </Box>
  );
}
