import React from "react";
import { Button, styled } from "@mui/material";
import type { ButtonProps } from "@mui/material";

const PrimaryButton = styled(Button)`
  text-transform: none;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  background-color: #3573ce;
  line-height: 140%;
  height: 44px;
`;

const SecondaryButton = styled(Button)`
  text-transform: none;
  background-color: rgba(26, 119, 202, 0.06);
  color: #1a77ca;
  font-weight: 700;
  line-height: 24px;
  height: 44px;

  &.MuiButton-root:hover,
  &.MuiButton-root:focus {
    background-color: rgba(26, 119, 202, 0.16);
  }
`;

export function CheckoutPrimaryButton(props: ButtonProps) {
  return <PrimaryButton {...props} />;
}

export function CheckoutSecondaryButton(props: ButtonProps) {
  return <SecondaryButton {...props} />;
}
