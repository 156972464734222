import React from "react";
import { SvgIcon } from "@mui/material";

interface ChevronDownIconProps {
  fill?: string;
}

export function ChevronDownIcon(props: ChevronDownIconProps) {
  const fillColor = props.fill || "#11181D";

  return (
    <SvgIcon
      viewBox="0 0 14 8"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: "12.67px",
        height: "13.82px;",
      }}
      fill="none"
    >
      <path
        d="M6.23077 7.26587L0.234239 1.27211C-0.0780798 0.991155 -0.0780798 0.522893 0.234239 0.210718C0.515327 -0.0702393 0.983806 -0.0702393 1.29612 0.210718L6.76171 5.70499L12.2273 0.241935C12.5084 -0.0702393 12.9769 -0.0702393 13.2892 0.241935C13.5703 0.522893 13.5703 0.991155 13.2892 1.27211L7.26142 7.26587C6.98034 7.57804 6.51186 7.57804 6.23077 7.26587Z"
        fill={fillColor}
      />
    </SvgIcon>
  );
}
