import type { ChangeEvent } from "react";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import type { AxiosError } from "axios";
import axios from "axios";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { updateCustomerSpecifiedMachineLimit } from "client/api/CloudSubscriptionApi";
import { AlertSeverity, useAlerts } from "components/AlertProvider/AlertProvider";
import { themeTokens } from "components/Theme/theme";
import { ErrorAlert } from "components/alert/ErrorAlert";
import type { CloudSubscriptionProps } from "areas/cloudSubscriptions/CloudSubscriptionProps";
import { hasCustomerSpecifiedMachineLimit } from "areas/cloudSubscriptions/detail/components/finance/CustomerSpecifiedMachineLimitLine";

type ChangeCustomerSpecifiedMachineLimitDialogProps = CloudSubscriptionProps & {
  open: boolean;
  onClose: () => void;
};

export function ChangeCustomerSpecifiedMachineLimitDialog(props: ChangeCustomerSpecifiedMachineLimitDialogProps) {
  const { open, onClose, cloudSubscription } = props;
  const { update } = useEntity<CloudSubscriptionDto>();
  const { sendNewAlert } = useAlerts();

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | undefined>(undefined);
  const [value, setValue] = useState<number>(1);
  const [hasSpecifiedLimit, setHasSpecifiedLimit] = useState<boolean>(
    hasCustomerSpecifiedMachineLimit(cloudSubscription)
  );
  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    //when the dialog opens, set the initial values
    if (open) {
      setValue(cloudSubscription.customerSpecifiedMachineLimit ?? 1);
      setHasSpecifiedLimit(hasCustomerSpecifiedMachineLimit(cloudSubscription));
      setTitle(`${hasCustomerSpecifiedMachineLimit(cloudSubscription) ? "Change" : "Set"} maximum targets`);
    }
  }, [open, cloudSubscription]);

  const updateMaximumTargets = async () => {
    setIsUpdating(true);

    try {
      const machineLimit = hasSpecifiedLimit && value ? value : undefined;

      const changedCloudSubscription = await updateCustomerSpecifiedMachineLimit(cloudSubscription, machineLimit);

      sendNewAlert("Maximum targets updated", AlertSeverity.Success);
      handleOnClose(true);

      //update after close
      update(changedCloudSubscription);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setError(e);
      } else {
        console.error(e);
      }
    } finally {
      setIsUpdating(false);
    }
  };

  const handleOnClose = (force = false) => {
    if (isUpdating && !force) {
      return;
    }
    onClose();
  };

  const handleHasMaximumChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setHasSpecifiedLimit(event.target.checked);
    if (hasSpecifiedLimit && (!value || value < 0)) {
      setValue(1);
    }
  };

  const handleOnValueChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(parseInt(event.target.value));
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleOnClose()}
      aria-labelledby="change-customer-specified-machine-limit-dialog-title"
      aria-describedby="change-customer-specified-machine-limit-dialog-description"
    >
      <DialogTitle id="change-customer-specified-machine-limit-dialog-title">
        {title}
        <IconButton
          aria-label="close"
          onClick={() => handleOnClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: themeTokens.color.callout.icon.default,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent id="change-customer-specified-machine-limit-dialog-description">
        {error && <ErrorAlert error={error} />}
        <DialogContentText>
          Setting a maximum target limit can help to control your spend. You will only be charged for the number of
          targets in your subscription on the renewal date.
        </DialogContentText>
        <FormControl sx={{ mt: 2 }}>
          <FormLabel
            sx={{ color: themeTokens.color.text.primary, fontWeight: "bold" }}
            id="maximum-targets-group-label"
          >
            Maximum deployment target usage
          </FormLabel>
          <RadioGroup
            name="maximum-targets-group"
            value={hasSpecifiedLimit}
            onChange={(e) => setHasSpecifiedLimit(e.target.value === "true")}
            aria-labelledby="maximum-targets-group-label"
          >
            <FormControlLabel
              value={false}
              control={<Radio aria-labelledby={"maximum-targets-unlimited-option-label"} />}
              label={"Unlimited"}
              id={"maximum-targets-unlimited-option-label"}
            />
            <FormControlLabel
              value={true}
              control={<Radio aria-labelledby={"maximum-targets-limited-option-label"} />}
              label={"Limited"}
              id={"maximum-targets-limited-option-label"}
            />
          </RadioGroup>
        </FormControl>
        {hasSpecifiedLimit && (
          <Box ml={"33px"} mt={1}>
            <TextField
              value={value}
              onChange={handleOnValueChanged}
              label="Maximum targets"
              type="number"
              inputProps={{ min: 1, "aria-label": "Maximum targets" }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOnClose()} variant="outlined" disabled={isUpdating} autoFocus>
          Close
        </Button>
        <LoadingButton
          onClick={updateMaximumTargets}
          variant="contained"
          loading={isUpdating}
          sx={{ width: isUpdating ? "140px" : undefined }}
          disabled={hasSpecifiedLimit && (!value || value <= 0)}
          loadingIndicator={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="inherit" size={16} sx={{ mr: 1 }} />
              {"Updating..."}
            </Box>
          }
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
