import React from "react";
import { useLocation } from "react-router-dom";
import type { SxProps, Theme } from "@mui/material";
import { AlertTitle } from "@mui/material";
import type { AlertColor } from "@mui/material/Alert/Alert";
import { AlertWithShadow } from "components/alert/AlertWithShadow";

type NavigationAlertProps = {
  sx?: SxProps<Theme>;
};
export type NavigationAlertMessage = {
  title?: string;
  content?: string | React.ReactNode;
  severity: AlertColor;
};
export function createNavigationAlert(message: NavigationAlertMessage) {
  return {
    state: {
      navigationAlert: message,
    },
  };
}

export function NavigationAlert(props: NavigationAlertProps) {
  const { sx } = props;
  const { state } = useLocation();

  const { navigationAlert } = state ?? {};

  if (!navigationAlert) {
    return null;
  }

  const { title, content, severity } = navigationAlert;
  return (
    <AlertWithShadow severity={severity} sx={sx}>
      {title && <AlertTitle>{title}</AlertTitle>}
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </AlertWithShadow>
  );
}
