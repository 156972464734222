const ContactSalesReasonsKeys = {
  BillingOptions: "billing-options",
  ServerLicenseRenewal: "server-license-renewal",
  Enterprise: "enterprise",
  Professional: "professional",
  Starter: "starter",
  Community: "community",
  UsageEstimation: "usage-estimation",
  VolumePricing: "volume-pricing",
  ModifySubscription: "modify-subscription",
  Other: "other",
} as const;

export const contactSalesReasons = Object.values(ContactSalesReasonsKeys);

export type ContactSalesReason = (typeof contactSalesReasons)[number];

export const ContactSalesReasonLabels: Record<ContactSalesReason, string> = {
  "billing-options": "Billing options",
  "server-license-renewal": "Arrange license renewal",
  enterprise: "Enterprise",
  professional: "Professional",
  starter: "Starter",
  community: "Community",
  "usage-estimation": "Usage estimation",
  "volume-pricing": "Volume pricing",
  "modify-subscription": "Make changes to my subscription",
  other: "Other",
};

export const ContactSalesMessageTemplates: Record<ContactSalesReason, string> = {
  "billing-options": "",
  "server-license-renewal":
    "Hello,\n\nI would like to arrange my next Server license renewal. <Please specify if you would like to change your licensed deployment target quantity.>\n\nThanks.",
  enterprise: "",
  professional: "",
  starter: "",
  community: "",
  "usage-estimation": "",
  "volume-pricing": "",
  "modify-subscription": "",
  other: "",
};
