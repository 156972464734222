import React from "react";
import { useSearchParams } from "react-router-dom";
import { CircularProgress, Grid, Typography, styled } from "@mui/material";
import { PaymentEventTypes } from "shared/PaymentEvents";
import type { ZuoraCallbackPageParams } from "shared/ZuoraCallbackPageTypes";
import { ResponseFrom } from "shared/ZuoraCallbackPageTypes";

const getZuoraCallbackParams = (searchParams: URLSearchParams): ZuoraCallbackPageParams => {
  const baseParams = {
    success: searchParams.get("success") === "true",
    responseFrom: searchParams.get("responseFrom") ?? "unknown",
    rawGatewayInfo: searchParams.get("rawGatewayInfo") ?? "",
    signature: searchParams.get("signature") ?? "",
    token: searchParams.get("token") ?? "",
    tenantId: searchParams.get("tenantId") ?? "",
    pageId: searchParams.get("pageId") ?? "",
    timestamp: searchParams.get("timestamp") ?? "",
    fieldPassthrough1: searchParams.get("fieldPassthrough1") ?? undefined,
    fieldPassthrough2: searchParams.get("fieldPassthrough2") ?? undefined,
    fieldPassthrough3: searchParams.get("fieldPassthrough3") ?? undefined,
    fieldPassthrough4: searchParams.get("fieldPassthrough4") ?? undefined,
    fieldPassthrough5: searchParams.get("fieldPassthrough5") ?? undefined,
  };

  if (baseParams.success) {
    return {
      responseFrom: baseParams.responseFrom,
      success: baseParams.success,
      rawGatewayInfo: baseParams.rawGatewayInfo,
      signature: baseParams.signature,
      token: baseParams.token,
      tenantId: baseParams.tenantId,
      pageId: baseParams.pageId,
      timestamp: baseParams.timestamp,
      refId: searchParams.get("refId") ?? "",
      fieldPassthrough1: baseParams.fieldPassthrough1,
      fieldPassthrough2: baseParams.fieldPassthrough2,
      fieldPassthrough3: baseParams.fieldPassthrough3,
      fieldPassthrough4: baseParams.fieldPassthrough4,
      fieldPassthrough5: baseParams.fieldPassthrough5,
    };
  }

  return {
    responseFrom: baseParams.responseFrom,
    success: baseParams.success,
    rawGatewayInfo: baseParams.rawGatewayInfo,
    signature: baseParams.signature,
    token: baseParams.token,
    tenantId: baseParams.tenantId,
    pageId: baseParams.pageId,
    timestamp: baseParams.timestamp,
    errorField: searchParams.get("errorField") ?? "",
    errorCode: searchParams.get("errorCode") ?? "",
    errorMessage: searchParams.get("errorMessage") ?? "",
    fieldPassthrough1: baseParams.fieldPassthrough1,
    fieldPassthrough2: baseParams.fieldPassthrough2,
    fieldPassthrough3: baseParams.fieldPassthrough3,
    fieldPassthrough4: baseParams.fieldPassthrough4,
    fieldPassthrough5: baseParams.fieldPassthrough5,
  };
};

const BodyStyle = () => <style>{`body { background: transparent; }`}</style>;

const Container = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 412px;
`;

export function ZuoraCallbackPage() {
  const [searchParams] = useSearchParams();
  const callbackParams = getZuoraCallbackParams(searchParams);
  const { success, responseFrom } = callbackParams;
  const error = !success || !(responseFrom === ResponseFrom.SubmitPage || responseFrom === ResponseFrom["3DS"]);

  if ((responseFrom === ResponseFrom.SubmitPage || responseFrom === ResponseFrom["3DS"]) && callbackParams.success) {
    // payment successful
    const {
      tenantId,
      token,
      timestamp,
      pageId,
      refId,
      signature,
      fieldPassthrough1,
      fieldPassthrough2,
      fieldPassthrough3,
      fieldPassthrough4,
      fieldPassthrough5,
    } = callbackParams;
    const event = new CustomEvent(PaymentEventTypes.PaymentSuccessful, {
      detail: {
        responseFrom,
        tenantId,
        token,
        timestamp,
        pageId,
        refId,
        signature,
        fieldPassthrough1,
        fieldPassthrough2,
        fieldPassthrough3,
        fieldPassthrough4,
        fieldPassthrough5,
      },
    });
    window.parent.dispatchEvent(event);
  } else {
    // payment failure
    const errorDetails = (() => {
      if (!callbackParams.success) {
        const { errorCode, errorField, errorMessage } = callbackParams;
        return { errorCode, errorField, errorMessage };
      }
    })();
    const event = new CustomEvent(PaymentEventTypes.PaymentFailure, {
      detail: { responseFrom, ...errorDetails, searchParams: searchParams.toString() },
    });
    window.parent.dispatchEvent(event);
  }

  return (
    <>
      <BodyStyle />
      <Container>
        <Grid item xs={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress />
          {!error && (
            <Typography align="center" variant="body1" gutterBottom>
              Processing your payment
            </Typography>
          )}
        </Grid>
      </Container>
    </>
  );
}
