import React from "react";
import { Link } from "@mui/material";
import type { LinkProps } from "@mui/material";
import { themeTokens } from "components/Theme/theme";

type LinkButtonProps = Pick<
  LinkProps,
  "children" | "variant" | "fontSize" | "fontWeight" | "underline" | "textAlign" | "sx"
> & { type?: "button" | "submit"; onClick?: React.MouseEventHandler<HTMLButtonElement> };

// eslint-disable-next-line jsdoc/require-param
/**
 * A button that is styled to look like a link
 */
export function LinkButton({
  children,
  type = "button",
  onClick,
  variant = "body2",
  fontSize = "inherit",
  fontWeight = 500,
  underline = "none",
  textAlign,
  sx,
}: LinkButtonProps) {
  return (
    <Link
      component={"button"}
      type={type}
      variant={variant}
      onClick={onClick}
      underline={underline}
      fontSize={fontSize}
      fontWeight={fontWeight}
      textAlign={textAlign}
      sx={{
        cursor: "pointer",
        color: themeTokens.color.text.link.default,
        ":hover": {
          color: themeTokens.color.text.link.hover,
        },
        ":focus": {
          outlineColor: "revert",
        },
        ...sx,
      }}
    >
      {children}
    </Link>
  );
}
