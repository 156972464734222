/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from "react";
import type { PropsWithChildren } from "react";
import { matchPath } from "react-router-dom";
import { NavLink as ReactNavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { flatten } from "lodash";
import Section from "components/Section";
import styles from "./style.module.less";

export class Navigation {
  static navItem(label: string | JSX.Element, path: string, exact?: boolean, show: boolean = true) {
    return { label, path, exact: exact || false, show };
  }

  static navGroup(label: string, path: string | undefined, children: NavItem[], alwaysOpen?: boolean): NavGroup {
    return {
      groupLabel: label,
      defaultChildPath: path,
      children,
      alwaysOpen,
    };
  }
}

interface NavLink {
  label: string | JSX.Element;
  path: string;
  exact: boolean;
  show: boolean;
}

interface NavGroup {
  groupLabel: string;
  defaultChildPath: string | undefined;
  children: NavItem[];
  alwaysOpen?: boolean;
}

export type NavItem = NavLink | NavGroup;

type NavigationSidebarLayoutProps = PropsWithChildren<{
  name?: string;
  resourceType?: string;
  preNavbarComponent?: React.ReactNode;
  preNavbarComponentVisible?: boolean;
  navLinks: NavItem[];
  navLinksVisible?: boolean;
}>;

const NavigationSidebarLayout = (props: NavigationSidebarLayoutProps) => {
  const shouldDisplay = (value?: boolean) => value ?? true;

  return (
    <div>
      <div className={styles.sidebarLayout}>
        <div className={styles.sideMenu}>
          <Section>
            {props.name && (
              <div className={styles.name}>
                {props.resourceType && <div className={styles.resourceType}>{props.resourceType}</div>}
                {props.name}
              </div>
            )}
            {props.preNavbarComponent && shouldDisplay(props.preNavbarComponentVisible) && (
              <div className={styles.preNav}>{props.preNavbarComponent}</div>
            )}
          </Section>
          {shouldDisplay(props.navLinksVisible) && (
            <nav className={styles.links}>
              {props.navLinks
                .filter((x: NavItem) => !!x)
                .map((link: NavItem, index: number) => (
                  <NavItemComponent item={link} key={index} />
                ))}
            </nav>
          )}
        </div>
        <div className={styles.sidebarLayoutContent} key="content">
          {props.children}
        </div>
      </div>
    </div>
  );
};

function isGroup(item: NavItem): item is NavGroup {
  return (item as NavGroup).children !== undefined;
}

type NavItemComponentProps = { item: NavItem };

const NavItemComponent = (props: NavItemComponentProps) => {
  const item = props.item;
  return isGroup(item) ? renderNavLinkGroup(item) : renderNavLink(item.label, item.path, item.exact, item.show);

  function renderNavLinkGroup(group: NavGroup) {
    return <NavLinkGroup group={group} />;
  }
};

function renderNavLink(label: string | JSX.Element, path: string, exact: boolean, show: boolean) {
  if (!show) return <></>;

  return (
    <ReactNavLink
      key={path}
      to={path}
      caseSensitive={false}
      end={exact}
      className={({ isActive }) => (isActive ? classNames(styles.link, styles.selected) : styles.link)}
    >
      {label}
    </ReactNavLink>
  );
}

const NavLinkGroup = (props: { group: NavGroup }) => {
  const location = useLocation();
  const allDescendantLinks = getDescendantLinks(props.group);
  const anyDescendantsMatch = allDescendantLinks.some((l) => !!matchPath(location.pathname, l.path));
  return (
    <div>
      <div
        className={classNames(
          styles.nestedNavLinksParent,
          anyDescendantsMatch ? styles.nestedNavLinksParentSelected : null
        )}
      >
        {!!props.group.defaultChildPath ? (
          renderNavLink(props.group.groupLabel, props.group.defaultChildPath, true, true)
        ) : (
          <div className={styles.sidebarListSubtitle}>{props.group.groupLabel}</div>
        )}
      </div>
      {(anyDescendantsMatch || props.group.alwaysOpen) && (
        <div className={styles.nestedNavLinks}>
          {props.group.children.map((g, index) => (
            <NavItemComponent key={index} item={g} />
          ))}
        </div>
      )}
    </div>
  );
};

function getDescendantLinks(group: NavGroup): NavLink[] {
  return flatten(
    group.children.map((c) => {
      if (isGroup(c)) {
        return getDescendantLinks(c);
      }
      return [c];
    })
  );
}

export default NavigationSidebarLayout;
