import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { DateText } from "@cpt/components";
import { isServerTrial } from "utils/isTrial";
import type { TrialServerLicensingChannelDto } from "client/api/ServerLicenseApi";
import { LicenseChannelChip } from "components/LicenseChannelChip/LicenseChannelChip";
import { RouterLink } from "components/RouterLink/RouterLink";
import { themeTokens } from "components/Theme/theme";
import { PolicyAuthorized } from "components/authorization/PolicyAuthorized";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { ServerLicensesRoutesMap } from "areas/serverLicenses/ServerLicensesRoutesMap";
import type { ServerLicensingChannelViewProps } from "./ServerLicensingChannelViewProps";

export function TrialServerLicensingChannelView({
  serverLicense,
  channel,
}: ServerLicensingChannelViewProps<TrialServerLicensingChannelDto>) {
  const isTrial = isServerTrial(channel.channelType);

  if (!isTrial) {
    throw new Error("Rendering TrialLicensingChannelView for non-trial subscription.");
  }

  const now = DateTime.now();
  let planStatusPrefix = "Expires";
  if (serverLicense.expiryDate < now) {
    planStatusPrefix = "Expired";
  }

  return (
    <>
      <Grid item sm={3} sx={{ padding: 3 }}>
        <Typography fontWeight={700}>Current plan</Typography>
      </Grid>
      <Grid item sm={6} sx={{ padding: 3 }}>
        <Typography marginBottom={1} component={"div"}>
          Octopus Server Trial <LicenseChannelChip channelType={channel.channelType} />
        </Typography>
        <Typography sx={{ color: themeTokens.color.text.secondary }}>
          {planStatusPrefix} <DateText date={channel.expiryDate} />
        </Typography>
      </Grid>
      <Grid item sm={3} sx={{ padding: 3 }}>
        <Stack spacing={1} sx={{ alignItems: "flex-end" }}>
          <PolicyAuthorized
            permission={RbacPermissions.ServerLicense.ManageBilling}
            assetId={serverLicense?.id}
            subscriptionGroupId={serverLicense?.subscriptionGroup?.id}
          >
            {serverLicense.isFulfilled && (
              <RouterLink to={ServerLicensesRoutesMap.detail(serverLicense.id).upgrade}>Upgrade Plan</RouterLink>
            )}
          </PolicyAuthorized>
        </Stack>
      </Grid>
    </>
  );
}
