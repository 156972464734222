import React from "react";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import type { RbacPermission } from "components/authorization/RbacPermissions";

type PolicyAuthorizedProps = React.PropsWithChildren<{
  permission: RbacPermission;
  assetId: string | undefined;
  subscriptionGroupId: string | undefined;
}>;

export function PolicyAuthorized(props: PolicyAuthorizedProps) {
  const { permission, assetId, subscriptionGroupId } = props;
  const { hasPermissionForAsset } = useUserContext();

  return <>{hasPermissionForAsset(permission, assetId, subscriptionGroupId) ? props.children : undefined}</>;
}
