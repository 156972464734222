export const RoutesMap = {
  root: "/",
  inviteAccept: (invite: string) => ({
    root: `/subscription-groups/invite/accept/${invite}`,
  }),
  completingPayment: "/completing-payment",
  completingUpgrade: "/completing-upgrade",
  completingSetup: "/completing-renewal-setup",
  completingTrialFulfillment: "/completing-trial",
  zuoraCallbackPage: `/zuora/callback`,
  notFound: "/not-found",
  fallback: "*",
};
