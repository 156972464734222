import React, { useEffect, useRef, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTheme } from "@mui/material";
import SyntaxHighlighter from "react-syntax-highlighter";
import { fontSize } from "@octopusdeploy/design-system-tokens";
import { themeTokens } from "components/Theme/theme";
import { DarkStyle } from "components/form/FormattedTextAreaStyles/DarkStyle";
import CopyToClipboardWrapper from "../CopyToClipboardWrapper";
import HoverOverlay from "../HoverOverlay";
import { LightStyle } from "./FormattedTextAreaStyles/LightStyle";

export type FormattedTextAreaStyleProps = {
  forceTheme?: "dark" | "light";
  hideLineNumbers?: boolean;
  wrapLongLines?: boolean;
  defaultColorOverride?: string;
};

type FormattedTextAreaProps = {
  value: string | null;
  language: string;
} & FormattedTextAreaStyleProps;

export function FormattedTextArea({
  value,
  language,
  forceTheme,
  hideLineNumbers,
  wrapLongLines,
  defaultColorOverride,
}: FormattedTextAreaProps) {
  const theme = useTheme();
  const [copied, setCopied] = useState(false);
  const [scrollbarHeight, setScrollbarHeight] = useState<number | undefined>();
  const isDarkMode = (forceTheme ?? theme.palette.mode) === "dark";
  const containerRef = useRef<HTMLPreElement>(null);

  const SyntaxHighlighterContainer = (props: React.PropsWithChildren<{}>) => (
    <pre {...props} ref={containerRef}>
      {props.children}
    </pre>
  );

  useEffect(() => {
    if (containerRef.current) {
      const scrollbarHeight = containerRef.current.offsetHeight - containerRef.current.clientHeight;

      if (scrollbarHeight > 0) {
        setScrollbarHeight(scrollbarHeight);
      }
    }
  }, []);

  const customStyle: { [key: string]: string | number } = {
    borderColor: themeTokens.color.border.primary,
    borderWidth: 1,
    fontSize: fontSize.xSmall,
    padding: "14px",
    borderRadius: "4px",
    margin: 0,
  };

  if (defaultColorOverride) {
    customStyle.color = defaultColorOverride;
  }

  return (
    <CopyToClipboardWrapper value={value} onCopied={() => setCopied(true)}>
      <HoverOverlay
        disabled={!value}
        hoverText={copied ? "Copied!" : "Copy To Clipboard"}
        hoverIcon={<ContentCopyIcon />}
        scrollbarHeight={scrollbarHeight}
      >
        <SyntaxHighlighter
          data-testid="syntax-highlighter"
          role="code"
          wrapLongLines={wrapLongLines}
          showLineNumbers={!hideLineNumbers}
          language={language}
          style={isDarkMode ? DarkStyle : LightStyle}
          customStyle={customStyle}
          PreTag={SyntaxHighlighterContainer}
        >
          {value ?? ""}
        </SyntaxHighlighter>
      </HoverOverlay>
    </CopyToClipboardWrapper>
  );
}
