import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import useAsyncRequest from "hooks/useAsyncRequest";
import { getServerLicenseBySerial } from "client/api/ServerLicenseApi";

export function ServerLicenseLoadViaSerial() {
  const { serialId } = useParams();

  const { data, loading, error, refresh } = useAsyncRequest(getServerLicenseBySerial, serialId || "");

  const navigate = useNavigate();

  if (data) {
    navigate(`/server-licenses/${data.id}`);
  }

  if (error) {
    return <Alert severity={"error"}>{error.message}</Alert>;
  }

  return <></>;
}
