import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { envConfig } from "utils/envConfig";

export type LicenseHeaderProps = {
  title: string;
  trialTitle: string;
  trialType?: "cloud" | "server";
};

export function LicenseHeader(props: LicenseHeaderProps) {
  const { title, trialTitle, trialType } = props;

  const trialLink = trialType
    ? `${envConfig.OctopusComDomain}/start/${trialType}`
    : `${envConfig.OctopusComDomain}/start/`;

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant={"h5"} padding="16px" fontWeight={600} fontSize="1.5rem">
          {title}
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" spacing={2} padding={2}>
        <Button
          variant="contained"
          disableRipple={true}
          disableElevation
          target={"_blank"}
          href={trialLink}
          size={"small"}
          color={"info"}
          sx={{
            ":hover": {
              color: "white",
            },
            textTransform: "none",
          }}
        >
          {trialTitle}
        </Button>
      </Stack>
    </Stack>
  );
}
