import type { Dispatch } from "react";
import React, { useMemo, useState } from "react";
import { TableCell, TableRow, TableHead, Table, TableBody } from "@mui/material";
import type { AxiosError } from "axios";
import type {
  BaseUserGrantsApi,
  CloudSubscriptionOrSubscriptionGroupUserGrantDto,
  UserGrantDto,
  UserGrantInviteDto,
} from "client/api/UserGrantApi";
import { ActionDialog } from "components/ActionDialog/ActionDialog";
import { UserGrantInviteRow } from "./UserGrantInviteRow";

export type UserGrantsTableProps<TUserGrant extends UserGrantDto> = {
  userGrantInvites?: UserGrantInviteDto[];
  grantsApi: BaseUserGrantsApi<TUserGrant>;
  assetId: string;
  canRevokeInvite: () => boolean;
  refresh: () => void;
  onDeleteError: Dispatch<AxiosError | undefined>;
};

export function UserGrantsInviteTable<TUserGrant extends CloudSubscriptionOrSubscriptionGroupUserGrantDto>(
  props: UserGrantsTableProps<TUserGrant>
) {
  const { userGrantInvites, grantsApi, assetId, canRevokeInvite, refresh, onDeleteError } = props;
  const [userGrantInviteToRevoke, setUserGrantInviteToRevoke] = useState<UserGrantInviteDto | undefined>();
  const onUserGrantInviteRevoke = (userGrantInvite: UserGrantInviteDto) => {
    setUserGrantInviteToRevoke(userGrantInvite);
  };

  const onUserGrantInviteRevokeConfirm = async () => {
    if (!userGrantInviteToRevoke) {
      return;
    }
    onDeleteError(undefined);
    await grantsApi.revokeInvite(assetId, userGrantInviteToRevoke.id).catch((error) => {
      onDeleteError(error);
    });
    setUserGrantInviteToRevoke(undefined);
    refresh();
  };

  const onUserGrantInviteRevokeCancel = async () => {
    setUserGrantInviteToRevoke(undefined);
  };

  const sortedUserGrants = useMemo(() => {
    return userGrantInvites?.sort((a, b) => {
      const buildSortKey = function (invite: UserGrantInviteDto) {
        return `${invite.role.name}${invite.email}`;
      };
      return buildSortKey(a).localeCompare(buildSortKey(b));
    });
  }, [userGrantInvites]);

  return (
    <>
      <Table size="small" aria-label="Invitations">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "45%" }}>Email</TableCell>
            <TableCell sx={{ width: "45%" }}>Role</TableCell>
            <TableCell sx={{ width: "10%" }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedUserGrants?.map((userGrantInvite) => (
            <UserGrantInviteRow
              showDelete={canRevokeInvite()}
              userGrantInvite={userGrantInvite}
              onDeleteClicked={() => onUserGrantInviteRevoke(userGrantInvite)}
              key={userGrantInvite.id}
              loading={userGrantInvite.id === userGrantInviteToRevoke?.id}
            />
          ))}
        </TableBody>
      </Table>
      <ActionDialog
        title={"Revoke invitation"}
        description={`The invitation to ${userGrantInviteToRevoke?.email} will no longer be valid and can not be accepted.`}
        show={!!userGrantInviteToRevoke}
        confirmText="Revoke"
        onConfirm={onUserGrantInviteRevokeConfirm}
        onCancel={onUserGrantInviteRevokeCancel}
      />
    </>
  );
}
