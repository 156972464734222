import type { CloudLicensingChannelTypes } from "client/api/CloudSubscriptionApi";
import { CloudLicensingChannelTypesKeys } from "client/api/CloudSubscriptionApi";
import type { ServerLicensingChannelTypes } from "client/api/ServerLicenseApi";
import { ServerLicensingChannelTypesKeys } from "client/api/ServerLicenseApi";
import { isCloudSubscriptionLicenseChannelType } from "./CloudSubscriptionLicensingChannelTypeHelpers";
import { isServerLicenseChannelType } from "./ServerLicenseLicensingChannelTypeHelpers";

export const isCloudTrial = (channelType: CloudLicensingChannelTypes) =>
  channelType === CloudLicensingChannelTypesKeys.Trial;

export const isServerTrial = (channelType: ServerLicensingChannelTypes) =>
  channelType === ServerLicensingChannelTypesKeys.Trial;

export const isTrial = (channelType: CloudLicensingChannelTypes | ServerLicensingChannelTypes) => {
  if (isCloudSubscriptionLicenseChannelType(channelType)) {
    return isCloudTrial(channelType);
  } else if (isServerLicenseChannelType(channelType)) {
    return isServerTrial(channelType);
  }
  throw new Error("Unexpected channelType");
};
