import axiosClient from "client/axios-client";

const BASE_URL = "purchase";

export type AddressSearchResultDto = {
  countryCode: string;
  countrySubdivision: string;
  countrySubdivisionName: string;
  municipality: string;
  postalCode: string | null;
  streetName: string;
  streetNumber: string | null;
  freeFormAddress: string;
};

export const searchAddress = async (signal: AbortSignal, search: string, countryCode?: string) => {
  const response = await axiosClient.post<AddressSearchResultDto[]>(
    `${BASE_URL}/address-search`,
    {
      search,
      countryCode,
    },
    {
      signal,
    }
  );
  return response.data;
};
