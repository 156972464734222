import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import type { RbacPermission } from "components/authorization/RbacPermissions";

const ITEM_HEIGHT = 48;

export const Overflow = {
  menuItem: (label: string, onClick: () => void): OverflowMenuItem => {
    return { label, onClick };
  },
  policyAuthorizedMenuItem: (
    requiredPolicy: RbacPermission,
    assetId: string = "",
    subscriptionGroupId: string = "",
    label: string,
    onClick: () => void
  ): OverflowMenuItem => {
    return { requiredPolicy, assetId, subscriptionGroupId, label, onClick };
  },
};

interface OverflowMenuItem {
  requiredPolicy?: RbacPermission;
  assetId?: string;
  subscriptionGroupId?: string;
  label: string;
  onClick: () => void;
}

export type OverflowMenuProps = {
  items: Array<OverflowMenuItem>;
  hideWhenEmpty?: boolean;
};

export function OverflowMenu(props: OverflowMenuProps) {
  const { items, hideWhenEmpty } = props;
  const { hasPermissionForAsset } = useUserContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const authorizedMenuItems = items.filter(
    (it) => !it.requiredPolicy || hasPermissionForAsset(it.requiredPolicy, it.assetId, it.subscriptionGroupId)
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClicked = (item: OverflowMenuItem) => {
    handleClose();
    item.onClick();
  };

  if (hideWhenEmpty && !authorizedMenuItems.length) {
    return <></>;
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        disabled={!authorizedMenuItems.length}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {authorizedMenuItems.map((item) => (
          <MenuItem key={item.label} onClick={() => onMenuItemClicked(item)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
