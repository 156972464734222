import React from "react";
import { Grid } from "@mui/material";
import { ExternalUriMap } from "utils/ExternalUriMap";
import { ExternalLink } from "components/ExternalLink/ExternalLink";
import LoadingPlaceholder from "components/LoadingPlaceholder";
import { FormattedTextArea } from "components/form/FormattedTextArea";
import {
  InstallationStep,
  InstallationStepText,
  InstallGuide,
} from "areas/serverLicenses/detail/components/ServerLicenseInstallation/InstallGuide";

function GetDockerCommand(licenseKeyXml: string) {
  const base64LicenseKey = window.btoa(licenseKeyXml);
  let dockerCommand = `docker run \\\n`;
  dockerCommand += `--interactive \\\n`;
  dockerCommand += `--detach \\\n`;
  dockerCommand += `--privileged \\\n`;
  dockerCommand += `--name OctopusDeploy \\\n`;
  dockerCommand += `--publish 1322:8080 \\\n`;
  dockerCommand += `--env ACCEPT_EULA="Y" \\\n`;
  dockerCommand += `--env DB_CONNECTION_STRING="..." \\\n`;
  dockerCommand += `--env OCTOPUS_SERVER_BASE64_LICENSE="${base64LicenseKey}" \\\n`;
  dockerCommand += `octopusdeploy/octopusdeploy`;

  return dockerCommand;
}

interface LinuxInstallGuideProps {
  licenseXml?: string;
  loading?: boolean;
  error?: Error;
}

export function LinuxInstallGuide(props: LinuxInstallGuideProps) {
  const { licenseXml, loading, error } = props;
  return (
    <InstallGuide>
      <InstallationStep stepNumber={1} label="Install Octopus with a Docker command">
        <InstallationStepText>
          Here’s an example command to start the Octopus Server container on Linux.
        </InstallationStepText>
        <Grid item xs={12}>
          <LoadingPlaceholder
            small
            loading={loading}
            error={error}
            loadingLabel="Loading Docker command"
            errorLabel="Error loading Docker command"
          >
            <FormattedTextArea value={GetDockerCommand(licenseXml ?? "")} language="bash" hideLineNumbers={true} />
          </LoadingPlaceholder>
        </Grid>
        <InstallationStepText>Read the docs for more configuration options.</InstallationStepText>
        <Grid item xs={12}>
          <ExternalLink href={ExternalUriMap.octopusComDocsLinuxInstallationPath}>Explore the docs</ExternalLink>
        </Grid>
      </InstallationStep>

      <InstallationStep stepNumber={2} label="Your first deployment">
        <InstallationStepText>
          Start using your new Octopus instance by doing your first deployment.
        </InstallationStepText>
        <Grid item xs={12}>
          <ExternalLink href={ExternalUriMap.octopusComDocsFirstDeploymentPath}>Follow the tutorial</ExternalLink>
        </Grid>
      </InstallationStep>
    </InstallGuide>
  );
}
