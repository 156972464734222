import axiosClient from "client/axios-client";

const BASE_URL = "bff/productpricing";

export type GetProductPricingResponse = {
  products: {
    name: string;
    prices: {
      projectQuantity: string;
      totalPrice: number;
    }[];
  }[];
};

export const getProductPricing = async (): Promise<GetProductPricingResponse> => {
  const response = await axiosClient.get<GetProductPricingResponse>(BASE_URL);

  return response.data;
};

export const CloudPricingCatalogProducts = {
  CloudStarter: "CloudStarter",
  CloudProfessional: "CloudProfessional",
  CloudEnterprise: "CloudEnterprise",
} as const;

export const ServerPricingCatalogProducts = {
  ServerStarter: "ServerStarter",
  ServerProfessional: "ServerProfessional",
  ServerEnterprise: "ServerEnterprise",
} as const;
