import axiosClient from "client/axios-client";

const BASE_URL = "purchase";

export type State = {
  twoDigitIsoCode: string;
  name: string;
};

export type Country = {
  twoDigitIsoCode: string;
  name: string;
  states: State[];
};

export const listCountries = async () => {
  const response = await axiosClient.get<Country[]>(`${BASE_URL}/countries`);
  return response.data;
};
