import React from "react";
import { Navigate, useLocation } from "react-router-dom";

export function StripTrailingSlash({ ...rest }) {
  const location = useLocation();

  if (location.pathname !== "/" && location.pathname.match(/.*\/$/)) {
    return (
      <Navigate
        replace
        to={{
          pathname: location.pathname.replace(/\/+$/, ""),
          search: location.search,
        }}
      />
    );
  }
  return null;
}
