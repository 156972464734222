import React, { createContext, useState, useEffect, useContext } from "react";
import type { ReactNode } from "react";
import type { Country } from "client/api/CountriesApi";
import { listCountries } from "client/api/CountriesApi";

interface CountriesContextProps {
  countries: Country[];
  getCountryFromCode: (countryCode: string) => Country | undefined;
  loading: boolean;
  error: Error | null;
}

const CountriesContext = createContext<CountriesContextProps | undefined>(undefined);

interface CountriesProviderProps {
  children: ReactNode;
}

export const CountriesProvider: React.FC<CountriesProviderProps> = ({ children }) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const getCountryFromCode = (countryCode: string): Country | undefined => {
    return countries.find((country) => country.twoDigitIsoCode === countryCode);
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countries = await listCountries();
        setCountries(countries);
        setLoading(false);
      } catch (err) {
        setError({ name: "CountriesProvider", message: "Failed to load countries" });
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  return (
    <CountriesContext.Provider value={{ countries, getCountryFromCode, loading, error }}>
      {children}
    </CountriesContext.Provider>
  );
};

export const useCountries = (): CountriesContextProps => {
  const context = useContext(CountriesContext);
  if (context === undefined) {
    throw new Error("useCountries must be used within a CountriesProvider");
  }
  return context;
};
