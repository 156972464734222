import { isServerLicenseChannelType } from "utils/ServerLicenseLicensingChannelTypeHelpers";
import type { CloudLicensingChannelTypes } from "client/api/CloudSubscriptionApi";
import type { ServerLicensingChannelTypes } from "client/api/ServerLicenseApi";
import { ServerLicensingChannelTypesKeys } from "client/api/ServerLicenseApi";

export const isServerTemporary = (channelType: ServerLicensingChannelTypes) =>
  channelType === ServerLicensingChannelTypesKeys.Temporary;

export const isTemporary = (channelType: CloudLicensingChannelTypes | ServerLicensingChannelTypes) =>
  isServerLicenseChannelType(channelType) && isServerTemporary(channelType);
