import { styled } from "@mui/material";

export const FaqTitle = styled("h2")(
  ({ theme }) => `
    margin: 0;
    margin-bottom: 1.8rem;
    text-align: center;
    font-size: 1.8rem;
    font-weight: ${theme.typography.fontWeightBold};
    line-height: 1.5;
  `
);
