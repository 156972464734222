import React from "react";

type ResourceUsageCopyProps = {
  limitInGb?: number;
  usageInGb?: number;
};

export function ResourceUsageCopy({ limitInGb, usageInGb }: ResourceUsageCopyProps) {
  if (limitInGb === undefined || usageInGb === undefined) return null;

  return (
    <>
      <strong>{formatUnits(usageInGb)}</strong> of {formatUnits(limitInGb)} used
    </>
  );
}

function formatUnits(amountInGb: number) {
  return amountInGb >= 1000 ? `${amountInGb / 1000}TB` : `${amountInGb}GB`;
}
