import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { OpenInNew } from "@mui/icons-material";
import { Box, CircularProgress, Link, Stack, Typography } from "@mui/material";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import { usePolling } from "hooks/usePolling";
import { getHostname } from "utils/formatters/DnsFormatters";
import { checkCloudSubscriptionFulfilled, type CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import type { ServerLicenseDto } from "client/api/ServerLicenseApi";
import { ErrorAlert } from "components/alert/ErrorAlert";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { NotFoundPage } from "pages/NotFoundPage";
import { CheckoutPrimaryButton } from "areas/checkout/components/CheckoutButtons";
import { TentacleHeartIcon } from "areas/checkout/components/TentacleHeartIcon";
import { ServerLicensesRoutesMap } from "areas/serverLicenses/ServerLicensesRoutesMap";
import { SelfFetchingLicenseKeyView } from "areas/serverLicenses/components/LicenseKeyView";

interface CheckoutCompleteInputProps {
  subscriptionId?: string;
  subscriptionType?: "cloud" | "selfhosted";
}

export function CheckoutComplete(props: CheckoutCompleteInputProps) {
  const { userInfo } = useUserContext();
  const { entity: license } = useEntity<ServerLicenseDto>();
  const { entity: cloudSubscription } = useEntity<CloudSubscriptionDto>();
  const { subscriptionId, subscriptionType } = props;

  if (!subscriptionId || (subscriptionType !== "cloud" && subscriptionType !== "selfhosted")) {
    return <NotFoundPage />;
  }

  if (!license && subscriptionType === "selfhosted") return <NotFoundPage />;
  if (!cloudSubscription && subscriptionType === "cloud") return <NotFoundPage />;

  return (
    <Box paddingX={14} paddingTop={5} paddingBottom={16}>
      <Stack spacing={2}>
        <TentacleHeartIcon />
        <Typography variant={"h5"} fontWeight={"700"}>
          Order complete, thank you for subscribing
        </Typography>
        <Box>
          <Typography>
            An email was sent to <Link href={`mailto:${userInfo?.email}`}>{userInfo?.email}</Link> containing the
            receipt and invoice for your purchase.
          </Typography>
        </Box>
        {subscriptionType === "cloud" && cloudSubscription && (
          <CheckoutCompleteCloud cloudSubscription={cloudSubscription} />
        )}
        {subscriptionType === "selfhosted" && license && <CheckoutCompleteSelfHosted license={license} />}
      </Stack>
    </Box>
  );
}

const CheckoutCompleteCloud = ({ cloudSubscription }: { cloudSubscription: CloudSubscriptionDto }) => {
  const cloudSubscriptionId = cloudSubscription?.id;
  const [loading, setLoading] = useState<boolean>(true);
  const [isFulfilled, setIsFullfilled] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);

  const checkProvisioned = async () => {
    const check = await checkCloudSubscriptionFulfilled(cloudSubscriptionId);
    if (check) {
      try {
        setIsFullfilled(true);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        if (e instanceof Error) {
          setError(e);
        }
      }
    }
  };

  usePolling(checkProvisioned, loading);

  const cloudInstanceLink = `https://${getHostname(cloudSubscription.dnsPrefix)}`;

  if (!isFulfilled) {
    return (
      <>
        <Box>
          <Stack direction="row" spacing={1}>
            <Typography fontWeight={700}>Your Octopus is being setup</Typography>
            <Typography fontSize={"medium"}>– this should take less than a minute.</Typography>
          </Stack>
        </Box>
        <Stack direction="row" spacing={1}>
          {loading && (
            <>
              <CircularProgress size={25} />
              <Typography color={"#68778D"}>Loading Octopus</Typography>
            </>
          )}
          {error && <ErrorAlert error={error} />}
        </Stack>
      </>
    );
  }
  return (
    <Box>
      <Stack direction="column" spacing={1}>
        <Typography fontWeight={700} fontSize={"large"}>
          Your Octopus is ready!
        </Typography>
        <CheckoutPrimaryButton href={cloudInstanceLink} sx={{ width: "200px" }} endIcon={<OpenInNew />}>
          Check out Octopus
        </CheckoutPrimaryButton>
      </Stack>
    </Box>
  );
};

const CheckoutCompleteSelfHosted = ({ license }: { license: ServerLicenseDto }) => {
  const { hasPermissionForAsset } = useUserContext();
  const canViewLicenseKeyXml = hasPermissionForAsset(
    RbacPermissions.ServerLicense.ViewLicenseKeyXml,
    license.id,
    license.subscriptionGroup?.id
  );

  if (!canViewLicenseKeyXml) {
    return <></>;
  }
  return (
    <Box>
      <Typography fontWeight={700} fontSize={"large"}>
        License Key
      </Typography>
      <Typography sx={{ marginBottom: "10px" }}>
        This information is also available on the{" "}
        <RouterLink to={ServerLicensesRoutesMap.detail(license.id).root}>Overview</RouterLink> page.
      </Typography>
      <SelfFetchingLicenseKeyView serverLicense={license} />
    </Box>
  );
};
