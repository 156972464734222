import React, { useState } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { LoadingButton } from "@mui/lab";
import type { ButtonProps } from "@mui/material";
import { navigateToStripeCustomerPortal } from "utils/navigationHelpers";
import type { ProductType } from "shared/ProductType";
import { PolicyAuthorized } from "components/authorization/PolicyAuthorized";
import type { RbacPermission } from "components/authorization/RbacPermissions";
import { RbacPermissions } from "components/authorization/RbacPermissions";

type StripeCustomerPortalLinkButtonProps = ButtonProps<
  "button",
  CloudSubscriptionLinkButtonProps | ServerLicenseLinkButtonProps
>;

type BaseLinkButtonProps = {
  subscriptionGroupId?: string;
  label: string;
  buttonIcon?: React.ReactNode;
  newTab?: boolean;
};

type CloudSubscriptionLinkButtonProps = BaseLinkButtonProps & {
  cloudSubscriptionId: string;
};

type ServerLicenseLinkButtonProps = BaseLinkButtonProps & {
  serverLicenseId: string;
};

type InitialDestructuredProps = Omit<StripeCustomerPortalLinkButtonProps, keyof BaseLinkButtonProps>;

function isServerLicenseLinkProps(
  props: InitialDestructuredProps
): props is Omit<ButtonProps<"button", ServerLicenseLinkButtonProps>, keyof BaseLinkButtonProps> {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return (props as ServerLicenseLinkButtonProps).serverLicenseId !== undefined;
}

function isCloudSubscriptionLinkProps(
  props: InitialDestructuredProps
): props is Omit<ButtonProps<"button", CloudSubscriptionLinkButtonProps>, keyof BaseLinkButtonProps> {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return (props as CloudSubscriptionLinkButtonProps).cloudSubscriptionId !== undefined;
}

export function StripeCustomerPortalLinkButton(props: StripeCustomerPortalLinkButtonProps) {
  const { subscriptionGroupId, label, buttonIcon, newTab, ...remainingProps } = props;

  let productId: string = "";
  let permission: RbacPermission = RbacPermissions.CloudSubscription.ManageBilling;
  let productType: ProductType = "CloudSubscription";
  let buttonProps: ButtonProps<"button"> = {};

  if (isServerLicenseLinkProps(remainingProps)) {
    const { serverLicenseId, ...rest } = remainingProps;
    buttonProps = rest;
    productId = serverLicenseId;
    permission = RbacPermissions.ServerLicense.ManageBilling;
    productType = "ServerLicense";
  } else if (isCloudSubscriptionLinkProps(remainingProps)) {
    const { cloudSubscriptionId, ...rest } = remainingProps;
    productId = cloudSubscriptionId;
    buttonProps = rest;
  }

  const [loadingPortal, setLoadingPortal] = useState<boolean>(false);
  const navigateToStripePortal = async () => {
    try {
      setLoadingPortal(true);
      await navigateToStripeCustomerPortal(productType, productId, newTab || false);
    } finally {
      setLoadingPortal(false);
    }
  };

  const defaultButtonIcon = buttonIcon || newTab ? <OpenInNewIcon /> : null;

  return (
    <PolicyAuthorized permission={permission} subscriptionGroupId={subscriptionGroupId} assetId={productId}>
      <LoadingButton
        {...buttonProps}
        loading={loadingPortal}
        onClick={navigateToStripePortal}
        endIcon={defaultButtonIcon}
      >
        {label}
      </LoadingButton>
    </PolicyAuthorized>
  );
}
