import React from "react";
import { PaperLayout } from "components/PaperLayout/PaperLayout";
import { NavigationAlert } from "components/alert/NavigationAlert";
import { DashboardProducts } from "areas/dashboard/components/DashboardProducts";

export function DashboardLayout() {
  return (
    <div>
      <PaperLayout sx={{ marginBottom: 3 }}>
        <NavigationAlert sx={{ borderRadius: 0 }} />
      </PaperLayout>
      <DashboardProducts />
    </div>
  );
}
