import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Alert, Typography } from "@mui/material";
import { RoutesMap } from "RoutesMap";
import axios from "axios";
import type { DateTime } from "luxon";
import { DateText, FontAwesomeSvgIcon } from "@cpt/components";
import { changeStripeProduct } from "client/api/ServerLicenseApi";
import type { UpgradePlan } from "client/api/UpgradePlan";
import { ActionDialog } from "components/ActionDialog/ActionDialog";
import { StripeCustomerPortalLinkButton } from "components/StripeCustomerPortalLink/StripeCustomerPortalLinkButton";
import { themeTokens } from "components/Theme/theme";
import { ErrorAlert } from "components/alert/ErrorAlert";

type ConfirmStripeUpgradeDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => Promise<void> | void;
  productName: string;
  serverLicenseId: string;
  targetPlan: UpgradePlan;
  varyByTarget: boolean;
  nextRenewalDate?: DateTime;
};

export function ConfirmStripeUpgradeDialog(props: ConfirmStripeUpgradeDialogProps) {
  const { open, setOpen, productName, serverLicenseId, targetPlan, varyByTarget, nextRenewalDate } = props;
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown | undefined>();
  const [billingInformationError, setBillingInformationError] = useState<boolean>(false);

  return (
    <ActionDialog
      title={`Upgrade to ${productName}`}
      show={open}
      confirmText={loading ? "Upgrading..." : "Confirm Upgrade"}
      confirmColor="success"
      onConfirm={async () => {
        setLoading(true);
        try {
          await changeStripeProduct(serverLicenseId, targetPlan);

          navigate({
            pathname: RoutesMap.completingUpgrade,
            search: `serverLicenseId=${serverLicenseId}&priceCode=${targetPlan}`,
          });
        } catch (e) {
          if (axios.isAxiosError(e)) {
            if (e.response?.data === "Stripe billing information is incomplete") setBillingInformationError(true);
            else setError(e);
          } else {
            console.error(e);
          }

          setLoading(false);
        }
      }}
      onCancel={() => setOpen(false)}
    >
      <>
        {billingInformationError && <BillingInformationAlert serverLicenseId={serverLicenseId} />}
        {error && <ErrorAlert error={error} />}
        <Typography pb={1}>Your subscription will immediately be updated to Server {productName}.</Typography>
        {!varyByTarget && nextRenewalDate && (
          <Typography pb={1}>
            You will be charged for your upgraded plan in your next invoice on{" "}
            <DateText date={nextRenewalDate} fontWeight="bold" />
          </Typography>
        )}
        {varyByTarget && nextRenewalDate && (
          <Typography pb={1}>
            Your next bill, charged <DateText date={nextRenewalDate} fontWeight="bold" />, will be based on the number
            of deployment targets you use during this billing period.
          </Typography>
        )}
      </>
    </ActionDialog>
  );
}

const BillingInformationAlert = ({ serverLicenseId }: { serverLicenseId: string }) => (
  <Alert
    sx={{ backgroundColor: themeTokens.color.callout.background.attention.default, marginBottom: 2 }}
    icon={
      <FontAwesomeSvgIcon
        icon={faExclamationCircle}
        sx={{ paddingTop: "4px", fontSize: "14px", color: themeTokens.color.callout.icon.warning }}
      />
    }
  >
    <Typography fontWeight="bold" mb={0.5} color={themeTokens.color.callout.text.warning}>
      Missing billing information
    </Typography>
    <Typography mb={1}>
      Please set up your billing details in Stripe and then return to this page to complete your upgrade.
    </Typography>
    <StripeCustomerPortalLinkButton
      serverLicenseId={serverLicenseId}
      label="Add Stripe billing details"
      variant="contained"
      newTab={true}
    ></StripeCustomerPortalLinkButton>
  </Alert>
);
