import type { MouseEvent } from "react";
import React, { useRef, useState } from "react";
import { Link as RouterLink, useLinkClickHandler } from "react-router-dom";
import { ChevronRightSharp } from "@mui/icons-material";
import { Box, Grid, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, alpha, styled } from "@mui/material";
import { designSystemColors } from "@cpt/shared";
import { getHostname } from "utils/formatters/DnsFormatters";
import { isTrial } from "utils/isTrial";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { GreenButton } from "components/Button/GreenButton";
import { CloudSubscriptionNextDate } from "components/Dashboard/CloudSubscriptions/CloudSubscriptionNextDate";
import { CloudSubscriptionStatusIcon } from "components/Dashboard/CloudSubscriptions/CloudSubscriptionStatusIcon";
import { FulfillmentProgressChip } from "components/Dashboard/FufillmentProgressChip";
import { LicenseChannelChip } from "components/LicenseChannelChip/LicenseChannelChip";
import { themeTokens } from "components/Theme/theme";
import { PolicyAuthorized } from "components/authorization/PolicyAuthorized";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { CloudSubscriptionsRoutesMap } from "areas/cloudSubscriptions/CloudSubscriptionRoutesMap";
import { CloudSubscriptionWarningChip } from "./CloudSubscriptionWarningChip";

interface CloudSubscriptionProps {
  cloudSubscription: CloudSubscriptionDto;
}

const UpgradeButton = React.forwardRef<
  HTMLButtonElement,
  {
    cloudSubscription: CloudSubscriptionDto;
    handleMouseOverButtons: () => void;
    handleMouseOutButtons: () => void;
  }
>(({ cloudSubscription, handleMouseOverButtons, handleMouseOutButtons }, ref) => {
  return (
    <GreenButton
      ref={ref}
      variant="contained"
      disableElevation
      onClick={useLinkClickHandler<HTMLButtonElement>(CloudSubscriptionsRoutesMap.detail(cloudSubscription.id).upgrade)}
      onMouseOver={handleMouseOverButtons}
      onMouseOut={handleMouseOutButtons}
      sx={{ fontSize: "0.8125rem" }}
    >
      Upgrade Plan
    </GreenButton>
  );
});

const StyledListItem = styled(ListItem)`
  padding: 0;
  border-top: 1px solid;
  border-color: ${designSystemColors.grey["200"]};
  &:first-of-type {
    border-top-width: 2px;
  }
`;

export function CloudSubscription(props: CloudSubscriptionProps) {
  const { cloudSubscription } = props;
  const isFulfilling = !cloudSubscription.isFulfilled;

  const upgradeButtonRef = useRef<HTMLButtonElement | null>(null);
  const [showRipple, setShowRipple] = useState(false);
  const [showArrow, setShowArrow] = useState(false);

  const handleMouseEnter = () => setShowArrow(true);
  const handleMouseLeave = () => setShowArrow(false);

  const handleMouseOverButtons = () => {
    setShowRipple(true);
    setShowArrow(false);
  };
  const handleMouseOutButtons = () => {
    setShowRipple(false);
    setShowArrow(true);
  };

  const clickedListItem = (e: MouseEvent<HTMLAnchorElement>) => {
    const isEventTargetRef = (ref: React.MutableRefObject<HTMLButtonElement | null>) => {
      return e.target instanceof Node && ref.current && ref.current.contains(e.target);
    };
    if (isEventTargetRef(upgradeButtonRef)) {
      e.preventDefault();
    }
  };

  return (
    <StyledListItem>
      <ListItemButton
        component={RouterLink}
        to={CloudSubscriptionsRoutesMap.detail(cloudSubscription.id).root}
        sx={{
          backgroundColor: themeTokens.color.background.primary.default,
          padding: "12px 16px",
          minHeight: "68px",
          "&:hover": {
            color: themeTokens.color.text.primary,
            backgroundColor: designSystemColors.grey["100"],
          },
        }}
        onClick={clickedListItem}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        disableRipple={showRipple}
      >
        <Grid container>
          <Grid container item xs={12} lg={7}>
            <Stack direction="row" justifyContent="start" alignItems="center">
              <ListItemIcon sx={{ minWidth: "auto" }}>
                <CloudSubscriptionStatusIcon
                  cloudSubscription={cloudSubscription}
                  size="medium"
                  sx={{ width: "24px", height: "24px", margin: "10px 16px", marginLeft: "0" }}
                />
              </ListItemIcon>
              <ListItemText
                sx={{ margin: 0 }}
                primary={
                  <Stack direction="row" alignItems="start" spacing={2}>
                    <span style={{ fontSize: "0.875rem", lineHeight: 1.2 }}>{cloudSubscription.friendlyName}</span>
                    <div style={{ display: "flex", marginLeft: "8px" }}>
                      {cloudSubscription.isFulfilled && (
                        <LicenseChannelChip
                          channelType={cloudSubscription.licensingChannel.channelType}
                          sx={{
                            alignSelf: "flex-start",
                            marginTop: "-4px",
                            marginBottom: "-4px",
                            fontSize: "12px",
                          }}
                        />
                      )}
                      <CloudSubscriptionWarningChip
                        cloudSubscription={cloudSubscription}
                        sx={{
                          alignSelf: "flex-start",
                          marginTop: "-4px",
                          marginBottom: "-4px",
                          fontSize: "12px",
                          color: designSystemColors.red["500"],
                          background: alpha(designSystemColors.red["300"], 0.16),
                        }}
                      />
                    </div>
                  </Stack>
                }
                primaryTypographyProps={{
                  display: "flex",
                  fontWeight: 700,
                  fontSize: "0.875rem",
                  color: designSystemColors.grey["800"],
                }}
                secondary={<>{getHostname(cloudSubscription.dnsPrefix)} </>}
                secondaryTypographyProps={{
                  component: "div",
                  fontSize: "0.8125rem",
                  color: designSystemColors.grey["600"],
                }}
              />
            </Stack>
          </Grid>
          <Grid container item sm={6} lg={2}>
            <Stack direction="row" justifyContent="start" alignItems="center" spacing={2} padding={1}>
              <CloudSubscriptionNextDate cloudSubscription={cloudSubscription} />
            </Stack>
          </Grid>
          <Grid container item sm={6} lg={3} justifyContent="end">
            <Stack direction="row" alignItems="center" spacing={2}>
              {isFulfilling && <FulfillmentProgressChip />}
              {!isFulfilling && isTrial(cloudSubscription.licensingChannel.channelType) && (
                <PolicyAuthorized
                  permission={RbacPermissions.CloudSubscription.ManageBilling}
                  assetId={cloudSubscription?.id}
                  subscriptionGroupId={cloudSubscription?.subscriptionGroup?.id}
                >
                  <UpgradeButton
                    ref={upgradeButtonRef}
                    cloudSubscription={cloudSubscription}
                    handleMouseOverButtons={handleMouseOverButtons}
                    handleMouseOutButtons={handleMouseOutButtons}
                  />
                </PolicyAuthorized>
              )}
              <Box width={"32px"} marginLeft={"8px"}>
                {showArrow && (
                  <ChevronRightSharp
                    className={"animate-fade-in-from-left"}
                    sx={{
                      position: "absolute",
                      top: "calc(50% - 12px)",
                      right: "22px",
                      color: themeTokens.color.text.link.default,
                    }}
                  />
                )}
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </ListItemButton>
    </StyledListItem>
  );
}
