import React from "react";
import { styled } from "@mui/material";
import type { ChangeHandler } from "react-hook-form";

const ButtonLikeLabel = styled("label")`
  display: flex;
  box-sizing: border-box;
  height: 44px;
  border: 1px solid #449be1;
  padding: 8px 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  text-transform: none;
  color: #1a77ca;
  background-color: #fff;
`;
const HiddenRadio = styled("input")`
  position: absolute;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap;

  :checked + label {
    border: none;
    color: #fff;
    background-color: #1a77ca;
  }
`;

const Wrapper = styled("div")(
  ({ fullWidth }: { fullWidth?: boolean }) => `
  ${fullWidth && `flex: 1 0 0;`}
`
);

type CheckoutButtonLikeRadioProps = {
  id: string;
  name: string;
  value: string;
  fullWidth?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement> | ChangeHandler;
} & React.PropsWithChildren;

export const CheckoutButtonLikeRadio = React.forwardRef(
  (props: CheckoutButtonLikeRadioProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { id, name, value, children, fullWidth, onChange } = props;
    return (
      <Wrapper fullWidth={fullWidth}>
        <HiddenRadio type="radio" id={id} name={name} value={value} onChange={onChange} ref={ref} />
        <ButtonLikeLabel htmlFor={id}>{children}</ButtonLikeLabel>
      </Wrapper>
    );
  }
);
