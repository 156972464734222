import React from "react";
import { Grid } from "@mui/material";
import { ExternalUriMap } from "utils/ExternalUriMap";
import { ExternalLink } from "components/ExternalLink/ExternalLink";
import {
  InstallationStep,
  InstallationStepText,
  InstallGuide,
} from "areas/serverLicenses/detail/components/ServerLicenseInstallation/InstallGuide";

export function WindowsInstallGuide() {
  return (
    <InstallGuide>
      <InstallationStep stepNumber={1} label="Download Octopus Server">
        <InstallationStepText>
          Click the link below to download the 64-bit MSI installer for Windows.
        </InstallationStepText>
        <Grid item xs={12}>
          <ExternalLink href={ExternalUriMap.octopusComDownloadsServerPath}>Download Octopus</ExternalLink>
        </Grid>
      </InstallationStep>

      <InstallationStep stepNumber={2} label="Run the setup wizard">
        <InstallationStepText>Copy and paste your license key when prompted.</InstallationStepText>
        <Grid item xs={12}>
          <ExternalLink href={ExternalUriMap.octopusComDocsInstallationPath}>Read the guide</ExternalLink>
        </Grid>
      </InstallationStep>

      <InstallationStep stepNumber={3} label="Your first deployment">
        <InstallationStepText>
          Start using your new Octopus instance by doing your first deployment.
        </InstallationStepText>
        <Grid item xs={12}>
          <ExternalLink href={ExternalUriMap.octopusComDocsFirstDeploymentPath}>Follow the tutorial</ExternalLink>
        </Grid>
      </InstallationStep>
    </InstallGuide>
  );
}
