import React, { useMemo } from "react";
import _ from "lodash";
import useAsyncRequest from "hooks/useAsyncRequest";
import { nameof } from "utils/nameof";
import { LoadablePage } from "shared/LoadablePage";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { getCloudSubscriptions } from "client/api/CloudSubscriptionApi";
import type { ServerLicenseDto } from "client/api/ServerLicenseApi";
import { getServerLicenses } from "client/api/ServerLicenseApi";
import { getSubscriptionGroups } from "client/api/SubscriptionGroupApi";
import type { SubscriptionGroupDto } from "client/api/common/SubscriptionGroupDto";
import { OldControlCenterBanner } from "components/Banner/OldControlCenterBanner";
import { CollapsibleList } from "components/CollapsibleLayout/CollapsibleList";
import { PaperLayout } from "components/PaperLayout/PaperLayout";
import { GroupedSubscriptions } from "areas/dashboard/components/SubscriptionGroups/GroupedSubscriptions";
import { DashboardHeader } from "../components/DashboardHeader";
import { UngroupedSubscriptions } from "./UngroupedSubscriptions";

type GroupedSubscriptions = {
  group: SubscriptionGroupDto;
  cloudSubscriptions: CloudSubscriptionDto[];
  serverLicenses: ServerLicenseDto[];
};

function entitiesWithoutSubscriptionGroup<T extends CloudSubscriptionDto | ServerLicenseDto>(entities: T[]) {
  return entities.filter(({ subscriptionGroup }) => !subscriptionGroup);
}

function groupBySubscriptionGroup<T extends CloudSubscriptionDto | ServerLicenseDto>(entities: T[]) {
  return _(entities)
    .filter(nameof<T>("subscriptionGroup"))
    .groupBy((dto) => dto.subscriptionGroup?.id)
    .value();
}

function groupEntitiesBySubscriptionGroups(
  subscriptionGroups: SubscriptionGroupDto[] | undefined,
  cloudSubscriptions: CloudSubscriptionDto[],
  serverLicenses: ServerLicenseDto[]
) {
  const result: GroupedSubscriptions[] = [];

  if (!subscriptionGroups) {
    return result;
  }

  const groupedCloud = groupBySubscriptionGroup(cloudSubscriptions);
  const groupedServer = groupBySubscriptionGroup(serverLicenses);
  const idsOfGroupsWithSubscriptions = _.union(Object.keys(groupedCloud), Object.keys(groupedServer));

  for (const groupId of idsOfGroupsWithSubscriptions) {
    const group = subscriptionGroups.find((g) => g.id === groupId);
    if (!group) {
      continue;
    }

    result.push({
      group: group,
      cloudSubscriptions: groupedCloud[groupId] ?? [],
      serverLicenses: groupedServer[groupId] ?? [],
    });
  }

  return result;
}

function findEmptyGroups(
  subscriptionGroups: SubscriptionGroupDto[] | undefined,
  cloudSubscriptions: CloudSubscriptionDto[],
  serverLicenses: ServerLicenseDto[]
) {
  if (!subscriptionGroups) {
    return [];
  }

  return subscriptionGroups.filter(
    (group) =>
      cloudSubscriptions.every((entity) => entity.subscriptionGroup?.id !== group.id) &&
      serverLicenses.every((entity) => entity.subscriptionGroup?.id !== group.id)
  );
}

function sortByFriendlyName(subscriptionA: CloudSubscriptionDto, subscriptionB: CloudSubscriptionDto) {
  return subscriptionA.friendlyName.localeCompare(subscriptionB.friendlyName);
}

function sortById(subscriptionA: ServerLicenseDto, subscriptionB: ServerLicenseDto) {
  return subscriptionA.serial.localeCompare(subscriptionB.serial);
}

function sortByName(groupA: SubscriptionGroupDto, groupB: SubscriptionGroupDto) {
  return groupA.name.localeCompare(groupB.name);
}

export function DashboardProducts() {
  const {
    data: cloudSubscriptions = { entities: [], totalCount: undefined },
    loading: loadingCloudSubscriptions,
    error: cloudSubscriptionsError,
    refresh: cloudRefresh,
  } = useAsyncRequest(getCloudSubscriptions, {});
  const {
    data: serverLicenses = { entities: [], totalCount: undefined },
    loading: loadingServerLicenses,
    error: serverLicensesError,
    refresh: serverRefresh,
  } = useAsyncRequest(getServerLicenses, {});
  const {
    data: subscriptionGroups = { entities: [], totalCount: undefined },
    loading: loadingSubscriptionGroups,
    error: subscriptionGroupsError,
    refresh: subscriptionGroupsRefresh,
  } = useAsyncRequest(getSubscriptionGroups);

  const loading = useMemo(
    () => loadingCloudSubscriptions || loadingServerLicenses || loadingSubscriptionGroups,
    [loadingCloudSubscriptions, loadingServerLicenses, loadingSubscriptionGroups]
  );

  const groupedSubscriptions = groupEntitiesBySubscriptionGroups(
    subscriptionGroups.entities,
    cloudSubscriptions.entities,
    serverLicenses.entities
  );
  const emptyGroups = findEmptyGroups(
    subscriptionGroups.entities,
    cloudSubscriptions.entities,
    serverLicenses.entities
  );

  return (
    <LoadablePage loading={loading} error={cloudSubscriptionsError || serverLicensesError || subscriptionGroupsError}>
      <PaperLayout sx={{ marginBottom: 3 }}>
        <DashboardHeader />
        <OldControlCenterBanner sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} />
        <CollapsibleList title={"Subscriptions"} initiallyOpen={true} eyebrowTitle={"Ungrouped"}>
          <UngroupedSubscriptions
            cloudSubscriptions={entitiesWithoutSubscriptionGroup(cloudSubscriptions.entities).sort(sortByFriendlyName)}
            serverSubscriptions={entitiesWithoutSubscriptionGroup(serverLicenses.entities).sort(sortById)}
          />
        </CollapsibleList>
      </PaperLayout>
      {groupedSubscriptions.map(({ group, cloudSubscriptions, serverLicenses }: GroupedSubscriptions) => (
        <PaperLayout sx={{ marginBottom: 3 }} key={group.id}>
          <GroupedSubscriptions
            refresh={subscriptionGroupsRefresh}
            subscriptionGroup={group}
            key={group.id}
            cloudSubscriptions={cloudSubscriptions.sort(sortByFriendlyName)}
            serverSubscriptions={serverLicenses.sort(sortById)}
          />
        </PaperLayout>
      ))}
      {emptyGroups?.sort(sortByName).map((subscriptionGroup: SubscriptionGroupDto) => (
        <PaperLayout sx={{ marginBottom: 3 }} key={subscriptionGroup.id}>
          <GroupedSubscriptions
            key={subscriptionGroup.id}
            subscriptionGroup={subscriptionGroup}
            refresh={subscriptionGroupsRefresh}
            doNotAutoExpand
          />
        </PaperLayout>
      ))}
    </LoadablePage>
  );
}
