import React from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { RoutesMap } from "RoutesMap";
import { DateTime } from "luxon";
import useLocalStorage from "@cpt/hooks/useLocalStorage";
import { IsStripeSubscriptionLicensingChannel } from "utils/CloudSubscriptionLicensingChannelTypeHelpers";
import { getCloudSubscriptionById } from "client/api/CloudSubscriptionApi";
import type { UpgradedStripeProducts } from "components/StripeUpgradeSuccessAlert/StripeUpgradeSuccessAlert";
import { UPGRADED_STRIPE_PRODUCTS_KEY } from "components/StripeUpgradeSuccessAlert/StripeUpgradeSuccessAlert";
import StripeToOctopus from "resources/images/svgs/stripe_to_octopus.svg";
import { PollingPage } from "pages/PollingPage";
import { CloudSubscriptionsRoutesMap } from "areas/cloudSubscriptions/CloudSubscriptionRoutesMap";

export function StripeUpgradeCompletingPage() {
  const [, setUpgradedProducts] = useLocalStorage<UpgradedStripeProducts>(UPGRADED_STRIPE_PRODUCTS_KEY, {});

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const cloudSubscriptionId = searchParams.get("cloudSubscriptionId");
  const expectedPriceCode = searchParams.get("priceCode");

  if (!cloudSubscriptionId) return <Navigate to={RoutesMap.notFound} />;

  const query = async (_: boolean): Promise<boolean> => {
    const response = await getCloudSubscriptionById(cloudSubscriptionId);

    if (!IsStripeSubscriptionLicensingChannel(response?.licensingChannel)) return false;
    if (response.licensingChannel.priceCode !== expectedPriceCode) return false;

    markProductAsUpgraded(response.id, response.licensingChannel.productName);
    navigate(CloudSubscriptionsRoutesMap.detail(response.id).root);
    return true;
  };

  const markProductAsUpgraded = (id: string, productName: string | undefined) => {
    setUpgradedProducts((prev) => {
      return {
        ...prev,
        [id]: { upgradedAt: DateTime.now().toISO(), productName: productName },
      };
    });
  };

  return (
    <PollingPage
      query={query}
      inProgressTitle={"Processing your upgrade"}
      errorTitle={"Your upgrade was processed but we were unable to finalize it at this time."}
      errorMessage={"Our team has been notified and will complete the upgrade on your behalf as soon as possible."}
      svgImage={StripeToOctopus}
      svgImageAlt="Stripe to Octopus Deploy"
    />
  );
}
