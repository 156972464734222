import React, { useState } from "react";
import { Grid, Stack } from "@mui/material";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import { usePolling } from "hooks/usePolling";
import { IsStripeSubscriptionLicensingChannel } from "utils/CloudSubscriptionLicensingChannelTypeHelpers";
import {
  checkCloudSubscriptionFulfilled,
  getCloudSubscriptionById,
  type CloudSubscriptionDto,
} from "client/api/CloudSubscriptionApi";
import { DetailOverviewBanner } from "components/DetailOverviewBanner/DetailOverviewBanner";
import { themeTokens } from "components/Theme/theme";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { CloudSubscriptionsRoutesMap } from "areas/cloudSubscriptions/CloudSubscriptionRoutesMap";
import { CloudSubscriptionHeader } from "areas/cloudSubscriptions/components/CloudSubscriptionHeader";
import { PlanCardByLicensingChannel } from "areas/cloudSubscriptions/detail/components/overview/CloudSubscriptionPlanCard";
import { CloudSubscriptionStatusCard } from "areas/cloudSubscriptions/detail/components/overview/CloudSubscriptionStatusCard";
import { RenewalCard } from "areas/cloudSubscriptions/detail/components/overview/RenewalCard";
import { ResourceUsageCard } from "./components/overview/ResourceUsageCard";

export function CloudSubscriptionOverview() {
  const { entity: cloudSubscription, update } = useEntity<CloudSubscriptionDto>();
  const { hasPermissionForAsset } = useUserContext();
  const [loading, setLoading] = useState<boolean>(!cloudSubscription?.isFulfilled ?? true);

  const checkProvisioned = async () => {
    if (cloudSubscription?.id && !cloudSubscription?.isFulfilled) {
      const check = await checkCloudSubscriptionFulfilled(cloudSubscription?.id);
      if (check) {
        const updatedLicense = await getCloudSubscriptionById(cloudSubscription?.id);
        setLoading(false);
        update(updatedLicense);
      }
    }
  };

  usePolling(checkProvisioned, loading);

  if (!cloudSubscription) return <></>;

  const hasStripeSubscription = IsStripeSubscriptionLicensingChannel(cloudSubscription.licensingChannel);
  const canManageSubscriptionViaStripe =
    IsStripeSubscriptionLicensingChannel(cloudSubscription.licensingChannel) &&
    cloudSubscription.licensingChannel.status != "canceled";
  const isBillingUser = hasPermissionForAsset(
    RbacPermissions.CloudSubscription.ViewBilling,
    cloudSubscription.id,
    cloudSubscription.subscriptionGroup?.id
  );
  const isPastDue =
    IsStripeSubscriptionLicensingChannel(cloudSubscription.licensingChannel) &&
    cloudSubscription?.licensingChannel.status == "past_due";

  const showRenewalCard = isBillingUser && hasStripeSubscription && canManageSubscriptionViaStripe;

  return (
    <>
      <CloudSubscriptionHeader pageName={"Overview"} />
      <Stack spacing={2} sx={{ p: 4, backgroundColor: themeTokens.color.background.secondary.default }}>
        <Grid container spacing={2}>
          <DetailOverviewBanner
            id={cloudSubscription.id}
            freemium={
              IsStripeSubscriptionLicensingChannel(cloudSubscription.licensingChannel)
                ? cloudSubscription.licensingChannel.freemium
                : false
            }
            financePageRoute={CloudSubscriptionsRoutesMap.detail(cloudSubscription.id).finance}
            type="CloudSubscription"
          />
          <Grid item xs={12}>
            <PlanCardByLicensingChannel
              cloudSubscription={cloudSubscription}
              licensingChannel={cloudSubscription.licensingChannel}
            />
          </Grid>
          <Grid
            item
            xs
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CloudSubscriptionStatusCard {...cloudSubscription} />
          </Grid>
          {showRenewalCard && (
            <Grid
              item
              xs
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <RenewalCard
                cloudSubscriptionId={cloudSubscription.id}
                subscriptionGroupId={cloudSubscription.subscriptionGroup?.id}
                isPastDue={isPastDue}
              />
            </Grid>
          )}
          <Grid
            item
            xs
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ResourceUsageCard
              limitInGb={cloudSubscription.storageLimitInGb}
              usageInGb={cloudSubscription.fileUsageInGb}
            />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
