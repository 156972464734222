import type { Dispatch } from "react";
import React, { useMemo, useState } from "react";
import { TableCell, TableRow, TableHead, Table, TableBody } from "@mui/material";
import type { AxiosError } from "axios";
import type {
  BaseUserGrantsApi,
  CloudSubscriptionOrSubscriptionGroupUserGrantDto,
  UserDto,
  UserGrantDto,
} from "client/api/UserGrantApi";
import { ActionDialog } from "components/ActionDialog/ActionDialog";
import { UserGrantRow } from "./UserGrantRow";

export type UserGrantsTableProps<TUserGrant extends UserGrantDto> = {
  grants?: TUserGrant[];
  grantsApi: BaseUserGrantsApi<TUserGrant>;
  assetId: string;
  canDeleteGrant: (userGrant: TUserGrant) => boolean;
  refresh: () => void;
  onDeleteError: Dispatch<AxiosError | undefined>;
};

const getUserNameFormatted = (user?: UserDto): string => {
  if (!user) {
    return "User";
  }

  const { firstName, lastName } = user;
  if (!firstName) {
    return "User";
  }

  return `${firstName} ${lastName ?? ""} (${user.email})`;
};

export function UserGrantsTable<TUserGrant extends CloudSubscriptionOrSubscriptionGroupUserGrantDto>(
  props: UserGrantsTableProps<TUserGrant>
) {
  const { grants, grantsApi, assetId, canDeleteGrant, refresh, onDeleteError } = props;
  const [userGrantToDelete, setUserGrantToDelete] = useState<UserGrantDto | undefined>();
  const onUserGrantDelete = (userGrant: UserGrantDto) => {
    setUserGrantToDelete(userGrant);
  };

  const onUserGrantDeleteConfirm = async () => {
    if (!userGrantToDelete) {
      return;
    }
    onDeleteError(undefined);
    await grantsApi.deleteUserGrant(assetId, userGrantToDelete.id).catch((error) => {
      onDeleteError(error);
    });
    setUserGrantToDelete(undefined);
    refresh();
  };

  const onUserGrantDeleteCancel = async () => {
    setUserGrantToDelete(undefined);
  };

  const sortedUserGrants = useMemo(() => {
    return grants?.sort((a, b) => {
      const buildSortKey = function (userGrant: TUserGrant) {
        return `${userGrant.grantType}${userGrant.user.firstName}${userGrant.user.lastName}${userGrant.user.email}`;
      };
      return buildSortKey(a).localeCompare(buildSortKey(b));
    });
  }, [grants]);

  return (
    <>
      <Table size="small" aria-label="Active Users">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "45%" }}>User</TableCell>
            <TableCell sx={{ width: "45%" }}>Role</TableCell>
            <TableCell sx={{ width: "10%" }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedUserGrants?.map((userGrant) => (
            <UserGrantRow
              showDelete={canDeleteGrant(userGrant)}
              userGrant={userGrant}
              onDeleteClicked={() => onUserGrantDelete(userGrant)}
              key={userGrant.id}
              loading={userGrant.id === userGrantToDelete?.id}
            />
          ))}
        </TableBody>
      </Table>
      <ActionDialog
        title={"Delete user grant"}
        description={`${getUserNameFormatted(
          userGrantToDelete?.user
        )} may no longer be able to access this Octopus Deploy resource.`}
        show={!!userGrantToDelete}
        confirmText="Delete"
        onConfirm={onUserGrantDeleteConfirm}
        onCancel={onUserGrantDeleteCancel}
      />
    </>
  );
}
