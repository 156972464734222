import type { DateTime } from "luxon";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import type { ServerLicenseDto } from "client/api/ServerLicenseApi";
import axiosClient from "../axios-client";

export interface CheckoutDto {
  completed?: DateTime;
  id: string;
  serverLicense?: Pick<ServerLicenseDto, "id"> & Partial<ServerLicenseDto>;
  cloudSubscription?: Pick<CloudSubscriptionDto, "id"> & Partial<CloudSubscriptionDto>;
}

const BASE_URL = "checkouts";

export const getCheckout = async (checkoutId: string): Promise<CheckoutDto> => {
  const response = await axiosClient.get<CheckoutDto>(`${BASE_URL}/${checkoutId}`);
  return response.data;
};
