import type { ReactNode } from "react";
import React from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/material";
import { FontAwesomeSvgIcon } from "@cpt/components";

type FaqItemProps = {
  title: ReactNode;
  children: ReactNode;
};

const StyledSummary = styled("summary")(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
  color: #657079;
  font-size: 1rem;
  padding-left: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 1.5;
  font-weight: ${theme.typography.fontWeightBold};
`
);

const Content = styled("div")`
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 1em;
  color: #657079;
`;

const StyledArrowIcon = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
  width: 35px;
  height: 35px;
  background: #fff;
  border-radius: 100%;
  border: 1px solid #a9bbcb;
  flex-shrink: 0;
`;

const StyledDetails = styled("details")`
  margin-bottom: 8px;
  border-radius: 10px;
  border: 1px solid #e9edf2;
  background: #f5f7fa;
  &[open] .faq-arrow {
    transform: rotate(180deg);
  }
`;

const ArrowIcon = () => (
  <StyledArrowIcon className="faq-arrow">
    <FontAwesomeSvgIcon icon={faChevronDown} sx={{ color: "#A9BBCB", marginTop: "2px", width: "13px" }} />
  </StyledArrowIcon>
);

export const FaqItem = ({ title, children }: FaqItemProps) => {
  return (
    <StyledDetails>
      <StyledSummary>
        {title}
        <ArrowIcon />
      </StyledSummary>
      <Content>{children}</Content>
    </StyledDetails>
  );
};
