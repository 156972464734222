import type { PropsWithChildren } from "react";
import { createContext, useContext, useState } from "react";
import React from "react";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import { noOp } from "utils/noOp";
import type { ContactRequestSource } from "client/api/ContactRequestsApi";
import type { ContactSalesFields } from "components/Dialogs/ContactSalesDialog/ContactSalesDialog";
import { ContactSalesDialog } from "components/Dialogs/ContactSalesDialog/ContactSalesDialog";
import type { ContactSalesReason } from "./ContactSalesReasons";

type ContactSalesDialogState = {
  showDialog: (defaultValues: Partial<ContactSalesFields>) => void;
  open: boolean;
  submittedData?: ContactSalesFields;
};

const ContactSalesDialogContext = createContext<ContactSalesDialogState>({
  showDialog: noOp,
  open: false,
});

export const useContactSalesDialog = (): ContactSalesDialogState => {
  return useContext(ContactSalesDialogContext);
};

type ContactSalesDialogProviderProps = PropsWithChildren<{
  source: ContactRequestSource;
  serial?: string;
  reasons?: ContactSalesReason[];
}>;

export function ContactSalesDialogProvider(props: ContactSalesDialogProviderProps) {
  const { children, source, serial, reasons } = props;
  const { userInfo } = useUserContext();
  const [open, setOpen] = useState<boolean>(false);
  const [submittedData, setSubmittedData] = useState<ContactSalesFields | undefined>();

  const [defaults, setDefaults] = useState<Partial<ContactSalesFields> | undefined>({
    name: userInfo ? `${userInfo.firstName} ${userInfo.lastName}` : undefined,
    email: userInfo?.email,
  });

  const showDialog = (defaultValues: Partial<ContactSalesFields>) => {
    setDefaults({
      ...defaults,
      projectQuantity: undefined,
      ...defaultValues,
    });
    setOpen(true);
  };

  const hideDialog = (data?: ContactSalesFields) => {
    setOpen(false);
    setSubmittedData(data);
  };

  return (
    <ContactSalesDialogContext.Provider value={{ showDialog, open, submittedData }}>
      {children}
      <ContactSalesDialog
        open={open}
        onClose={hideDialog}
        defaults={defaults}
        source={source}
        serial={serial}
        reasons={reasons}
      />
    </ContactSalesDialogContext.Provider>
  );
}
