import React from "react";
import { CloudSubscriptionStatus, IsSubscribedOrLapsed } from "utils/CloudSubscription/CloudSubscriptionStatus";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";

type ActiveCloudSubscriptionOnlyProps = React.PropsWithChildren<{
  subscription?: CloudSubscriptionDto;
}>;

export function ActiveCloudSubscriptionOnly(props: ActiveCloudSubscriptionOnlyProps) {
  const { subscription } = props;
  const isActive = IsSubscribedOrLapsed(subscription?.cloudSubscriptionStatus || CloudSubscriptionStatus.None);

  return <>{isActive ? props.children : <></>}</>;
}
