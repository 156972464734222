// eslint-disable-next-line no-restricted-imports -- this is the one place we want to allow importing themeTokens from @octopusdeploy/design-system-tokens
import { themeTokens as designSystemThemeTokens } from "@octopusdeploy/design-system-tokens";

/** Wrapper of @octopusdeploy/design-system-tokens */
export const themeTokens = Object.assign({}, designSystemThemeTokens, {
  color: {
    ...designSystemThemeTokens.color,
    text: {
      ...designSystemThemeTokens.color.text,
      link: {
        ...designSystemThemeTokens.color.text.link,
        hover: designSystemThemeTokens.color.text.link.pressed,
      },
    },
  },
});
