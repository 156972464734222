import React from "react";
import { Outlet } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import { useMiniProfiler } from "@cpt/hooks/useMiniProfiler";
import { AuthProvider } from "contexts/AuthProvider/AuthProvider";
import { UserProvider } from "contexts/UserProvider/UserProvider";
import { envConfig } from "utils/envConfig";
import { getAccessToken } from "client/api/Authentication";
import { ControlCenterHeader } from "components/PageHeading/ControlCenterHeader";
import { themeTokens } from "components/Theme/theme";

export function AuthenticatedLayout() {
  useMiniProfiler({
    isProductionEnvironment: envConfig.IsProdEnvironment,
    rootUrl: envConfig.ApiUrl,
    accessToken: getAccessToken(),
  });

  return (
    <AuthProvider>
      <UserProvider>
        {!envConfig.IsProdEnvironment && (
          <Box sx={{ backgroundColor: themeTokens.color.callout.background.attention.default, textAlign: "center" }}>
            <Typography variant="subtitle2" color={themeTokens.color.text.primary}>
              {envConfig.Environment}
            </Typography>
          </Box>
        )}
        <Container sx={{ pt: 3, marginBottom: "24px" }}>
          <ControlCenterHeader />
          <Outlet />
        </Container>
      </UserProvider>
    </AuthProvider>
  );
}
