import React from "react";
import type { ReactNode } from "react";
import { Box, Grid, Typography, styled } from "@mui/material";
import { formatMoney } from "utils/formatters/MoneyFormatter";

type PricingCardPriceProps = {
  price: number | undefined;
  currency: string | ReactNode;
  pricePerLabel: string | ReactNode;
};

const VolumePricing = styled(Typography)`
  box-sizing: border-box;
  margin: 0;
  min-height: 2.625rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  line-height: 1.2;
`;

export function PricingCardPrice(props: PricingCardPriceProps) {
  const { price, currency, pricePerLabel } = props;

  if (typeof price === "undefined") {
    return (
      <Grid item xs={12}>
        <Box></Box>
      </Grid>
    );
  }

  if (price < 0) {
    return (
      <Grid item xs={12}>
        <Box>
          <VolumePricing fontWeight="fontWeightRegular" fontSize="1rem">
            Contact sales for volume pricing
          </VolumePricing>
        </Box>
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <Box sx={{ marginTop: "-2px", marginBottom: "4px" }}>
        <Typography component="span" fontWeight="fontWeightBold" fontSize="1.2rem">
          $
        </Typography>
        <Typography component="span" fontWeight="fontWeightBold" fontSize="1.75rem">
          {formatMoney(price, { removeCurrency: true })}
        </Typography>
        <Typography variant="subtitle1" component="span" fontWeight="fontWeightRegular" fontSize="1.25rem">
          &nbsp;{currency}&nbsp;
        </Typography>
        <Typography variant="subtitle1" component="span" fontWeight="fontWeightRegular" fontSize="1.25rem">
          {pricePerLabel}
        </Typography>
      </Box>
    </Grid>
  );
}
