import React, { useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { DateText } from "@cpt/components";
import { IsSubscribedOrLapsed } from "utils/CloudSubscription/CloudSubscriptionStatus";
import { isCloudTrial } from "utils/isTrial";
import type { TrialCloudSubscriptionLicensingChannelDto } from "client/api/CloudSubscriptionApi";
import { GridDivider } from "components/GridDivider/GridDivider";
import { LicenseChannelChip } from "components/LicenseChannelChip/LicenseChannelChip";
import { LinkButton } from "components/LinkButton/LinkButton";
import { OrdersFinanceLine } from "components/OrdersFinanceLine/OrdersFinanceLine";
import { RouterLink } from "components/RouterLink/RouterLink";
import { themeTokens } from "components/Theme/theme";
import { PolicyAuthorized } from "components/authorization/PolicyAuthorized";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { CloudSubscriptionsRoutesMap } from "areas/cloudSubscriptions/CloudSubscriptionRoutesMap";
import type { LicensingChannelViewProps } from "areas/cloudSubscriptions/detail/components/finance/LicensingChannelViewProps";
import {
  CloudSubscriptionCancelPlanDialog,
  CloudSubscriptionCancelPlanDialogText,
} from "./CloudSubscriptionCancelPlanDialog";

export function TrialLicensingChannelView(props: LicensingChannelViewProps<TrialCloudSubscriptionLicensingChannelDto>) {
  const { cloudSubscription, channel } = props;
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);

  const isTrial = isCloudTrial(channel.channelType);

  if (!isTrial) {
    throw new Error("Rendering TrialLicensingChannelView for non-trial subscription.");
  }

  const now = DateTime.now();
  let planStatusPrefix = "Expires";
  if (channel.expiryDate < now) {
    planStatusPrefix = "Expired";
  }

  return (
    <>
      <>
        <Grid item sm={3} sx={{ padding: 3 }}>
          <Typography fontWeight={700}>Current plan</Typography>
        </Grid>
        <Grid item sm={6} sx={{ padding: 3 }}>
          <Typography marginBottom={1} component={"div"}>
            Cloud Trial <LicenseChannelChip channelType={cloudSubscription.licensingChannel.channelType} />
          </Typography>
          <Typography sx={{ color: themeTokens.color.text.secondary }}>
            {planStatusPrefix} <DateText date={channel.expiryDate} />
          </Typography>
        </Grid>
        <Grid item sm={3} sx={{ padding: 3 }}>
          <Stack spacing={1} sx={{ alignItems: "flex-end" }}>
            {cloudSubscription.isFulfilled && (
              <PolicyAuthorized
                permission={RbacPermissions.CloudSubscription.ManageBilling}
                assetId={cloudSubscription?.id}
                subscriptionGroupId={cloudSubscription?.subscriptionGroup?.id}
              >
                <RouterLink to={CloudSubscriptionsRoutesMap.detail(cloudSubscription.id).upgrade}>
                  Upgrade Plan
                </RouterLink>
              </PolicyAuthorized>
            )}
            {cloudSubscription.isFulfilled && IsSubscribedOrLapsed(cloudSubscription.cloudSubscriptionStatus) && (
              <PolicyAuthorized
                permission={RbacPermissions.CloudSubscription.ManageBilling}
                assetId={cloudSubscription?.id}
                subscriptionGroupId={cloudSubscription?.subscriptionGroup?.id}
              >
                <LinkButton onClick={() => setShowCancelDialog(true)}>Cancel trial</LinkButton>
                <CloudSubscriptionCancelPlanDialog
                  open={showCancelDialog}
                  channelType={channel.channelType}
                  onClose={() => setShowCancelDialog(false)}
                  cloudSubscription={cloudSubscription}
                >
                  <CloudSubscriptionCancelPlanDialogText
                    channelType={channel.channelType}
                    endDate={channel.expiryDate}
                  />
                </CloudSubscriptionCancelPlanDialog>
              </PolicyAuthorized>
            )}
          </Stack>
        </Grid>
      </>
      <GridDivider />
      <OrdersFinanceLine cloudSubscription={cloudSubscription} />
    </>
  );
}
