import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";

export type CloudSubscriptionProps = {
  cloudSubscription: CloudSubscriptionDto;
};

export function isCloudSubscriptionProps(props: unknown): props is CloudSubscriptionProps {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return (props as CloudSubscriptionProps).cloudSubscription !== undefined;
}
