import React from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { RoutesMap } from "RoutesMap";
import { DateTime } from "luxon";
import useLocalStorage from "@cpt/hooks/useLocalStorage";
import { getPurchaseIntent } from "client/api/PurchaseIntentApi";
import type { RenewedProducts } from "components/RenewSuccessBanner/RenewSuccessBanner";
import { RENEWED_PRODUCTS_KEY } from "components/RenewSuccessBanner/RenewSuccessBanner";
import StripeToOctopus from "resources/images/svgs/stripe_to_octopus.svg";
import { PollingPage } from "pages/PollingPage";
import { CloudSubscriptionsRoutesMap } from "areas/cloudSubscriptions/CloudSubscriptionRoutesMap";
import { ServerLicensesRoutesMap } from "areas/serverLicenses/ServerLicensesRoutesMap";

export function StripeSetupRenewalCompletingPage() {
  const [, setRenewedProducts] = useLocalStorage<RenewedProducts>(RENEWED_PRODUCTS_KEY, {});

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const intentId = searchParams.get("setupIntentId");

  if (!intentId) return <Navigate to={RoutesMap.notFound} />;

  const query = async (hasSentFirstRequest: boolean): Promise<boolean> => {
    const response = await getPurchaseIntent(intentId, !hasSentFirstRequest);

    if (!!response.cloudSubscriptionId) {
      markProductAsRenewed(response.cloudSubscriptionId);
      navigate(CloudSubscriptionsRoutesMap.detail(response.cloudSubscriptionId).root);
    } else if (!!response.serverLicenseId) {
      markProductAsRenewed(response.serverLicenseId);
      navigate(ServerLicensesRoutesMap.detail(response.serverLicenseId).root);
    } else {
      return false;
    }

    return true;
  };

  const markProductAsRenewed = (id: string) => {
    setRenewedProducts((prev) => {
      return {
        ...prev,
        [id]: DateTime.now().toISO(),
      };
    });
  };

  return (
    <PollingPage
      query={query}
      inProgressTitle={"Setting up auto-renewal"}
      errorTitle={"We were unable to set up auto-renewal"}
      errorMessage={
        "Our team has been notified and will set up auto-renewal on your behalf as soon as possible. You will receive an email confirmation once complete."
      }
      svgImage={StripeToOctopus}
      svgImageAlt="Stripe to Octopus Deploy"
    />
  );
}
