import React from "react";
import { LoadingButton } from "@mui/lab";
import type { LoadingButtonProps } from "@mui/lab/LoadingButton/LoadingButton";

type LoadingSubmitButtonProps = LoadingButtonProps & {
  isLoading?: boolean;
  isDisabled?: boolean;
  disableIcon?: boolean;
  label?: string;
  formName?: string;
};

export function LoadingSubmitButton(props: LoadingSubmitButtonProps) {
  const { isLoading, isDisabled, disableIcon, label, formName, ...rest } = props;
  return (
    <LoadingButton
      {...rest}
      color={rest.color || "primary"}
      type="submit"
      form={formName ? `form_${formName}` : undefined}
      loading={isLoading}
      disabled={isDisabled}
      variant="contained"
    >
      {label || "Submit"}
    </LoadingButton>
  );
}
