import React from "react";
import type { Theme } from "@emotion/react";
import type { SxProps } from "@mui/material";
import { Stack, Typography } from "@mui/material";
import { themeTokens } from "components/Theme/theme";

interface SummaryLineProps {
  label: string;
  value: string | React.ReactElement;
  sx?: SxProps<Theme>;
  valueTestId?: string;
}

export const SummaryLine = (props: SummaryLineProps) => {
  const { label, value, sx, valueTestId } = props;
  return (
    <Stack direction={"row"} justifyContent={"space-between"}>
      <Typography variant={"body1"} sx={sx} color={themeTokens.color.text.primary}>
        {label}
      </Typography>
      <Typography variant={"body1"} sx={sx} color={themeTokens.color.text.primary} data-testid={valueTestId}>
        {value}
      </Typography>
    </Stack>
  );
};
