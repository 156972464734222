const root = "/cloud-subscriptions";

export const CloudSubscriptionsRoutesMap = {
  root: "/",
  detail: (cloudSubscriptionId: string) => ({
    root: `${root}/${cloudSubscriptionId}`,
    finance: `${root}/${cloudSubscriptionId}/finance`,
    upgrade: `${root}/${cloudSubscriptionId}/upgrade`,
    users: `${root}/${cloudSubscriptionId}/users`,
    configuration: `${root}/${cloudSubscriptionId}/configuration`,
  }),
  loadBySerial: (serialId: string) => ({
    root: `${root}/serial/${serialId}`,
  }),
  inviteAccept: (invite: string) => `${root}/invite/accept/${invite}`,
};
