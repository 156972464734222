import React from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { RoutesMap } from "RoutesMap";
import { DateTime } from "luxon";
import useLocalStorage from "@cpt/hooks/useLocalStorage";
import { getCheckout } from "client/api/CheckoutApi";
import { getPurchaseIntent } from "client/api/PurchaseIntentApi";
import type { UpgradedProducts } from "components/UpgradeSuccessBanner/UpgradeSuccessBanner";
import { UPGRADED_PRODUCTS_KEY } from "components/UpgradeSuccessBanner/UpgradeSuccessBanner";
import StripeToOctopus from "resources/images/svgs/stripe_to_octopus.svg";
import { PollingPage } from "pages/PollingPage";
import { CloudSubscriptionsRoutesMap } from "areas/cloudSubscriptions/CloudSubscriptionRoutesMap";
import { ServerLicensesRoutesMap } from "areas/serverLicenses/ServerLicensesRoutesMap";

export function StripePaymentCompletingPage() {
  const [, setUpgradedProducts] = useLocalStorage<UpgradedProducts>(UPGRADED_PRODUCTS_KEY, {});

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const intentId = searchParams.get("purchaseIntentId");
  const checkoutId = searchParams.get("co");

  if (!intentId && !checkoutId) return <Navigate to={RoutesMap.notFound} />;

  const intentQuery = async (hasSentFirstRequest: boolean): Promise<boolean> => {
    if (!intentId) {
      throw new Error("Intent query called with null intent.");
    }
    const response = await getPurchaseIntent(intentId, !hasSentFirstRequest);

    if (!!response.cloudSubscriptionId) {
      markProductAsUpgraded(response.cloudSubscriptionId);
      navigate(CloudSubscriptionsRoutesMap.detail(response.cloudSubscriptionId).root);
    } else if (!!response.serverLicenseId) {
      markProductAsUpgraded(response.serverLicenseId);
      navigate(ServerLicensesRoutesMap.detail(response.serverLicenseId).root);
    } else {
      return false;
    }

    return true;
  };

  const checkoutQuery = async (hasSentFirstRequest: boolean): Promise<boolean> => {
    if (!checkoutId) {
      throw new Error("Checkout query called with null id.");
    }
    const response = await getCheckout(checkoutId);
    if (!response.completed) {
      return false;
    }

    let destination = "";
    if (response.cloudSubscription) {
      destination = CloudSubscriptionsRoutesMap.detail(response.cloudSubscription.id).root;
    } else if (response.serverLicense) {
      destination = ServerLicensesRoutesMap.detail(response.serverLicense.id).root;
    } else {
      throw Error("Checkout did not return a license.");
    }
    navigate(destination);
    return true;
  };

  const markProductAsUpgraded = (id: string) => {
    setUpgradedProducts((prev) => {
      return {
        ...prev,
        [id]: DateTime.now().toISO(),
      };
    });
  };

  return (
    <PollingPage
      query={intentId ? intentQuery : checkoutQuery}
      inProgressTitle={"Processing your payment"}
      errorTitle={"Your payment was processed but we were unable to complete your upgrade."}
      errorMessage={
        "Our team has been notified and will complete the upgrade on your behalf as soon as possible. You will receive an email confirmation once complete."
      }
      svgImage={StripeToOctopus}
      svgImageAlt="Stripe to Octopus Deploy"
    />
  );
}
