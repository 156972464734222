import React from "react";
import type { SxProps, Theme } from "@mui/material";
import { SvgIcon } from "@mui/material";

export function CheckIconSimple({ sx, fillColor = "#fff" }: { sx?: SxProps<Theme>; fillColor?: string }) {
  return (
    <SvgIcon
      viewBox="0 0 17 14"
      xmlns="http://www.w3.org/2000/svg"
      width="17px"
      height="14px"
      sx={{
        width: "17px",
        height: "14px;",
        ...sx,
      }}
      fill="none"
    >
      <path
        d="M5.68904 13.0255L0.245288 7.6721C-0.0817626 7.35048 -0.0817626 6.82901 0.245288 6.50735L1.42966 5.3426C1.75672 5.02095 2.28702 5.02095 2.61407 5.3426L6.28125 8.94887L14.1359 1.22461C14.463 0.902993 14.9933 0.902993 15.3203 1.22461L16.5047 2.38936C16.8318 2.71098 16.8318 3.23246 16.5047 3.55411L6.87345 13.0255C6.54637 13.3471 6.01609 13.3471 5.68904 13.0255Z"
        fill={fillColor}
      />
    </SvgIcon>
  );
}
