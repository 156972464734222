import { DateTime } from "luxon";
import type { DateTimeFormatOptions } from "luxon";

export const defaultDateFormat = "d MMM yyyy";

export const formatDateAsLocaleString = (
  date: string,
  useLocalTime: boolean = false,
  formatOpts: DateTimeFormatOptions = DateTime.DATETIME_SHORT_WITH_SECONDS
): string => {
  const dateTime = DateTime.fromISO(date);

  if (useLocalTime) {
    const localTime = dateTime.toLocal();
    return `${localTime.toLocaleString(formatOpts)} (${localTime.zoneName})`;
  }
  return `${dateTime.toLocaleString(formatOpts)} (${dateTime.zoneName})`;
};

export const formatDateTimeAsLocaleDateString = (
  date: DateTime,
  dateFormat: Intl.DateTimeFormatOptions = DateTime.DATE_SHORT,
  useLocalTime: boolean = false,
  includeZoneName: boolean = true
): string => {
  if (useLocalTime) {
    const localTime = date.toLocal();
    return `${localTime.toLocaleString(dateFormat)} ${includeZoneName ? `(${localTime.zoneName})` : ""}`;
  }
  return `${date.toLocaleString(dateFormat)} ${includeZoneName ? `(${date.zoneName})` : ""}`;
};

export const formatDateAsLocaleTimeString = (date: DateTime, useLocalTime?: boolean): string => {
  if (useLocalTime) {
    const localTime = date.toLocal();
    return `${localTime.toLocaleString(DateTime.TIME_SIMPLE)} (${localTime.zoneName})`;
  }
  return `${date.toLocaleString(DateTime.TIME_SIMPLE)} (${date.zoneName})`;
};
