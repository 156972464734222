import { CloudLicensingChannelTypesArr, CloudLicensingChannelTypesKeys } from "client/api/CloudSubscriptionApi";
import type {
  CloudLicensingChannel,
  CloudLicensingChannelTypes,
  StripeSubscriptionCloudSubscriptionLicensingChannelDto,
  ZuoraCloudSubscriptionLicensingChannelDto,
  TrialCloudSubscriptionLicensingChannelDto,
} from "client/api/CloudSubscriptionApi";
import { UpgradePlan } from "client/api/UpgradePlan";

export function IsZuoraSubscriptionLicensingChannel(
  licensingChannel: CloudLicensingChannel
): licensingChannel is ZuoraCloudSubscriptionLicensingChannelDto {
  return licensingChannel.channelType === "ZuoraCloudSubscriptionLicensingChannelDto";
}

export function IsStripeSubscriptionLicensingChannel(
  licensingChannel: CloudLicensingChannel
): licensingChannel is StripeSubscriptionCloudSubscriptionLicensingChannelDto {
  return licensingChannel.channelType === "StripeSubscriptionCloudSubscriptionLicensingChannelDto";
}

export function isCloudSubscriptionLicenseChannelType(
  licensingChannelType: string
): licensingChannelType is CloudLicensingChannelTypes {
  const arr: string[] = CloudLicensingChannelTypesArr;
  return arr.includes(licensingChannelType);
}

export function isTrialLicensingChannel(
  licensingChannel: CloudLicensingChannel
): licensingChannel is TrialCloudSubscriptionLicensingChannelDto {
  return licensingChannel.channelType === CloudLicensingChannelTypesKeys.Trial;
}

export function getUpgradePlanForPriceCode(priceCode: string): UpgradePlan | undefined {
  return enumFromStringValue(UpgradePlan, priceCode);
}

function enumFromStringValue<T>(enm: { [s: string]: T }, value: string): T | undefined {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return (Object.values(enm) as unknown as string[]).includes(value) ? (value as unknown as T) : undefined;
}
