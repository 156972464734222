export const formatCloudRegionForDisplay = (cloudRegion: string) => {
  switch (cloudRegion) {
    case "AustraliaEast":
      return "Australia East";
    case "WestEurope":
      return "West Europe";
    case "WestUS2":
      return "West US 2";
    default:
      return cloudRegion;
  }
};
