import React from "react";
import type { AlertColor } from "@mui/material";
import { DateTime } from "luxon";
import { formatDateTimeAsLocaleDateString } from "utils/formatters/DateFormatters";
import { useContactSalesDialog } from "components/Dialogs/ContactSalesDialog/ContactSalesDialogProvider";
import { LinkButton } from "components/LinkButton/LinkButton";
import { AlertWithShadow } from "components/alert/AlertWithShadow";

type ServerLicenseExpiryBannerProps = {
  expiryDate: string | DateTime;
};

export function ServerLicenseExpiryBanner(props: ServerLicenseExpiryBannerProps) {
  const { showDialog } = useContactSalesDialog();

  const { expiryDate } = props;

  const licenseExpiryDate = expiryDate instanceof DateTime ? expiryDate : DateTime.fromISO(expiryDate);
  const daysToExpiry = licenseExpiryDate.diffNow().as("days");
  const formattedExpiryDate = formatDateTimeAsLocaleDateString(licenseExpiryDate, DateTime.DATE_MED, true, false);

  const expiresSoon = daysToExpiry > 2 && daysToExpiry <= 7;
  const expiresImmediately = daysToExpiry <= 2 && daysToExpiry >= 0;
  const hasExpired = daysToExpiry < 0;
  const notExpiring = !expiresSoon && !expiresImmediately && !hasExpired;
  const showContactSalesDialog = () => showDialog({ reason: "server-license-renewal" });

  if (notExpiring) {
    return null;
  }

  let bannerLevel: AlertColor = "warning";
  let bannerText = null;

  if (expiresSoon) {
    bannerText = (
      <>
        This server license will expire on {formattedExpiryDate}, to keep using Octopus Deploy server{" "}
        <LinkButton onClick={showContactSalesDialog}>please contact our sales team to renew.</LinkButton>
      </>
    );
  }

  if (expiresImmediately) {
    bannerLevel = "error";
    bannerText = (
      <>
        This server license is close to expiring on {formattedExpiryDate}, to keep using Octopus Deploy{" "}
        <LinkButton onClick={showContactSalesDialog}>please contact our sales team to renew.</LinkButton>
      </>
    );
  }

  if (hasExpired) {
    bannerText = (
      <>
        This server license expired on {formattedExpiryDate}, to use this license again{" "}
        <LinkButton onClick={showContactSalesDialog}>please contact our sales team to renew.</LinkButton>
      </>
    );
  }

  return (
    <AlertWithShadow severity={bannerLevel} sx={{ padding: 2, marginBottom: 1 }}>
      {bannerText}
    </AlertWithShadow>
  );
}
