import React from "react";
import PaymentsIcon from "@mui/icons-material/Payments";
import { Box, Card, Typography } from "@mui/material";
import { useContactSalesDialog } from "components/Dialogs/ContactSalesDialog/ContactSalesDialogProvider";
import { LinkButton } from "components/LinkButton/LinkButton";
import { StripeCustomerPortalLinkButton } from "components/StripeCustomerPortalLink/StripeCustomerPortalLinkButton";
import { themeTokens } from "components/Theme/theme";
import { IconAndErrorText, IconAndText } from "areas/cloudSubscriptions/detail/components/overview/IconAndText";

export type RenewalCardProps = {
  cloudSubscriptionId: string;
  subscriptionGroupId?: string;
  isPastDue?: boolean;
};

export function RenewalCard(props: RenewalCardProps) {
  const { cloudSubscriptionId, subscriptionGroupId, isPastDue } = props;
  const { showDialog } = useContactSalesDialog();

  const iconAndText = isPastDue ? (
    <IconAndErrorText
      icon={<PaymentsIcon />}
      primary={"Payment overdue"}
      secondary={
        <StripeCustomerPortalLinkButton
          cloudSubscriptionId={cloudSubscriptionId}
          subscriptionGroupId={subscriptionGroupId}
          label="Pay via Stripe"
          variant="contained"
          size="small"
          color="success"
          sx={{ marginTop: 0.25 }}
        />
      }
    />
  ) : (
    <IconAndText
      icon={<PaymentsIcon />}
      primary={""}
      secondary={<LinkButton onClick={() => showDialog({ reason: "other" })}>Manage subscription</LinkButton>}
    />
  );

  return (
    <Card sx={{ padding: 2, flexGrow: 1, display: "flex", flexDirection: "column" }}>
      <Typography sx={{ color: themeTokens.color.text.secondary }}>Renewal</Typography>
      <Box display="flex" flexDirection="column" justifyContent="center" flexGrow={1}>
        {iconAndText}
      </Box>
    </Card>
  );
}
