import { useEffect, useMemo, useRef, useState } from "react";
import { getDefaultDelays } from "pages/utils/get-default-delays";

export type PollingCallback = () => Promise<void>;

export function usePolling(callback: PollingCallback, enabled: boolean) {
  const pollTimerRef = useRef(0);
  const defaultDelays = useMemo(() => getDefaultDelays(), []);
  const [warmedUp, setWarmedUp] = useState<boolean>(false);

  // Warm up - delay the initial poll so the user doesn't see a flash of screen
  useEffect(() => {
    const startPoll = () => {
      setWarmedUp(true);
    };
    const t = window.setTimeout(startPoll, defaultDelays.initial);
    return () => window.clearTimeout(t);
  }, [defaultDelays]);

  useEffect(() => {
    if (!warmedUp || !enabled) {
      return;
    }
    const asyncCallback = async () => {
      try {
        await callback();
      } finally {
        clearTimeout(pollTimerRef.current);
        if (enabled) {
          pollTimerRef.current = window.setTimeout(asyncCallback, defaultDelays.subsequent);
        }
      }
    };
    asyncCallback();

    return () => {
      clearTimeout(pollTimerRef.current);
    };
  }, [enabled, defaultDelays, callback, warmedUp]);
}
