import React from "react";
import { Typography } from "@mui/material";
import { DateTime } from "luxon";
import { designSystemColors } from "@cpt/shared";
import { defaultDateFormat } from "utils/formatters/DateFormatters";
import { ServerLicensingChannelTypesKeys, type ServerLicenseDto } from "client/api/ServerLicenseApi";

interface ServerLicenseNextDateProps {
  serverLicense: ServerLicenseDto;
}

export function ServerLicenseNextDate(props: ServerLicenseNextDateProps) {
  const { serverLicense } = props;

  const now = DateTime.now();

  let prefix = "Expires";
  if (serverLicense.expiryDate < now) {
    prefix = "Expired";
  }
  if (serverLicense.licensingChannel.channelType === ServerLicensingChannelTypesKeys.Stripe) {
    if (serverLicense.licensingChannel.status === "canceled") {
      prefix = "Cancelled";
    } else if (serverLicense.licensingChannel.cancelAtPeriodEnd) {
      prefix = "Cancels";
    }
  } else if (serverLicense.licensingChannel.channelType === ServerLicensingChannelTypesKeys.Zuora) {
    // TODO: handle cancelled state when there is one for zuora
    if (serverLicense.licensingChannel.cancelAtPeriodEnd) {
      prefix = "Cancels";
    }
  }

  const displayText = serverLicense.expiryDate
    ? `${prefix} ${serverLicense.expiryDate.toFormat(defaultDateFormat)}`
    : null;
  return (
    <Typography color={designSystemColors.grey["600"]} sx={{ fontSize: "0.8125rem" }}>
      {displayText}
    </Typography>
  );
}
