import React from "react";
import type { PropsWithChildren } from "react";
import { Box } from "@mui/material";

export function TrialFulfillmentContent(props: PropsWithChildren) {
  return (
    <Box maxWidth={0.5} minWidth={450}>
      {props.children}
    </Box>
  );
}
