import React from "react";
import type { ReactNode } from "react";
import type { SxProps, Theme } from "@mui/material";
import { Box, Grid, Stack } from "@mui/material";

export interface CheckoutStepLayoutProps {
  children: ReactNode;
  orderSummary: React.ReactElement;
  sx?: SxProps<Theme>;
  testId?: string;
}

export function CheckoutStepLayout(props: CheckoutStepLayoutProps) {
  const { children, orderSummary, sx, testId } = props;

  return (
    <Grid container sx={sx} data-testid={testId}>
      <Grid item xs={12} md={8} display={"flex"} sx={{ borderRight: "1px solid #E6E8EA" }}>
        <Box padding={3} display={"flex"} width={"100%"} minHeight={"600px"}>
          <Stack direction="column" width="100%">
            {children}
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        {orderSummary}
      </Grid>
    </Grid>
  );
}
