import React from "react";
import { Link, Typography } from "@mui/material";
import { ExternalUriMap } from "utils/ExternalUriMap";
import { FaqBulletedList } from "./FaqBulletedList";
import { FaqItem } from "./FaqItem";

export const FaqWhatIsAProject = () => {
  return (
    <FaqItem title={"What is a project?"}>
      <>
        <Typography paragraph gutterBottom>
          A project is a resource you configure in Octopus Deploy that primarily represents the deployment configuration
          for an application. An application is a piece of software that is independently deployable.
        </Typography>
        <Typography paragraph gutterBottom>
          Some examples of an application are (but not limited to):
        </Typography>
        <FaqBulletedList>
          <li>A monolithic application with dozens of components.</li>
          <li>A back-end REST API with a database as the data store.</li>
          <li>A serverless function that monitors a queue.</li>
          <li>A back-end service that runs in a container on a Kubernetes or ECS cluster.</li>
          <li>A scheduling service that uses CRON jobs to schedule tasks for other applications.</li>
        </FaqBulletedList>
        <Typography paragraph gutterBottom>
          A project is primarily a deployment configuration for an application. However, there are other use cases for
          projects. Some examples of projects that are not deployment configurations for an application are (but not
          limited to):
        </Typography>
        <FaqBulletedList>
          <li>Runbook-only projects that store a shared set of runbooks for common day 2 maintenance tasks.</li>
          <li>
            Orchestrator/traffic cop projects used to orchestrate the deployments of other Octopus Deploy projects.
          </li>
        </FaqBulletedList>
        <Typography paragraph gutterBottom>
          Octopus Deploy will count all active projects, regardless of whether they deploy an application, are used for
          runbook only, or are used to orchestrate the deployments of other Octopus Deploy projects.
        </Typography>
      </>
    </FaqItem>
  );
};

export const FaqWhatIsATenant = () => {
  return (
    <FaqItem title={"What is a tenant?"}>
      <Typography paragraph gutterBottom>
        A tenant is a resource you configure in Octopus Deploy that is meant to represent the following:
      </Typography>
      <FaqBulletedList>
        <li>One of your SaaS customers.</li>
        <li>
          A physical location includes a retail store, branch office, hospital, restaurant, factory, city, state,
          country, data center, or car wash.
        </li>
        <li>One of your customers' physical locations (you deploy software to your customers' restaurants).</li>
      </FaqBulletedList>
      <Typography paragraph gutterBottom>
        Each tenant runs a dedicated copy of your application(s) with slightly different configurations. Octopus Deploy
        will count all tenants, regardless of what they represent (customer, physical location, etc.) and the
        environments they deploy to.
      </Typography>
    </FaqItem>
  );
};

export const FaqWhatIsAMachine = () => {
  return (
    <FaqItem title={"What is a machine?"}>
      <Typography paragraph gutterBottom>
        A machine is a Windows, Linux, or MacOS application host - or Deployment Target - registered with Octopus
        Deploy. See our{" "}
        <Link target="_blank" rel="noopener" href={ExternalUriMap.octopusComPricingFaqMachinesPath}>
          FAQ
        </Link>{" "}
        for more details.
      </Typography>
    </FaqItem>
  );
};
