import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import { Box, CircularProgress, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import type {
  CloudSubscriptionOrSubscriptionGroupUserGrantDto,
  UserGrantDto,
  UserGrantInviteDto,
} from "client/api/UserGrantApi";
import { themeTokens } from "components/Theme/theme";

export type UserGrantRowProps<TUserGrant extends UserGrantDto> = {
  userGrantInvite: UserGrantInviteDto;
  showDelete: boolean;
  onDeleteClicked: () => void | Promise<void>;
  loading?: boolean;
};

export function UserGrantInviteRow<TUserGrant extends CloudSubscriptionOrSubscriptionGroupUserGrantDto>(
  props: UserGrantRowProps<TUserGrant>
) {
  const { userGrantInvite, showDelete, onDeleteClicked, loading } = props;

  return (
    <TableRow>
      <TableCell>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <PersonIcon sx={{ color: themeTokens.color.text.secondary, fontSize: 30, marginRight: "0.5rem" }} />
          <Typography variant="body1" sx={{ ml: 1, fontWeight: "bold" }}>
            {userGrantInvite.email}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box>{userGrantInvite.role.name}</Box>
      </TableCell>
      <TableCell align={"right"}>
        {showDelete && <DeleteUser onDeleteClicked={onDeleteClicked} loading={loading} />}
      </TableCell>
    </TableRow>
  );
}

type DeleteUserProps = {
  onDeleteClicked: () => void | Promise<void>;
  loading?: boolean;
};

function DeleteUser({ onDeleteClicked, loading }: DeleteUserProps) {
  return loading ? (
    <CircularProgress size={18} />
  ) : (
    <IconButton onClick={onDeleteClicked} aria-label="revoke-invite">
      <DeleteIcon />
    </IconButton>
  );
}
