import type { DateTime } from "luxon";
import type { DistributiveOmit } from "utils/DistributiveOmit/DistributiveOmit";
import axiosClient from "../axios-client";

const BASE_URL = "purchase";

export type ZuoraRsaSignature = {
  key: string;
  signature: string;
  success: boolean;
  tenantId: string;
  token: string;
};

export enum ProductType {
  StarterServer,
  StarterCloud,
}

export enum TaxRateType {
  Percentage = "Percentage",
  FlatFee = "FlatFee",
}

export interface OrderPreviewRequest {
  product: ProductType;
  city?: string;
  country?: string;
  county?: string;
  postalCode?: string;
  state?: string;
}

export interface OrderPreview {
  termStartDate: DateTime;
  termEndDate: DateTime;

  // Amount including any discounts
  totalAmountExcludingTax: number;

  // Amount including any discounts and tax
  totalAmount: number;

  discountAmount: number;
  chargeDescription: string;
  chargeName: string;
  productName: string;
  currencyCode: string;
  taxItems: OrderPreviewTaxItem[];
}

export interface OrderPreviewTaxItem {
  amount: number;
  rate: number;
  name: string;
  rateType: TaxRateType;
}

export interface PurchasePreviewRequestDto {
  billingProduct: ProductType;
  city?: string;
  country?: string;
  county?: string;
  postalCode?: string;
  state?: string;
}

interface PurchasePreviewResponseTaxItemDto {
  taxAmount: number;
  taxRate: number;
  taxName: string;
  taxRateType: TaxRateType;
}

interface OrderPreviewDto {
  serviceStartDate: DateTime;
  serviceEndDate: DateTime;
  totalAmount: number;
  amountWithoutTax: number;
  chargeDescription: string;
  chargeName: string;
  productName: string;
  currencyCode: string;
  taxItems: PurchasePreviewResponseTaxItemDto[];
}

export interface PurchasePreviewResponseDto {
  orderPreview: OrderPreviewDto;
  error: "Unknown" | "BadRequest" | null;
}

type BillingContactAddress = {
  address1: string;
  country: string;
  city: string;
  state: string;
  postalCode: string;
};

type BillingContact = BillingContactAddress & {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
};

export type AdvancedDigitalSignature = {
  tenantId: string;
  token: string;
  timestamp: string;
  pageId: string;
  refId: string;
  signature: string;
  errorCode?: string;
  fieldPassthrough1?: string;
  fieldPassthrough2?: string;
  fieldPassthrough3?: string;
  fieldPassthrough4?: string;
  fieldPassthrough5?: string;
};

type CheckoutRequest = {
  serial: string;
  billingProduct: ProductType;
  billToContact: BillingContact;
  advancedDigitalSignature: AdvancedDigitalSignature;
} & (
  | {
      businessAccount: true;
      companyName: string;
      taxId?: string;
    }
  | {
      businessAccount: false;
    }
);

interface CheckoutResponseDto {
  success: boolean;
}

type OrderThroughSalesRequest = DistributiveOmit<CheckoutRequest, "advancedDigitalSignature"> &
  OrderThroughSalesAdditionalFields;

type OrderThroughSalesAdditionalFields = {
  reason: string;
};

type OrderThroughSalesResponseDto = {
  success: boolean;
};

export type GeolocationResponseDto =
  | {
      success: true;
      isoCode: string;
    }
  | {
      success: false;
      errorMessage: string;
    };

export const getOrderPreview = async (request: OrderPreviewRequest, signal: AbortSignal): Promise<OrderPreview> => {
  function mapToDto(source: OrderPreviewRequest): PurchasePreviewRequestDto {
    return {
      city: source.city,
      billingProduct: source.product,
      country: source.country,
      county: source.county,
      postalCode: source.postalCode,
      state: source.state,
    };
  }

  function mapToOrderPreview(source: PurchasePreviewResponseDto): OrderPreview {
    return {
      chargeDescription: source.orderPreview.chargeDescription,
      totalAmountExcludingTax: source.orderPreview.amountWithoutTax,
      discountAmount: 0,
      currencyCode: source.orderPreview.currencyCode,
      chargeName: source.orderPreview.chargeName,
      productName: source.orderPreview.productName,
      termStartDate: source.orderPreview.serviceStartDate,
      termEndDate: source.orderPreview.serviceEndDate,
      totalAmount: source.orderPreview.totalAmount,
      taxItems: source.orderPreview.taxItems.map((i) => ({
        name: i.taxName,
        rate: i.taxRate,
        amount: i.taxAmount,
        rateType: i.taxRateType,
      })),
    };
  }

  const response = await axiosClient.post<PurchasePreviewResponseDto>(`${BASE_URL}/preview`, mapToDto(request), {
    signal,
  });
  return mapToOrderPreview(response.data);
};

export const checkout = async (request: CheckoutRequest, signal?: AbortSignal) => {
  const response = await axiosClient.post<CheckoutResponseDto>(`${BASE_URL}/checkout`, request, {
    signal,
  });
  return response.data;
};

export const getZuoraRsaSignature = async (signal?: AbortSignal) => {
  const response = await axiosClient.get<ZuoraRsaSignature>(`${BASE_URL}/rsa-signature`, {
    signal,
  });
  return response.data;
};

export const orderThroughSales = async (request: OrderThroughSalesRequest, signal?: AbortSignal) => {
  const response = await axiosClient.post<OrderThroughSalesResponseDto>(`${BASE_URL}/contact-sales`, request, {
    signal,
  });
  return response.data;
};

export const getGeolocation = async (ipAddress?: string, signal?: AbortSignal) => {
  const requestParams = ipAddress ? `?ipAddress=${ipAddress}` : "";
  const response = await axiosClient.get<GeolocationResponseDto>(`${BASE_URL}/geolocation${requestParams}`, {
    signal,
  });
  return response.data;
};
