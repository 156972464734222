import "config/LuxonConfig";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { GlobalStyles } from "@mui/material";
import { ConfigurationProvider } from "contexts/ConfigurationProvider/ConfigurationProvider";
import { envConfig } from "utils/envConfig";
import { logErrorWithException } from "client/api/LoggingApi";
import { AlertProvider } from "components/AlertProvider/AlertProvider";
import { GlobalThemeApplier } from "components/Theme/GlobalThemeApplier";

interface AppProps {
  routes: React.ReactElement;
}

if (
  envConfig.AppInsightsConnectionString &&
  envConfig.AppInsightsConnectionString != "NO_APP_INSIGHTS_CONNECTION_STRING_DEFINED"
) {
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: envConfig.AppInsightsConnectionString,
      enableAutoRouteTracking: true,
      enableCorsCorrelation: true,
      extensions: [reactPlugin],
    },
  });

  appInsights.loadAppInsights();
}

export function App({ routes }: AppProps) {
  useEffect(() => {
    window.onerror = (message, source, lineno, colno, error) => {
      logErrorWithException(`Unhandled error: ${message}`, JSON.stringify({ source, lineno, colno, error }));
    };

    window.onunhandledrejection = (event) => {
      logErrorWithException(`Unhandled rejection: ${event.reason.message.toString()}`, JSON.stringify(event));
    };

    return () => {
      // Clean up error handlers
      window.onerror = null;
      window.onunhandledrejection = null;
    };
  }, []);

  // TODO: When we've implemented darkmode, pass the palette to GlobalThemeApplier's themeName prop
  // const palette = useThemePaletteType();

  // TODO: remove ThemeFromLocalStorage when we have a proper theme switcher
  return (
    <div>
      {/*<ThemeFromLocalStorage>*/}
      <GlobalStyles
        styles={`
        @keyframes fadeInFromLeft {
          from {
            opacity: 0;
            transform: translate(-6px);
          }
          to {
            opacity: 1;
            transform: translate(0px);
          }
        }
        .animate-fade-in-from-left {
          animation: fadeInFromLeft .4s;
        }
      `}
      />
      {/* Temporarily force light mode on, as we not yet implemented dark mode */}
      <GlobalThemeApplier themeName={"light"} />
      <ConfigurationProvider>
        <AlertProvider>
          <BrowserRouter>{routes}</BrowserRouter>
        </AlertProvider>
      </ConfigurationProvider>
      {/*</ThemeFromLocalStorage>*/}
    </div>
  );
}
