import React from "react";
import type { ReactElement } from "react";
import { Avatar, ListItem, ListItemText } from "@mui/material";
import { themeTokens } from "components/Theme/theme";

export type IconAndTextProps = {
  icon: ReactElement;
  primary: string | ReactElement;
  secondary?: string | ReactElement;
};

export function IconAndText(props: IconAndTextProps) {
  const { icon, primary, secondary } = props;

  return (
    <ListItem>
      <Avatar
        sx={{
          backgroundColor: (theme) => theme.palette.grey[200],
          width: 56,
          height: 56,
          marginRight: 2,
          color: themeTokens.color.text.secondary,
        }}
      >
        {icon}
      </Avatar>
      <ListItemText
        primary={primary}
        primaryTypographyProps={{
          fontWeight: 700,
          fontSize: "1.25rem",
          color: themeTokens.color.text.primary,
        }}
        secondary={secondary}
        secondaryTypographyProps={{ fontSize: "1rem", color: themeTokens.color.text.secondary }}
      />
    </ListItem>
  );
}

export function IconAndErrorText(props: IconAndTextProps) {
  const { icon, primary, secondary } = props;

  return (
    <ListItem>
      <Avatar
        sx={{
          backgroundColor: (theme) => theme.palette.grey[200],
          width: 56,
          height: 56,
          marginRight: 2,
          color: themeTokens.color.text.secondary,
        }}
      >
        {icon}
      </Avatar>
      <ListItemText
        primary={primary}
        primaryTypographyProps={{ fontWeight: 700, fontSize: "1.25rem", color: "#AA3030" }}
        secondary={secondary}
        secondaryTypographyProps={{ fontSize: "1rem", color: themeTokens.color.text.secondary }}
      />
    </ListItem>
  );
}
