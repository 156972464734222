import React from "react";
import { Typography, styled } from "@mui/material";

export type ZuoraServerSideError = {
  key: string;
  code?: string;
  field?: string;
  message: string;
};

const ServerSideErrorMessage = styled(Typography)`
  margin: 1em 0;
  color: #c95340;
  line-height: 1.4;
`;

export const CheckoutZuoraServerSideErrors = ({ errors }: { errors: ZuoraServerSideError[] | undefined }) => {
  const errorItems =
    errors &&
    errors.map((error) => {
      return <ServerSideErrorMessage key={error.key}>{error.message}</ServerSideErrorMessage>;
    });
  return <>{errorItems}</>;
};
