import React from "react";
import { WarningChip } from "components/WarningChip/WarningChip";
import type { AlertLevel } from "./getAlertLevel";

type FileUsageWarningChipProps = {
  alertLevel: AlertLevel;
};

export function FileUsageWarningChip({ alertLevel }: FileUsageWarningChipProps) {
  if (alertLevel === "none") return null;
  const label = alertLevel === "warning" ? "Near limit" : "Action required";

  return (
    <WarningChip label={label} type={alertLevel === "warning" ? "warning" : "error"} role={"file-usage-warning"} />
  );
}
