import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  debounce,
  type AutocompleteChangeDetails,
  type AutocompleteChangeReason,
  type AutocompleteInputChangeReason,
  type InputBaseComponentProps,
} from "@mui/material";
import { searchAddress } from "client/api/AddressSearchApi";
import type { AutocompleteAddressValue } from "./CheckoutAddressField";
import type { AutocompleteOption } from "./CheckoutAutocompleteInput";
import { CheckoutAutocompleteInput } from "./CheckoutAutocompleteInput";

export interface StreetAddressAutocompleteOption {
  label: string;
  value: AutocompleteAddressValue;
}

type OnChange = (
  event: React.SyntheticEvent<Element, Event>,
  value: unknown | StreetAddressAutocompleteOption,
  reason: AutocompleteChangeReason,
  details?: AutocompleteChangeDetails<unknown> | undefined
) => void;

type OnInputChange = (
  event: React.SyntheticEvent<Element, Event>,
  value: string,
  reason: AutocompleteInputChangeReason
) => void;

interface CheckoutStreetAddressInputProps {
  id?: string;
  defaultOptions?: StreetAddressAutocompleteOption[];
  error?: boolean;
  required?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  value?: StreetAddressAutocompleteOption | string | null;
  onChange?: OnChange;
  onInputChange?: OnInputChange;
  freeSolo?: boolean;
  countryCode?: string;
  loading?: boolean;
  InputProps?: InputBaseComponentProps;
}

const getOptions = async (signal: AbortSignal, inputValue: string, countryCode?: string) => {
  const addresses = await searchAddress(signal, inputValue, countryCode);
  const addressOptions = addresses.map((item) => {
    return { label: item.freeFormAddress, value: item };
  });
  return addressOptions;
};

const debouncedGetOptions = debounce(
  async (
    { signal, value, countryCode }: { signal: AbortSignal; value: string; countryCode?: string },
    success: (options: StreetAddressAutocompleteOption[]) => void,
    fail: (e: Error) => void
  ) => {
    try {
      const addressOptions = await getOptions(signal, value, countryCode);
      success(addressOptions);
    } catch (e) {
      if (e instanceof Error) {
        if (e.name === "CanceledError") {
          // request was cancelled, ignore it
          return;
        }
        fail(e);
      }
    }
  },
  400
);

export function CheckoutStreetAddressInput(props: CheckoutStreetAddressInputProps) {
  const {
    id,
    error,
    required,
    placeholder,
    fullWidth = true,
    defaultOptions,
    value,
    onChange,
    onInputChange,
    freeSolo = true,
    countryCode,
    InputProps,
  } = props;
  const [options, setOptions] = useState(defaultOptions);
  const [loading, setLoading] = useState(props.loading ?? false);
  const controllerRef = useRef<AbortController | null>(null);
  const lastSearchValueRef = useRef<string | undefined>(undefined);
  const countryCodeRef = useRef<string | undefined>(countryCode);

  const updateOptions = useCallback((value: string, countryCode: string | undefined) => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;
    const signal = controllerRef.current.signal;

    lastSearchValueRef.current = value;
    countryCodeRef.current = countryCode;
    setLoading(true);
    debouncedGetOptions(
      { signal, value, countryCode },
      (addressOptions) => {
        setOptions(addressOptions);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  const onInputChangeHandler: OnInputChange = async (event, value, reason) => {
    updateOptions(value, countryCode);
    onInputChange && onInputChange(event, value, reason);
  };

  const getOptionKey = (option: unknown) => {
    if (
      typeof option === "object" &&
      option !== null &&
      "value" in option &&
      typeof option.value === "object" &&
      option.value !== null &&
      "label" in option
    ) {
      if ("countryCode" in option.value) {
        return `${option.value.countryCode}-${option.label}`;
      }
      return `${option.label}`;
    }
    return "";
  };

  useEffect(() => {
    // refetch options when countryCode is changed
    if (countryCode !== countryCodeRef.current && lastSearchValueRef.current) {
      updateOptions(lastSearchValueRef.current, countryCode);
    }

    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [countryCode, updateOptions]);

  return (
    <CheckoutAutocompleteInput
      id={id}
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      options={options as AutocompleteOption[]}
      error={error}
      required={required}
      placeholder={placeholder}
      fullWidth={fullWidth}
      value={value}
      onChange={onChange}
      onInputChange={onInputChangeHandler}
      filterOptions={(x) => x}
      freeSolo={freeSolo}
      loading={loading}
      getOptionKey={getOptionKey}
      InputProps={InputProps}
    />
  );
}
