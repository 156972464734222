import React from "react";
import { Link as RouterLink } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloudIcon from "@mui/icons-material/Cloud";
import { Card, Grid, Typography } from "@mui/material";
import type { DateTime } from "luxon";
import { DateText } from "@cpt/components";
import { IsZuoraSubscriptionLicensingChannel } from "utils/CloudSubscriptionLicensingChannelTypeHelpers";
import { isCloudTrial } from "utils/isTrial";
import type {
  CloudSubscriptionDto,
  CloudLicensingChannel,
  CloudLicensingChannelTypes,
} from "client/api/CloudSubscriptionApi";
import { CloudLicensingChannelTypesKeys } from "client/api/CloudSubscriptionApi";
import { GreenButton } from "components/Button/GreenButton";
import { FulfillmentProgressChip } from "components/Dashboard/FufillmentProgressChip";
import { LicenseChannelChip } from "components/LicenseChannelChip/LicenseChannelChip";
import { themeTokens } from "components/Theme/theme";
import { PolicyAuthorized } from "components/authorization/PolicyAuthorized";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { CloudSubscriptionsRoutesMap } from "areas/cloudSubscriptions/CloudSubscriptionRoutesMap";
import { IconAndText } from "areas/cloudSubscriptions/detail/components/overview/IconAndText";

function PlanCard(props: {
  productName: string;
  expiryDate: DateTime;
  expiryDateLabel: string;
  channelType: CloudLicensingChannelTypes;
  cloudSubscription: CloudSubscriptionDto;
}) {
  const { productName, expiryDate, expiryDateLabel, channelType, cloudSubscription } = props;
  const isTrial = isCloudTrial(channelType) && cloudSubscription.isFulfilled;
  const isZuora = IsZuoraSubscriptionLicensingChannel(cloudSubscription.licensingChannel);

  return (
    <Card sx={{ padding: 2 }}>
      <Typography sx={{ color: themeTokens.color.text.secondary }}>
        {isZuora ? "Platform and licensing" : "Plan"}
      </Typography>
      <Grid container>
        <Grid item xs={6}>
          <IconAndText
            icon={<CloudIcon />}
            primary={
              <>
                {productName} {isTrial && <LicenseChannelChip channelType={channelType} />}
              </>
            }
            secondary={<>{!cloudSubscription.isFulfilled && <FulfillmentProgressChip />}</>}
          />
          {isTrial && (
            <PolicyAuthorized
              permission={RbacPermissions.CloudSubscription.ViewBilling}
              assetId={cloudSubscription.id}
              subscriptionGroupId={cloudSubscription.subscriptionGroup?.id}
            >
              <GreenButton
                sx={{ marginLeft: "88px" }}
                variant="contained"
                disableElevation
                component={RouterLink}
                to={CloudSubscriptionsRoutesMap.detail(cloudSubscription.id).upgrade}
              >
                Upgrade Plan
              </GreenButton>
            </PolicyAuthorized>
          )}
        </Grid>
        <Grid item xs={6}>
          <IconAndText
            icon={<CalendarMonthIcon />}
            primary={
              <DateText date={expiryDate} fontWeight="bold" color={themeTokens.color.text.primary} fontSize="1.25rem" />
            }
            secondary={<>{expiryDateLabel}</>}
          />
        </Grid>
      </Grid>
    </Card>
  );
}

export function PlanCardByLicensingChannel({
  cloudSubscription,
  licensingChannel,
}: {
  cloudSubscription: CloudSubscriptionDto;
  licensingChannel: CloudLicensingChannel;
}) {
  const defaultProductName = "Cloud";
  switch (licensingChannel.channelType) {
    case CloudLicensingChannelTypesKeys.Salesforce:
    case CloudLicensingChannelTypesKeys.ImportedTrial:
    case CloudLicensingChannelTypesKeys.Trial:
      return (
        <PlanCard
          productName={defaultProductName}
          expiryDateLabel={"Expiry"}
          expiryDate={licensingChannel.expiryDate}
          channelType={licensingChannel.channelType}
          cloudSubscription={cloudSubscription}
        />
      );
    case CloudLicensingChannelTypesKeys.Zuora:
      return (
        <PlanCard
          productName={defaultProductName}
          expiryDateLabel={licensingChannel.cancelAtPeriodEnd ? "Cancels at" : "License expiry"}
          expiryDate={licensingChannel.nextRenewalDate}
          channelType={licensingChannel.channelType}
          cloudSubscription={cloudSubscription}
        />
      );
    case CloudLicensingChannelTypesKeys.Stripe:
      return (
        <PlanCard
          productName={licensingChannel.productName || defaultProductName}
          expiryDateLabel={licensingChannel.cancelAtPeriodEnd ? "Cancels at" : "Next renewal"}
          expiryDate={licensingChannel.nextRenewalDate}
          channelType={licensingChannel.channelType}
          cloudSubscription={cloudSubscription}
        />
      );
    case CloudLicensingChannelTypesKeys.LegacyBraintree:
    default:
      return null;
  }
}
