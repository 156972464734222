export enum CloudSubscriptionOutageWindowFrequency {
  Daily = "Daily",
  FirstMondayOfEachMonth = "FirstMondayOfEachMonth",
  SecondMondayOfEachMonth = "SecondMondayOfEachMonth",
  ThirdMondayOfEachMonth = "ThirdMondayOfEachMonth",
}

export const cloudSubscriptionOutageWindowFrequencyDescriptions: Record<
  CloudSubscriptionOutageWindowFrequency,
  string
> = {
  [CloudSubscriptionOutageWindowFrequency.Daily]: "Daily",
  [CloudSubscriptionOutageWindowFrequency.FirstMondayOfEachMonth]: "First Monday of each month",
  [CloudSubscriptionOutageWindowFrequency.SecondMondayOfEachMonth]: "Second Monday of each month",
  [CloudSubscriptionOutageWindowFrequency.ThirdMondayOfEachMonth]: "Third Monday of each month",
};

export function isDailyOutageWindow(outageWindowFrequency: CloudSubscriptionOutageWindowFrequency) {
  return outageWindowFrequency === CloudSubscriptionOutageWindowFrequency.Daily;
}
