import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, CircularProgress, styled } from "@mui/material";
import { useConfiguration } from "contexts/ConfigurationProvider/ConfigurationProvider";
import type { EventListener } from "shared/EventListener";
import type { EventPaymentFailure, EventPaymentSuccessful } from "shared/PaymentEvents";
import { PaymentEventTypes } from "shared/PaymentEvents";
import type { ZuoraClientErrorMessageCallback } from "shared/ZuoraLibraryTypes";
import { isZuoraLibrary, type ZuoraCallback } from "shared/ZuoraLibraryTypes";
import { getZuoraRsaSignature } from "client/api/PurchaseApi";
import { CustomErrorAlert, ErrorMessageTryAgainOrContactSupport } from "components/alert/CustomErrorAlert";
import { zuoraLibraryUrl, zuoraLibraryScriptId, zuoraPaymentIframeId } from "areas/checkout/constants/Zuora";

export const useZuoraHostedPaymentPage = (
  zuoraCallback: ZuoraCallback,
  clientErrorMessageCallback: ZuoraClientErrorMessageCallback,
  paymentSuccessful: EventListener<EventPaymentSuccessful>,
  paymentFailure: EventListener<EventPaymentFailure>
) => {
  const config = useConfiguration();
  const [isLoading, setIsLoading] = useState(true);
  const [requestSignatureError, setRequestSignatureError] = useState<Error | undefined>();
  const [zuoraConfigError, setZuoraConfigError] = useState<Error | undefined>();
  const controllerRef = useRef<AbortController | null>(null);

  const getZuoraConfig = useCallback(() => {
    if (!config.configuration) {
      const e = new Error("Missing Zuora config");
      setZuoraConfigError(e);
      return;
    }

    const {
      zuoraTenantId: tenantId,
      zuoraHostedPaymentPageId: id,
      zuoraHostedPaymentPageUri: url,
      zuoraPaymentGatewayName: paymentGateway,
    } = config.configuration;

    return {
      tenantId,
      id,
      url,
      paymentGateway,
    };
  }, [config.configuration]);
  const zuoraConfig = useMemo(getZuoraConfig, [getZuoraConfig]);

  const renderZuora = useCallback(async () => {
    if (!zuoraConfig) {
      const e = new Error("Missing Zuora config");
      setZuoraConfigError(e);
      return;
    }
    setIsLoading(true);

    // eslint-disable-next-line @typescript-eslint/init-declarations
    let auth;
    try {
      auth = await getZuoraRsaSignature();
    } catch (e) {
      if (e instanceof Error) {
        setRequestSignatureError(e);
      }
      return;
    } finally {
      setIsLoading(false);
    }

    const params = {
      ...zuoraConfig,
      authorizationAmount: 1,
      style: "inline",
      submitEnabled: "false",
      locale: "en_US",
      param_supportedTypes: "AmericanExpress,JCB,Visa,MasterCard,Discover",
      signatureType: "advanced",
      token: auth.token,
      signature: auth.signature,
      key: auth.key,
    } as const;

    const Z = window.Z;
    if (isZuoraLibrary(Z)) {
      Z.renderWithErrorHandler(params, {}, zuoraCallback, clientErrorMessageCallback);
    }
  }, [zuoraCallback, clientErrorMessageCallback, zuoraConfig]);

  useEffect(() => {
    /** Adds event listeners for events dispatched from the callback page */
    const addZuoraEventListeners = () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      const controller = new AbortController();
      controllerRef.current = controller;
      const signal = controllerRef.current.signal;

      window.addEventListener(PaymentEventTypes.PaymentSuccessful, paymentSuccessful, { signal });
      window.addEventListener(PaymentEventTypes.PaymentFailure, paymentFailure, { signal });
    };

    addZuoraEventListeners();

    return () => {
      // abort event listeners
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [paymentFailure, paymentSuccessful]);

  return { isLoading, renderZuora, requestSignatureError, zuoraConfigError };
};

export const useZuoraLibraryScriptLoader = () => {
  const hasLoadedScript =
    !!document.getElementById(zuoraLibraryScriptId) && typeof window.Z === "object" && window.Z !== null;
  const [isZuoraLibraryReady, setIsZuoraLibraryReady] = useState(hasLoadedScript);
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    if (document.getElementById(zuoraLibraryScriptId)) {
      // script element already exists, so return early
      return;
    }

    const zuoraScriptLoadEvent = () => {
      setIsZuoraLibraryReady(true);
    };

    const zuoraScriptError = () => {
      const e = new Error("Failed to load Zuora library");
      setError(e);
    };

    // dynamically load zuora library script
    const script = document.createElement("script");
    script.id = zuoraLibraryScriptId;
    script.src = zuoraLibraryUrl;
    script.async = true;
    script.onload = zuoraScriptLoadEvent;
    script.onerror = zuoraScriptError;
    document.body.appendChild(script);

    return () => {
      document.getElementById(zuoraLibraryScriptId)?.remove();
      setIsZuoraLibraryReady(false);
    };
  }, []);

  return { isZuoraLibraryReady, error };
};

const ZuoraContainer = styled("div")`
  min-height: 378px;
  iframe#z_hppm_iframe {
    background: transparent;
  }
`;

const LoadingState = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 378px;
`;

export const ZuoraHostedPaymentFormContainer = ({
  isZuoraLibraryReady,
  zuoraCallback,
  clientErrorMessageCallback,
  paymentSuccessful,
  paymentFailure,
  attempt,
}: {
  isZuoraLibraryReady: boolean;
  zuoraCallback: ZuoraCallback;
  clientErrorMessageCallback: ZuoraClientErrorMessageCallback;
  paymentSuccessful: EventListener<EventPaymentSuccessful>;
  paymentFailure: EventListener<EventPaymentFailure>;
  attempt: number;
}) => {
  const {
    isLoading: loadingZuoraHostedPaymentPage,
    renderZuora,
    requestSignatureError,
    zuoraConfigError,
  } = useZuoraHostedPaymentPage(zuoraCallback, clientErrorMessageCallback, paymentSuccessful, paymentFailure);
  const isLoading = useState(isZuoraLibraryReady && !loadingZuoraHostedPaymentPage ? true : false);

  /** Render Zuora Hosted Payment Page when the Zuora library becomes ready or the attempt number is updated */
  useEffect(() => {
    if (isZuoraLibraryReady) {
      renderZuora();
    }
  }, [renderZuora, isZuoraLibraryReady, attempt]);

  if (requestSignatureError || zuoraConfigError) {
    return (
      <Box marginTop={2} marginBottom={2}>
        <CustomErrorAlert title="Error loading payment form" message={<ErrorMessageTryAgainOrContactSupport />} />
      </Box>
    );
  }

  return (
    <ZuoraContainer id={zuoraPaymentIframeId}>
      {isLoading && (
        <LoadingState>
          <CircularProgress />
        </LoadingState>
      )}
    </ZuoraContainer>
  );
};
