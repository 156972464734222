import type { ListEntitiesResponse } from "client/api/common/ListEntitiesResponse";
import type { SubscriptionGroupDto } from "client/api/common/SubscriptionGroupDto";
import axiosClient from "client/axios-client";

const BASE_URL = "subscription-groups";

export type GetSubscriptionGroupsResponse = ListEntitiesResponse<SubscriptionGroupDto>;

export const getSubscriptionGroups = async (): Promise<GetSubscriptionGroupsResponse> => {
  const response = await axiosClient.get<GetSubscriptionGroupsResponse>(BASE_URL);

  return response.data;
};

export const updateSubscriptionGroup = async (
  subscriptionGroupDto: SubscriptionGroupDto
): Promise<SubscriptionGroupDto> => {
  const response = await axiosClient.put<SubscriptionGroupDto>(
    `${BASE_URL}/${subscriptionGroupDto.id}`,
    subscriptionGroupDto
  );

  return response.data;
};

export const deleteSubscriptionGroup = async (groupId: string): Promise<void> => {
  await axiosClient.delete(`${BASE_URL}/${groupId}`);
};

export const inviteUserToSubscriptionGroup = async (
  groupId: string | undefined,
  email: string,
  fullName: string,
  senderName: string,
  userRole: string
): Promise<void> => {
  await axiosClient.post(`${BASE_URL}/${groupId}/invites`, {
    email,
    fullName,
    senderName,
    userRole,
  });
};
