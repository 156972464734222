import type { ReactNode } from "react";
import React from "react";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { ListItem, ListItemIcon } from "@mui/material";
import { FontAwesomeSvgIcon } from "@cpt/components";

type PricingCardFeatureListItemProps = { feature: ReactNode; icon?: ReactNode };

export function PricingCardFeatureListItem(props: PricingCardFeatureListItemProps) {
  const { feature, icon } = props;
  return (
    <ListItem
      sx={{
        alignItems: "start",
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "1.3rem",
        paddingRight: "8px",
      }}
    >
      <ListItemIcon sx={{ minWidth: "32px" }}>
        {icon ? icon : <FontAwesomeSvgIcon icon={faCheckCircle} sx={{ color: "#0D80D8", fontSize: "24px" }} />}
      </ListItemIcon>
      {feature}
    </ListItem>
  );
}
