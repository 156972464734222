import * as React from "react";
import { styled } from "@mui/material";
import { borderRadius, colorScales } from "@octopusdeploy/design-system-tokens";

type ResourceLinkProps = {
  icon: React.ReactNode;
  label: string;
  href: string;
};

// Should be using the design system tokens here text.button.default.small
// but currently this app is using an outdated version of it which doesn't have the required tokens or the tokens have changed values.
const ResourceLinkButton = styled("a")`
  webkitfontsmoothing: "antialiased";
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition-timing-function: ease-in-out;
  transition-property: background-color, border-color, outline, color, fill;
  transition-duration: 0.2s;
  border-radius: ${borderRadius.medium};
  // padding-inline-start: space[12];
  padding-inline-start: 0.75rem;
  // padding-inline-end: space[12];
  padding-inline-end: 0.75rem;
  white-space: nowrap;
  //color: themeTokens.color.button.text.neutral,
  color: ${colorScales.slate[800]};
  //background: themeTokens.color.button.background.neutral.default,
  background: ${colorScales.slate[100]};
  // font: text.button.default.small;
  font: 400 0.875rem/1.5rem Roboto;
  //padding: space[6];
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  height: 40px;
  gap: 6px;

  svg {
    // fill: themeTokens.color.button.icon.neutral;
    fill: ${colorScales.slate[800]};
  }
  ":hover": {
    // background: themeTokens.color.button.background.neutral.hover;
    background: ${colorScales.slate[200]};
    // color: themeTokens.color.button.text.neutral;
    color: ${colorScales.slate[800]};
  }
  ":focus-visible": {
    //background: themeTokens.color.button.background.neutral.hover;
    background: ${colorScales.slate[200]};
  }
  ":active": {
    // background: themeTokens.color.button.background.neutral.pressed;
    background: ${colorScales.slate[200]};
    // color: themeTokens.color.button.text.neutral;
    color: ${colorScales.slate[800]};
  }
`;

export function ResourceLink({ label, href, icon }: ResourceLinkProps) {
  return (
    <ResourceLinkButton target="_blank" rel="noopener, noreferrer" href={href}>
      {icon}
      <span>{label}</span>
    </ResourceLinkButton>
  );
}
