import type { ReactNode } from "react";
import React from "react";
import WarningIcon from "@mui/icons-material/Warning";
import { Chip } from "@mui/material";
import { designSystemColors } from "@cpt/shared";
import { themeTokens } from "components/Theme/theme";

type WarningChipProps = {
  label?: ReactNode;
  type: "warning" | "error";
  role: string;
};

export function WarningChip(props: WarningChipProps) {
  const { label, type, role } = props;

  return (
    <Chip
      sx={{
        alignContent: "flex-end",
        backgroundColor: type === "warning" ? designSystemColors.orange["200"] : designSystemColors.red["200"],
        color: themeTokens.color.text.primary,
        fontSize: "12px",
        height: "20px",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: type === "warning" ? designSystemColors.orange["200"] : designSystemColors.red["200"],
        },
      }}
      color={"warning"}
      variant={"filled"}
      size={"small"}
      label={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              color: type === "warning" ? designSystemColors.orange["900"] : designSystemColors.red["900"],
            }}
          >
            {label}
          </span>
          <WarningIcon
            sx={{
              height: "12px",
              width: "12px",
              color: type === "warning" ? designSystemColors.orange["600"] : designSystemColors.red["600"],
              paddingLeft: "4px",
            }}
          />
        </div>
      }
      role={role}
    />
  );
}
