import React from "react";
import { Link } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import { RoutesMap } from "RoutesMap";
import { themeTokens } from "components/Theme/theme";

export interface TrialFulfillmentPendingProps {
  className?: string;
  style?: React.CSSProperties;
}

export function TrialFulfillmentPending(props: TrialFulfillmentPendingProps) {
  return (
    <Box display="flex" flexDirection="column" gap="32px" style={props.style} className={props.className}>
      <Box display="flex" flexDirection="row" alignItems="center" gap="8px">
        <CircularProgress />
        <Typography variant="overline">Preparing to launch</Typography>
      </Box>
      <Typography variant="h4" ml={{ xs: 0, md: 2 }} mt={{ xs: 1, md: 0 }}>
        You're <strong>less than a minute</strong> away from getting started with Octopus.
      </Typography>
      <Typography ml={{ xs: 0, md: 2 }} mt={{ xs: 1, md: 0 }}>
        Need to manage your account? Visit the{" "}
        <Link to={RoutesMap.root} target={"_blank"} style={{ color: themeTokens.color.text.link.default }}>
          Control Center
        </Link>
      </Typography>
    </Box>
  );
}
