import React from "react";
import { Grid, Typography } from "@mui/material";
import { ChangePlanFinanceLine } from "components/ChangePlanFinanceLine/ChangePlanFinanceLine";
import { GridDivider } from "components/GridDivider/GridDivider";

export function SalesforceContractedServerLicensingChannelView() {
  return (
    <>
      <Grid item sm={3} sx={{ padding: 3 }}>
        <Typography fontWeight={700}>Current plan</Typography>
      </Grid>
      <Grid item sm={9} sx={{ padding: 3 }}>
        <Typography>Octopus Server Enterprise, Annually</Typography>
      </Grid>
      <GridDivider />
      <ChangePlanFinanceLine />
    </>
  );
}
