import React from "react";
import { Grid, Typography, Stack } from "@mui/material";
import { DateText } from "@cpt/components";
import type { ZuoraServerLicensingChannelDto } from "client/api/ServerLicenseApi";
import { ChangePlanFinanceLine } from "components/ChangePlanFinanceLine/ChangePlanFinanceLine";
import { GridDivider } from "components/GridDivider/GridDivider";
import { themeTokens } from "components/Theme/theme";
import type { ServerLicensingChannelViewProps } from "./ServerLicensingChannelViewProps";

export function ZuoraServerLicensingChannelView(
  props: ServerLicensingChannelViewProps<ZuoraServerLicensingChannelDto>
) {
  const { channel, serverLicense } = props;
  const planStatusPrefix = channel.cancelAtPeriodEnd ? "Cancels on" : "License expiry";

  return (
    <>
      <Grid item sm={3} sx={{ padding: 3 }}>
        <Typography fontWeight={700}>Platform and licensing</Typography>
      </Grid>
      <Grid item sm={9} sx={{ padding: 3 }}>
        <Stack spacing={0}>
          <Typography>Server</Typography> {/* TODO: get actual plan name (sc-94081) */}
          <Typography sx={{ color: themeTokens.color.text.secondary }}>
            {planStatusPrefix} <DateText date={channel.nextRenewalDate} />
          </Typography>
        </Stack>
      </Grid>
      <GridDivider />
      <ChangePlanFinanceLine />
    </>
  );
}
