import React from "react";
import type { AutocompleteChangeDetails, AutocompleteChangeReason, InputBaseComponentProps } from "@mui/material";
import { CheckoutAutocompleteInput } from "./CheckoutAutocompleteInput";

type OnChange = (
  event: React.SyntheticEvent<Element, Event>,
  value: unknown | DropdownAutocompleteOption,
  reason: AutocompleteChangeReason,
  details?: AutocompleteChangeDetails<unknown> | undefined
) => void;

export interface DropdownAutocompleteOption {
  label: string;
  value: string;
}

export function isDropdownAutocompleteOption(
  obj: string | DropdownAutocompleteOption | null | undefined
): obj is DropdownAutocompleteOption {
  return obj !== null && typeof obj !== "undefined" && typeof obj !== "string" && obj.value !== undefined;
}

type SetOption = (value: DropdownAutocompleteOption | null) => void;

interface CheckoutDropdownInputProps {
  id?: string;
  options: DropdownAutocompleteOption[];
  error?: boolean;
  required?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  value?: DropdownAutocompleteOption | null;
  onSelectOption?: (value: unknown, setOption?: SetOption) => void;
  setOption?: SetOption;
  isOptionEqualToValue?: "valueMatch" | ((option: unknown, value: unknown) => boolean);
  InputProps?: InputBaseComponentProps;
}

export const getOptionByValue = (
  options: DropdownAutocompleteOption[],
  value: unknown | { label: string; value: string }
) => {
  if (typeof value === "object" && value !== null && "value" in value && value.value !== null) {
    const v = value.value;
    if (typeof v === "string") {
      const option = options.find((item) => item.value === v);
      return option;
    }
  }
  return undefined;
};

export function CheckoutDropdownInput(props: CheckoutDropdownInputProps) {
  const {
    id,
    options,
    error,
    required,
    placeholder = "Please select an option",
    fullWidth = true,
    value,
    setOption,
    InputProps,
  } = props;

  const isOptionEqualToValueMatchByValue = (option: unknown, value: unknown) => {
    // The usual default for MUI autocomplete is referential equality which can be a bit awkward. e.g. when using react-hook-form
    return JSON.stringify(option) === JSON.stringify(value);
  };

  const isOptionEqualToValue =
    props.isOptionEqualToValue === "valueMatch" ? isOptionEqualToValueMatchByValue : props.isOptionEqualToValue;

  const onChange: OnChange = (_event, value, reason) => {
    if (reason === "selectOption" && onSelectOptionDefault) {
      onSelectOptionDefault(value, setOption);
    }
  };

  const onSelectOptionDefault = (value: unknown | { label: string; value: string }, setOption?: SetOption) => {
    const option = getOptionByValue(options, value);
    setOption && setOption(option ?? null);
  };

  return (
    <CheckoutAutocompleteInput
      id={id}
      options={options}
      error={error}
      required={required}
      placeholder={placeholder}
      fullWidth={fullWidth}
      value={value}
      onChange={onChange}
      isOptionEqualToValue={isOptionEqualToValue}
      InputProps={InputProps}
    />
  );
}
