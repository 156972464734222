import React from "react";
import { Grid } from "@mui/material";
import { DateTime, Duration } from "luxon";
import useLocalStorage from "@cpt/hooks/useLocalStorage";
import type { ProductType } from "shared/ProductType";
import { ControlCenterV2MigrationBanner } from "components/ControlCenterV2MigrationBanner/ControlCenterV2MigrationBanner";
import type { RenewedProducts } from "components/RenewSuccessBanner/RenewSuccessBanner";
import { RENEWED_PRODUCTS_KEY, RenewSuccessBanner } from "components/RenewSuccessBanner/RenewSuccessBanner";
import type { UpgradedStripeProducts } from "components/StripeUpgradeSuccessAlert/StripeUpgradeSuccessAlert";
import {
  StripeUpgradeSuccessAlert,
  UPGRADED_STRIPE_PRODUCTS_KEY,
} from "components/StripeUpgradeSuccessAlert/StripeUpgradeSuccessAlert";
import type { UpgradedProducts } from "components/UpgradeSuccessBanner/UpgradeSuccessBanner";
import { UPGRADED_PRODUCTS_KEY, UpgradeSuccessBanner } from "components/UpgradeSuccessBanner/UpgradeSuccessBanner";

export type MigratedProducts = Record<string, string>;
export const MIGRATED_PRODUCTS_KEY = "migrated_products";

type DetailOverviewBannerProps = {
  id: string;
  freemium: boolean;
  financePageRoute: string;
  type: ProductType;
  nextRenewalDate?: DateTime;
};

function ShowMigrationBanner(freemium: boolean, date?: string) {
  if (!freemium) {
    return false;
  }

  if (!date) {
    return true;
  }

  const diffFromNow = DateTime.now().diff(DateTime.fromISO(date), "milliseconds").as("milliseconds");
  const oneDay = Duration.fromObject({ days: 1 }).as("milliseconds");
  return diffFromNow <= oneDay;
}

export function DetailOverviewBanner(props: DetailOverviewBannerProps) {
  const { id, freemium, financePageRoute, type, nextRenewalDate } = props;

  const [upgradedProducts] = useLocalStorage<UpgradedProducts>(UPGRADED_PRODUCTS_KEY, {});
  const [renewedProducts] = useLocalStorage<RenewedProducts>(RENEWED_PRODUCTS_KEY, {});
  const [migratedProducts, setMigratedProducts] = useLocalStorage<MigratedProducts>(MIGRATED_PRODUCTS_KEY, {});
  const [upgradedStripeProducts] = useLocalStorage<UpgradedStripeProducts>(UPGRADED_STRIPE_PRODUCTS_KEY, {});

  const upgradeDate = upgradedProducts[id];
  const renewedDate = renewedProducts[id];
  const migratedProduct = migratedProducts[id];
  const stripeUpgradeData = upgradedStripeProducts[id];

  // If we haven't seen this migrated product before add it to local storage with today's date
  if (!migratedProduct && freemium) {
    const updated = {
      ...migratedProducts,
      [id]: DateTime.now().toISODate(),
    };
    setMigratedProducts(updated);
  }

  const showUpgradeBanner = upgradeDate && !freemium;
  const showRenewBanner = renewedDate && nextRenewalDate && type === "ServerLicense" && !showUpgradeBanner && !freemium;
  const showMigrationBanner = ShowMigrationBanner(freemium, migratedProduct);
  const showStripeUpgradeAlert = stripeUpgradeData?.upgradedAt;
  const showBanner = showUpgradeBanner || showRenewBanner || showMigrationBanner || showStripeUpgradeAlert;

  const onBannerDismiss = () => {
    if (showUpgradeBanner) {
      // TODO lift more of the state up from UpgradeSuccessBanner
    }

    if (showMigrationBanner) {
      const updated = {
        ...migratedProducts,
        [id]: DateTime.now().minus({ days: 1 }).toISODate(),
      };
      setMigratedProducts(updated);
    }
  };

  return (
    <>
      {showBanner && (
        <Grid item xs={12}>
          {showUpgradeBanner && <UpgradeSuccessBanner id={id} type={type} financePageRoute={financePageRoute} />}
          {showRenewBanner && <RenewSuccessBanner id={id} nextRenewalDate={nextRenewalDate} />}
          {showMigrationBanner && <ControlCenterV2MigrationBanner id={id} type={type} onDismiss={onBannerDismiss} />}
          {showStripeUpgradeAlert && <StripeUpgradeSuccessAlert id={id} type={type} />}
        </Grid>
      )}
    </>
  );
}
