import axiosClient from "client/axios-client";

const BASE_URL = "configuration";

export type ConfigurationResponseDto = {
  serverLicenseUpgradeMaximumTargets: number;
  zuoraHostedPaymentPageId: string;
  zuoraHostedPaymentPageUri: string;
  zuoraTenantId: number;
  zuoraPaymentGatewayName: string;
};

export const getConfiguration = async (): Promise<ConfigurationResponseDto> => {
  const response = await axiosClient.get<ConfigurationResponseDto>(`${BASE_URL}`, {});
  return response.data;
};

export type FeatureFlagResponseDto = Record<string, boolean>;
export const getFeatureFlags = async (): Promise<FeatureFlagResponseDto> => {
  const response = await axiosClient.get<FeatureFlagResponseDto>(`${BASE_URL}/features`, {});
  return response.data;
};
