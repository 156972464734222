import React from "react";
import { Grid, Typography } from "@mui/material";
import type { LegacyBraintreeCloudSubscriptionLicensingChannelDto } from "client/api/CloudSubscriptionApi";
import { ChangePlanFinanceLine } from "components/ChangePlanFinanceLine/ChangePlanFinanceLine";
import { GridDivider } from "components/GridDivider/GridDivider";
import { OrdersFinanceLine } from "components/OrdersFinanceLine/OrdersFinanceLine";
import type { LicensingChannelViewProps } from "areas/cloudSubscriptions/detail/components/finance/LicensingChannelViewProps";

export function LegacyBraintreeLicensingChannelView(
  props: LicensingChannelViewProps<LegacyBraintreeCloudSubscriptionLicensingChannelDto>
) {
  const { cloudSubscription } = props;
  return (
    <>
      {/*<CurrentPlanLine>*/}
      <>
        <Grid item sm={3} sx={{ padding: 3 }}>
          <Typography fontWeight={700}>Current plan</Typography>
        </Grid>
        <Grid item sm={6} sx={{ padding: 3 }}>
          <Typography>Cloud</Typography>
        </Grid>
        <Grid item sm={3} sx={{ padding: 3 }}></Grid>
      </>
      <GridDivider />
      <ChangePlanFinanceLine />
      <GridDivider />
      <OrdersFinanceLine cloudSubscription={cloudSubscription} />
    </>
  );
}
