import React, { Fragment } from "react";
import { Alert, AlertTitle, Grid, Typography } from "@mui/material";
import axios from "axios";
import type { RequestError } from "client/RequestError";
import { EnhanceMessage } from "./EnhanceMessage";

interface ErrorAlertProps {
  error: unknown;
}

function isRequestError(error: unknown): error is RequestError {
  if (!error) {
    return false;
  }

  if (typeof error !== "object") {
    return false;
  }

  return "title" in error && "status" in error && "traceId" in error;
}

interface ErrorContent {
  title: string;
  content: string | Record<string, string[]>;
}

function getAlertContent(error: unknown): ErrorContent {
  if (axios.isAxiosError(error)) {
    const errorResponse = error.response?.data;
    if (!errorResponse) {
      return {
        title: "Something went wrong!",
        content: error.message ?? "Please try again or reach out for assistance.",
      };
    }
    const isKnownError = isRequestError(errorResponse);
    if (isKnownError) {
      return {
        title: errorResponse.title,
        content: errorResponse.detail ?? errorResponse.errors ?? "",
      };
    }
  }

  return {
    title: "Something went wrong!",
    content: error instanceof Error ? error.message : "Please try again or reach out for assistance.",
  };
}

export function ErrorAlert({ error }: ErrorAlertProps) {
  const errorContent = getAlertContent(error);

  return (
    <Grid item xs={12}>
      <Alert severity="error">
        <AlertTitle>{errorContent.title}</AlertTitle>
        {typeof errorContent.content === "string" ? (
          <Typography variant="subtitle1" gutterBottom component="div">
            <EnhanceMessage message={errorContent.content} />
          </Typography>
        ) : (
          Object.keys(errorContent.content).map((errKey, i) => (
            <Fragment key={`error-${i}`}>
              {errKey && (
                <Typography variant="subtitle1" gutterBottom component="div">
                  {errKey}:
                </Typography>
              )}
              {typeof errorContent.content === "object" &&
                errorContent.content[errKey]?.map((err, j) => (
                  <Typography key={`error-${i}-${j}`} variant="body2">
                    <EnhanceMessage message={err} />
                  </Typography>
                ))}
            </Fragment>
          ))
        )}
      </Alert>
    </Grid>
  );
}
