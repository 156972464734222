import React from "react";
import type { SxProps, Theme } from "@mui/material";
import { Chip } from "@mui/material";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";

interface CloudSubscriptionWarningChip {
  cloudSubscription: CloudSubscriptionDto;
  sx?: SxProps<Theme>;
}

export function CloudSubscriptionWarningChip(props: CloudSubscriptionWarningChip) {
  const { cloudSubscription, sx } = props;
  const isPastDue =
    cloudSubscription.licensingChannel.channelType == "StripeSubscriptionCloudSubscriptionLicensingChannelDto" &&
    cloudSubscription.licensingChannel.status == "past_due";
  return (
    <>
      {isPastDue && (
        <Chip sx={{ cursor: "inherit", ...sx }} color={"error"} variant={"filled"} size={"small"} label={"Overdue"} />
      )}
    </>
  );
}
