import React from "react";
import type { SelectChangeEvent } from "@mui/material";
import { MenuItem, Select, styled } from "@mui/material";
import { CloudPricingCatalogProducts, ServerPricingCatalogProducts } from "client/api/ProductPricingApi";
import type { CatalogProduct } from "../../areas/cloudSubscriptions/detail/CloudSubscriptionUpgrade";
import { PricingCardPrice } from "./PricingCardPrice";

const StyledSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== "isStarter",
})<{ isStarter: boolean }>(({ theme, isStarter }) => {
  return `
    width: 100%;
    min-height: 56px;
    ${
      isStarter &&
      `
        font-size: 14px;
        .MuiSelect-select {
          padding-right: 0px !important;
        }
        svg {
          display: none;
        }
      `
    }
  `;
});

export const PricingCardPriceSelector = ({
  catalog,
  selectedProjects,
  onChange,
}: {
  catalog: CatalogProduct;
  selectedProjects: string;
  onChange: (e: SelectChangeEvent<unknown>) => void;
}) => {
  const hasCatalog = !!(catalog && catalog.prices.length);
  const selectedPlan = catalog?.prices.find((item) => item.projectQuantity === selectedProjects);
  const price = selectedPlan?.totalPrice;

  const handleChangeProjects = (e: SelectChangeEvent<unknown>) => {
    onChange(e);
  };

  const hasCatalogName = !!(catalog && catalog.name);
  const isStarter =
    hasCatalogName &&
    [
      CloudPricingCatalogProducts.CloudStarter.toLowerCase(),
      ServerPricingCatalogProducts.ServerStarter.toLowerCase(),
    ].includes(catalog.name.toLowerCase());

  return (
    <>
      <PricingCardPrice price={price} currency={"USD"} pricePerLabel="/ year" />
      <StyledSelect
        value={selectedProjects}
        onChange={handleChangeProjects}
        disabled={!hasCatalog || catalog.prices.length <= 1}
        isStarter={isStarter}
        aria-label="Projects"
      >
        {hasCatalog &&
          catalog.prices.map((item) => {
            const isStarter10 = isStarter && parseInt(item.projectQuantity, 10) === 10;
            if (isStarter10) {
              return (
                <MenuItem key={`${item.projectQuantity}`} value={item.projectQuantity}>
                  10 Projects, 10 Tenants, 10 Machines
                </MenuItem>
              );
            }
            return (
              <MenuItem key={`${item.projectQuantity}`} value={item.projectQuantity}>
                {item.projectQuantity} Projects
              </MenuItem>
            );
          })}
      </StyledSelect>
    </>
  );
};
