import React from "react";
import { styled } from "@mui/material";
import { AlertSeverity, useAlerts } from "../AlertProvider/AlertProvider";

type CopyToClipboardWrapperProps = React.PropsWithChildren<{
  value?: string | null | undefined;
  alertMessage?: string;
  onCopied?: () => void;
}>;
const Wrapper = styled("div")`
  cursor: pointer;
`;
function CopyToClipboardWrapper(props: CopyToClipboardWrapperProps) {
  const { children, value, alertMessage } = props;
  const { sendNewAlert } = useAlerts();

  const copyToClipboard = async () => {
    if (value) {
      await navigator.clipboard.writeText(value);
      props.onCopied?.();
      sendNewAlert(alertMessage ?? "Copied to clipboard!", AlertSeverity.Success);
    }
  };

  return <Wrapper onClick={copyToClipboard}>{children}</Wrapper>;
}

export default CopyToClipboardWrapper;
