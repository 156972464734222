import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import type { AxiosError } from "axios";
import axios from "axios";
import type { DateTime } from "luxon";
import { DateText } from "@cpt/components";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import { isTrial } from "utils/isTrial";
import type { ServerLicenseDto, ServerLicensingChannelTypes } from "client/api/ServerLicenseApi";
import { cancelServerLicense } from "client/api/ServerLicenseApi";
import { AlertSeverity, useAlerts } from "components/AlertProvider/AlertProvider";
import { ErrorAlert } from "components/alert/ErrorAlert";
import type { ServerLicenseProps } from "areas/serverLicenses/ServerLicenseProps";

type ServerLicenseCancelPlanDialogProps = React.PropsWithChildren<{
  open: boolean;
  onClose: () => void;
}> &
  ServerLicenseProps;

export function ServerLicenseCancelPlanDialog(props: ServerLicenseCancelPlanDialogProps) {
  const { update } = useEntity<ServerLicenseDto>();
  const { open, onClose, serverLicense } = props;
  const { sendNewAlert } = useAlerts();

  const [isCancelling, setIsCancelling] = useState<boolean>(false);

  const [error, setError] = useState<AxiosError | undefined>(undefined);

  const cancelPlan = async () => {
    setIsCancelling(true);

    try {
      const changedServerLicense = await cancelServerLicense(serverLicense);
      update(changedServerLicense);

      sendNewAlert("Server license cancelled", AlertSeverity.Success);
      handleOnClose(true);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setError(e);
      } else {
        console.error(e);
      }
    } finally {
      setIsCancelling(false);
    }
  };

  const handleOnClose = (force = false) => {
    if (isCancelling && !force) {
      return;
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleOnClose()}
      aria-labelledby="cancel-subscription-dialog-title"
      aria-describedby="cancel-subscription-dialog-description"
    >
      <DialogTitle id="cancel-subscription-dialog-title">Cancel subscription?</DialogTitle>
      <DialogContent id="cancel-subscription-dialog-description">
        {error && <ErrorAlert error={error} />}
        <DialogContentText>{props.children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOnClose()} variant="outlined" disabled={isCancelling} autoFocus>
          Close
        </Button>
        <LoadingButton
          onClick={cancelPlan}
          variant="contained"
          color="error"
          loading={isCancelling}
          loadingIndicator={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="inherit" size={16} sx={{ mr: 1 }} />
              {"Cancelling..."}
            </Box>
          }
        >
          Cancel subscription
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export const ServerLicenseCancelPlanDialogText = ({
  channelType,
  endDate,
}: {
  channelType: ServerLicensingChannelTypes;
  endDate: DateTime;
}) => {
  const subscriptionType = isTrial(channelType) ? "trial" : "subscription";
  return (
    <span>
      If you cancel now, you can continue using your {subscriptionType} until{" "}
      <DateText date={endDate} fontWeight="bold" />.
    </span>
  );
};
