import React from "react";
import { Outlet } from "react-router-dom";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import type { SubscriptionGroupDto } from "client/api/common/SubscriptionGroupDto";
import type { RbacPermission } from "components/authorization/RbacPermissions";
import { NotFoundPage } from "pages/NotFoundPage";

type ProtectedRouteProps = {
  permission: RbacPermission;
  element?: React.ReactNode;
};
type ObjectWithAssetId = object & {
  id: string;
  subscriptionGroup?: SubscriptionGroupDto;
};

function getAssetIds(entity?: ObjectWithAssetId): [string | undefined, string | undefined] {
  return [entity?.id, entity?.subscriptionGroup?.id];
}

export function ProtectedRoute(props: ProtectedRouteProps) {
  const { permission, element } = props;
  const { entity } = useEntity<ObjectWithAssetId>();
  const [assetId, subscriptionGroupId] = getAssetIds(entity);
  const { hasPermissionForAsset } = useUserContext();
  return hasPermissionForAsset(permission, assetId, subscriptionGroupId) ? (
    <>{element || <Outlet />}</>
  ) : (
    <NotFoundPage />
  );
}
