import type { AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";
import qs from "qs";
import { interceptAndDeserializeDateTimes } from "@cpt/shared";
import { getAccessToken } from "./api/Authentication";

const defaultAxiosConfig: AxiosRequestConfig = {
  baseURL: process.env.API_URL,
  paramsSerializer: (params) => {
    return qs.stringify(params, { allowDots: true });
  },
};

export const unauthenticatedAxiosClient = axios.create(defaultAxiosConfig);
const axiosClient = axios.create(defaultAxiosConfig);

axiosClient.interceptors.request.use(
  async (request) => {
    const accessToken = getAccessToken();

    if (!request.headers) {
      request.headers = {};
    }

    if (accessToken !== undefined) {
      request.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return request;
  },
  (error) => Promise.reject(error)
);

axiosClient.interceptors.response.use(
  (req) => Promise.resolve(req),
  async (error) => {
    if (error?.response?.status === 401) {
      const { config } = error.response;
      const accessToken = getAccessToken();

      if (!config.headers) {
        config.headers = {};
      }

      if (accessToken !== undefined) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }

      // Don't want to use the axios client otherwise it will get stuck in an infinite loop if it can't refresh the token
      return axios.request(config);
    }
    throw error;
  }
);

axiosClient.interceptors.response.use((res: AxiosResponse) => {
  if (window.MiniProfiler) {
    const header = res.headers["x-miniprofiler-ids"];
    if (header) {
      const ids: string[] = JSON.parse(header);
      window.MiniProfiler.fetchResults(ids);
    }
  }

  return res;
});

axiosClient.interceptors.response.use(interceptAndDeserializeDateTimes);

export default axiosClient;
