import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import { RoutesMap } from "RoutesMap";
import { usePageTitle } from "hooks/usePageTitle";
import { usePolling } from "hooks/usePolling";
import { getHostname } from "utils/formatters/DnsFormatters";
import { getTrialFulfilled, TrialFulfillmentErrorCode } from "client/api/FulfillmentApi";
import { TrialFulfillmentContent } from "components/Trials/TrialFulfillmentContent";
import { TrialFulfillmentError } from "components/Trials/TrialFulfillmentError";
import { TrialFulfillmentPending } from "components/Trials/TrialFulfillmentPending";
import { TrialFulfillmentSuccess } from "components/Trials/TrialFulfillmentSuccess";
import { TrialFulfillmentTimeout } from "components/Trials/TrialFulfillmentTimeout";
import BlobK8s from "resources/images/svgs/blob-k8s.svg";
import { ServerLicensesRoutesMap } from "areas/serverLicenses/ServerLicensesRoutesMap";

type TrialFulfillmentPollingPageProps = {
  timeoutMs?: number;
};

export function TrialFulfillmentPollingPage(props: TrialFulfillmentPollingPageProps) {
  const { timeoutMs } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const intentId = searchParams.get("i");
  const [href, setHref] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const setTitle = usePageTitle("Octopus: Preparing to launch");

  const checkProvisioned = async () => {
    if (!intentId) {
      navigate({ pathname: RoutesMap.notFound });
      return;
    }
    const response = await getTrialFulfilled(intentId);
    if (response.error) {
      switch (response.error) {
        case TrialFulfillmentErrorCode.ActivePlatformLimitExceeded:
        case TrialFulfillmentErrorCode.HistoricalLimitExceeded:
          setError("You have reached the limit for the number of allowed trials.");
          break;
        default:
          setError("Sorry, an unknown error occurred.");
          break;
      }
      setLoading(false);
    }
    if (response.fulfilled) {
      setTitle("Your Octopus is ready!");

      if (response.serverLicenses.length > 0 && response.serverLicenses[0]) {
        setHref(ServerLicensesRoutesMap.loadBySerial(response.serverLicenses[0]).root);
      }
      if (response.cloudSubscriptions.length > 0 && response.cloudSubscriptions[0]) {
        setHref(`https://${getHostname(response.cloudSubscriptions[0].dnsPrefix)}`);
      }
      setError(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    const timeout = () => setLoading(false);
    const t = setTimeout(timeout, timeoutMs ?? 90000);
    return () => clearTimeout(t);
  }, [timeoutMs]);

  usePolling(checkProvisioned, loading);

  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="row"
      alignItems="center"
      overflow="hidden"
      sx={{
        backgroundImage: `url(${BlobK8s})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        backgroundPosition: "center right",
      }}
    >
      <Box margin={8}>
        <TrialFulfillmentContent>
          {error && <TrialFulfillmentError error={error} />}
          {loading && <TrialFulfillmentPending />}
          {!error && !loading && href && <TrialFulfillmentSuccess href={href} />}
          {!loading && !error && !href && <TrialFulfillmentTimeout />}
        </TrialFulfillmentContent>
      </Box>
    </Box>
  );
}
