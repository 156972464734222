import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { IsSubscribedOrLapsed } from "utils/CloudSubscription/CloudSubscriptionStatus";
import type { ImportedTrialCloudSubscriptionLicensingChannelDto } from "client/api/CloudSubscriptionApi";
import { ChangePlanFinanceLine } from "components/ChangePlanFinanceLine/ChangePlanFinanceLine";
import { GridDivider } from "components/GridDivider/GridDivider";
import { LinkButton } from "components/LinkButton/LinkButton";
import { OrdersFinanceLine } from "components/OrdersFinanceLine/OrdersFinanceLine";
import { PolicyAuthorized } from "components/authorization/PolicyAuthorized";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import {
  CloudSubscriptionCancelPlanDialog,
  CloudSubscriptionCancelPlanDialogText,
} from "areas/cloudSubscriptions/detail/components/finance/CloudSubscriptionCancelPlanDialog";
import type { LicensingChannelViewProps } from "areas/cloudSubscriptions/detail/components/finance/LicensingChannelViewProps";

export function ImportedTrialLicensingChannelView(
  props: LicensingChannelViewProps<ImportedTrialCloudSubscriptionLicensingChannelDto>
) {
  const { cloudSubscription, channel } = props;
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
  return (
    <>
      {/*<CurrentPlanLine>*/}
      <>
        <Grid item sm={3} sx={{ padding: 3 }}>
          <Typography fontWeight={700}>Current plan</Typography>
        </Grid>
        <Grid item sm={6} sx={{ padding: 3 }}>
          <Typography>Cloud</Typography>
        </Grid>
        <Grid item sm={3} sx={{ padding: 3 }}>
          {IsSubscribedOrLapsed(cloudSubscription.cloudSubscriptionStatus) && (
            <PolicyAuthorized
              permission={RbacPermissions.CloudSubscription.ManageBilling}
              assetId={cloudSubscription?.id}
              subscriptionGroupId={cloudSubscription?.subscriptionGroup?.id}
            >
              <LinkButton onClick={() => setShowCancelDialog(true)}>Cancel subscription</LinkButton>
              <CloudSubscriptionCancelPlanDialog
                channelType={channel.channelType}
                open={showCancelDialog}
                onClose={() => setShowCancelDialog(false)}
                cloudSubscription={cloudSubscription}
              >
                <CloudSubscriptionCancelPlanDialogText channelType={channel.channelType} endDate={channel.expiryDate} />
              </CloudSubscriptionCancelPlanDialog>
            </PolicyAuthorized>
          )}
        </Grid>
      </>
      <GridDivider />
      <ChangePlanFinanceLine />
      <GridDivider />
      <OrdersFinanceLine cloudSubscription={cloudSubscription} />
    </>
  );
}
