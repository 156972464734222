import React from "react";
import { SvgIcon } from "@mui/material";

interface TentacleHeartIconProps {}

export function TentacleHeartIcon(props: TentacleHeartIconProps) {
  return (
    <SvgIcon
      sx={{
        width: "96px",
        height: "96px",
      }}
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5311_2531)">
        <g filter="url(#filter0_i_5311_2531)">
          <rect
            x="27.7031"
            y="30.9685"
            width="46.0613"
            height="46.0613"
            rx="23.0306"
            transform="rotate(5.97211 27.7031 30.9685)"
            fill="#00FFA3"
            fillOpacity="0.4"
          />
        </g>
        <rect
          x="18.8614"
          y="20.0625"
          width="65.9153"
          height="65.9153"
          rx="32.9576"
          transform="rotate(5.97211 18.8614 20.0625)"
          fill="#0D80D8"
          fillOpacity="0.1"
          stroke="#0D80D8"
          strokeWidth="0.79416"
        />
        <g filter="url(#filter1_d_5311_2531)">
          <mask
            id="mask0_5311_2531"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="25"
            y="5"
            width="70"
            height="75"
          >
            <path
              d="M82.0543 61.008C81.6844 64.5433 69.5993 65.4662 67.8563 68.3062C63.3622 75.6286 54.9336 80.1295 45.8185 79.1759C33.168 77.8525 23.9856 66.5245 25.309 53.8741C26.6324 41.2236 40.1038 3.91923 52.7543 5.24262C82.5251 5.90741 96.5682 14.7657 94.3138 23.9629C92.5264 31.2548 94.661 55.2646 82.0543 61.008Z"
              fill="#D9D9D9"
            />
          </mask>
          <g mask="url(#mask0_5311_2531)">
            <path
              d="M39.9153 32.9587L38.6571 33.7547C38.6571 33.7547 34.546 25.2249 45.1367 19.0638L39.9153 32.9587Z"
              fill="#0E80D8"
            />
            <path
              d="M4.31913 16.2712C4.31913 16.2712 20.4233 10.9127 23.0333 17.0861C25.6434 23.2595 25.2571 26.8099 22.4274 30.2168C19.5978 33.6236 3.82414 60.7027 16.5771 65.921C29.3301 71.1394 37.4714 67.1185 43.4752 63.88C49.4447 60.6528 61.6843 52.8059 65.015 45.6206C68.3458 38.4352 72.7203 25.2004 67.2494 21.3032C61.7784 17.4061 55.2606 20.7004 55.2606 20.7004C55.2606 20.7004 65.7041 14.0935 71.1339 24.8878C76.5636 35.6822 68.3892 52.15 60.2171 59.1855C52.0449 66.2209 34.1999 77.8212 19.3608 75.4979C4.52182 73.1746 -1.86752 71.1021 -5.84758 64.8108C-9.82763 58.5196 -13.7066 57.1389 -11.6159 43.898C-9.52515 30.6571 -1.61183 17.8884 4.31913 16.2712Z"
              fill="#0E80D8"
            />
            <path
              d="M55.2511 20.6656C55.2511 20.6656 65.5451 14.4123 71.1243 24.853C76.4521 35.3387 68.3797 52.1152 60.2075 59.1506C52.0354 66.1861 34.1903 77.7864 19.3513 75.4631C4.51223 73.1397 0.0669113 70.0447 -3.91314 63.7534C-3.91314 63.7534 0.713183 84.6632 18.8678 85.5108C32.2724 86.1403 63.1637 76.8471 72.1831 60.4044C81.2025 43.9616 90.9012 20.1002 69.1117 13.0058C55.251 9.00025 38.6258 24.4468 38.7526 28.2841C38.8794 32.1213 39.3356 32.3509 39.9051 32.9234L42.3083 30.9885C42.3196 31.0228 41.9904 25.9974 55.2511 20.6656Z"
              fill="#3DA4F3"
            />
            <path
              d="M38.6549 33.7538C38.6549 33.7538 41.2756 35.9304 43.6758 35.1375C43.6758 35.1375 40.7092 34.216 39.9132 32.9578L38.6549 33.7538Z"
              fill="#0E80D8"
            />
            <path
              d="M39.9158 32.9586C39.9158 32.9586 40.7118 34.2168 43.6784 35.1382C46.645 36.0597 50.885 30.4755 50.6585 29.7897C50.4319 29.1039 49.5196 28.6447 48.7175 29.6703C47.9154 30.696 46.5423 31.72 44.7147 31.9436C42.887 32.1671 42.6604 31.4813 42.319 31.0237L39.9158 32.9586Z"
              fill="#49A2EA"
            />
            <path
              d="M25.7977 78.1635C25.4849 77.2167 25.9989 76.1955 26.9457 75.8827C27.8926 75.5699 28.9137 76.0839 29.2266 77.0307C29.5394 77.9775 29.0254 78.9987 28.0786 79.3115C27.1317 79.6243 26.1106 79.1104 25.7977 78.1635Z"
              stroke="#87E4F9"
              strokeWidth="1.81412"
              strokeMiterlimit="10"
            />
            <path
              d="M34.7079 76.3615C34.3951 75.4147 34.909 74.3935 35.8559 74.0807C36.8027 73.7679 37.8239 74.2819 38.1367 75.2287C38.4495 76.1755 37.9356 77.1967 36.9887 77.5095C36.0419 77.8223 35.0207 77.3084 34.7079 76.3615Z"
              stroke="#87E4F9"
              strokeWidth="1.81412"
              strokeMiterlimit="10"
            />
            <path
              d="M42.2352 79.5786C41.9224 78.6317 42.4364 77.6106 43.3832 77.2977C44.3301 76.9849 45.3512 77.4989 45.6641 78.4457C45.9769 79.3926 45.4629 80.4137 44.5161 80.7266C43.5692 81.0394 42.5481 80.5254 42.2352 79.5786Z"
              stroke="#87E4F9"
              strokeWidth="1.81412"
              strokeMiterlimit="10"
            />
            <path
              d="M42.372 71.9274C42.0591 70.9806 42.5731 69.9594 43.52 69.6466C44.4668 69.3338 45.488 69.8478 45.8008 70.7946C46.1136 71.7415 45.5996 72.7626 44.6528 73.0754C43.7059 73.3883 42.6848 72.8743 42.372 71.9274Z"
              stroke="#87E4F9"
              strokeWidth="1.81412"
              strokeMiterlimit="10"
            />
            <path
              d="M49.3251 75.7143C49.0123 74.7675 49.5262 73.7463 50.4731 73.4335C51.4199 73.1207 52.4411 73.6346 52.7539 74.5815C53.0667 75.5283 52.5527 76.5495 51.6059 76.8623C50.6591 77.1751 49.6379 76.6612 49.3251 75.7143Z"
              stroke="#87E4F9"
              strokeWidth="1.81412"
              strokeMiterlimit="10"
            />
            <path
              d="M50.2587 68.1796C49.9458 67.2328 50.4598 66.2116 51.4067 65.8988C52.3535 65.586 53.3747 66.1 53.6875 67.0468C54.0003 67.9937 53.4863 69.0148 52.5395 69.3276C51.5926 69.6405 50.5715 69.1265 50.2587 68.1796Z"
              stroke="#87E4F9"
              strokeWidth="1.81412"
              strokeMiterlimit="10"
            />
            <path
              d="M56.8759 70.9387C56.563 69.9918 57.077 68.9707 58.0239 68.6578C58.9707 68.345 59.9919 68.859 60.3047 69.8059C60.6175 70.7527 60.1035 71.7739 59.1567 72.0867C58.2098 72.3995 57.1887 71.8855 56.8759 70.9387Z"
              stroke="#87E4F9"
              strokeWidth="1.81412"
              strokeMiterlimit="10"
            />
            <path
              d="M55.9813 63.6284C55.6685 62.6815 56.1825 61.6604 57.1293 61.3475C58.0762 61.0347 59.0973 61.5487 59.4102 62.4955C59.723 63.4424 59.209 64.4636 58.2621 64.7764C57.3153 65.0892 56.2941 64.5752 55.9813 63.6284Z"
              stroke="#87E4F9"
              strokeWidth="1.81412"
              strokeMiterlimit="10"
            />
            <path
              d="M63.0555 65.4726C62.7427 64.5258 63.2567 63.5046 64.2035 63.1918C65.1504 62.879 66.1716 63.3929 66.4844 64.3398C66.7972 65.2866 66.2832 66.3078 65.3364 66.6206C64.3895 66.9334 63.3684 66.4195 63.0555 65.4726Z"
              stroke="#87E4F9"
              strokeWidth="1.81412"
              strokeMiterlimit="10"
            />
            <path
              d="M62.4824 58.0952C62.2009 57.2431 62.6635 56.324 63.5156 56.0425C64.3678 55.7609 65.2868 56.2235 65.5684 57.0757C65.8499 57.9278 65.3873 58.8469 64.5352 59.1284C63.683 59.41 62.7639 58.9474 62.4824 58.0952Z"
              stroke="#87E4F9"
              strokeWidth="1.64992"
              strokeMiterlimit="10"
            />
            <path
              d="M69.1493 58.8215C68.9053 58.083 69.3062 57.2865 70.0448 57.0425C70.7833 56.7985 71.5798 57.1994 71.8238 57.9379C72.0678 58.6764 71.6669 59.4729 70.9284 59.7169C70.1898 59.9609 69.3933 59.56 69.1493 58.8215Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M66.8251 53.0486C66.5811 52.31 66.982 51.5135 67.7206 51.2695C68.4591 51.0255 69.2556 51.4264 69.4996 52.165C69.7436 52.9035 69.3427 53.7 68.6042 53.944C67.8656 54.188 67.0691 53.7871 66.8251 53.0486Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M73.1064 52.4934C72.8624 51.7548 73.2633 50.9583 74.0018 50.7143C74.7404 50.4703 75.5369 50.8712 75.7809 51.6098C76.0249 52.3483 75.624 53.1448 74.8854 53.3888C74.1469 53.6328 73.3504 53.2319 73.1064 52.4934Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M70.3251 46.4912C70.0811 45.7526 70.482 44.9561 71.2206 44.7121C71.9591 44.4681 72.7556 44.869 72.9996 45.6076C73.2436 46.3461 72.8427 47.1426 72.1042 47.3866C71.3656 47.6306 70.5691 47.2297 70.3251 46.4912Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M77.2978 43.4265C77.0538 42.6879 77.4547 41.8914 78.1932 41.6474C78.9318 41.4034 79.7283 41.8043 79.9723 42.5429C80.2163 43.2814 79.8154 44.0779 79.0768 44.3219C78.3383 44.5659 77.5418 44.165 77.2978 43.4265Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M73.4267 38.6196C73.1827 37.8811 73.5836 37.0846 74.3221 36.8406C75.0607 36.5966 75.8572 36.9975 76.1012 37.736C76.3452 38.4745 75.9443 39.271 75.2057 39.515C74.4672 39.759 73.6707 39.3581 73.4267 38.6196Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M78.6923 33.8379C78.4483 33.0993 78.8492 32.3028 79.5878 32.0588C80.3263 31.8148 81.1228 32.2157 81.3668 32.9543C81.6108 33.6928 81.2099 34.4893 80.4714 34.7333C79.7328 34.9773 78.9363 34.5764 78.6923 33.8379Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M73.04 30.8762C72.796 30.1377 73.1969 29.3412 73.9354 29.0972C74.6739 28.8532 75.4705 29.2541 75.7145 29.9926C75.9585 30.7311 75.5575 31.5276 74.819 31.7716C74.0805 32.0156 73.284 31.6147 73.04 30.8762Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M77.0478 25.7488C76.8038 25.0102 77.2047 24.2137 77.9432 23.9697C78.6818 23.7257 79.4783 24.1266 79.7223 24.8652C79.9663 25.6037 79.5654 26.4002 78.8268 26.6442C78.0883 26.8882 77.2918 26.4873 77.0478 25.7488Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M71.4579 23.7925C71.2139 23.0539 71.6148 22.2574 72.3534 22.0134C73.0919 21.7694 73.8884 22.1703 74.1324 22.9089C74.3764 23.6474 73.9755 24.4439 73.237 24.6879C72.4984 24.9319 71.7019 24.531 71.4579 23.7925Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M71.5907 17.2837C71.3467 16.5451 71.7477 15.7486 72.4862 15.5046C73.2247 15.2606 74.0212 15.6615 74.2652 16.4001C74.5092 17.1386 74.1083 17.9351 73.3698 18.1791C72.6312 18.4231 71.8347 18.0222 71.5907 17.2837Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M64.7236 17.8027C64.4796 17.0642 64.8805 16.2677 65.619 16.0237C66.3575 15.7797 67.154 16.1806 67.398 16.9191C67.642 17.6576 67.2411 18.4541 66.5026 18.6981C65.7641 18.9421 64.9676 18.5412 64.7236 17.8027Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M60.0556 14.0202C59.8116 13.2817 60.2125 12.4852 60.951 12.2412C61.6896 11.9972 62.4861 12.3981 62.7301 13.1366C62.9741 13.8752 62.5732 14.6717 61.8346 14.9157C61.0961 15.1597 60.2996 14.7588 60.0556 14.0202Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M57.23 18.3757C57.036 17.7887 57.3547 17.1556 57.9417 16.9616C58.5288 16.7677 59.1619 17.0863 59.3559 17.6734C59.5498 18.2604 59.2311 18.8936 58.6441 19.0875C58.0571 19.2815 57.4239 18.9628 57.23 18.3757Z"
              stroke="#87E4F9"
              strokeWidth="1.13845"
              strokeMiterlimit="10"
            />
            <path
              d="M52.0972 16.6489C51.9032 16.0619 52.2219 15.4288 52.8089 15.2348C53.396 15.0409 54.0291 15.3595 54.223 15.9466C54.417 16.5336 54.0983 17.1667 53.5113 17.3607C52.9242 17.5546 52.2911 17.236 52.0972 16.6489Z"
              stroke="#87E4F9"
              strokeWidth="1.13845"
              strokeMiterlimit="10"
            />
            <path
              d="M50.2573 21.4409C50.0634 20.8539 50.382 20.2208 50.9691 20.0268C51.5561 19.8329 52.1893 20.1515 52.3832 20.7386C52.5771 21.3256 52.2585 21.9587 51.6714 22.1527C51.0844 22.3466 50.4513 22.028 50.2573 21.4409Z"
              stroke="#87E4F9"
              strokeWidth="1.13845"
              strokeMiterlimit="10"
            />
            <path
              d="M45.0073 20.5129C44.8134 19.9259 45.132 19.2928 45.7191 19.0988C46.3061 18.9049 46.9393 19.2236 47.1332 19.8106C47.3271 20.3976 47.0085 21.0308 46.4214 21.2247C45.8344 21.4187 45.2013 21.1 45.0073 20.5129Z"
              stroke="#87E4F9"
              strokeWidth="1.13845"
              strokeMiterlimit="10"
            />
            <path
              d="M43.7975 25.4776C43.6348 24.9852 43.9021 24.4542 44.3944 24.2916C44.8868 24.1289 45.4178 24.3962 45.5805 24.8885C45.7431 25.3809 45.4759 25.9119 44.9835 26.0746C44.4911 26.2372 43.9601 25.97 43.7975 25.4776Z"
              stroke="#87E4F9"
              strokeWidth="0.957042"
              strokeMiterlimit="10"
            />
            <path
              d="M39.0045 27.44C38.8418 26.9476 39.1091 26.4166 39.6015 26.254C40.0938 26.0913 40.6248 26.3586 40.7875 26.8509C40.9502 27.3433 40.6829 27.8743 40.1905 28.037C39.6982 28.1996 39.1672 27.9324 39.0045 27.44Z"
              stroke="#87E4F9"
              strokeWidth="0.957042"
              strokeMiterlimit="10"
            />
            <path
              d="M40.5141 30.7439C40.3577 30.2704 40.6147 29.7599 41.0881 29.6035C41.5615 29.447 42.0721 29.704 42.2285 30.1775C42.3849 30.6509 42.1279 31.1615 41.6545 31.3179C41.1811 31.4743 40.6705 31.2173 40.5141 30.7439Z"
              stroke="#87E4F9"
              strokeWidth="0.898728"
              strokeMiterlimit="10"
            />
            <path
              d="M41.3174 34.0921C41.211 33.7701 41.3858 33.4229 41.7077 33.3166C42.0297 33.2102 42.3768 33.385 42.4832 33.7069C42.5896 34.0288 42.4148 34.376 42.0929 34.4824C41.771 34.5887 41.4238 34.414 41.3174 34.0921Z"
              stroke="#87E4F9"
              strokeWidth="0.6195"
              strokeMiterlimit="10"
            />
            <path
              d="M43.458 32.7393C43.3517 32.4173 43.5264 32.0702 43.8483 31.9638C44.1703 31.8574 44.5175 32.0322 44.6238 32.3541C44.7302 32.676 44.5554 33.0232 44.2335 33.1296C43.9116 33.236 43.5644 33.0612 43.458 32.7393Z"
              stroke="#87E4F9"
              strokeWidth="0.6195"
              strokeMiterlimit="10"
            />
            <path
              d="M45.4381 33.6803C45.3443 33.3962 45.4985 33.0899 45.7825 32.996C46.0666 32.9022 46.3729 33.0564 46.4668 33.3404C46.5606 33.6245 46.4064 33.9308 46.1224 34.0247C45.8383 34.1185 45.532 33.9643 45.4381 33.6803Z"
              stroke="#87E4F9"
              strokeWidth="0.534047"
              strokeMiterlimit="10"
            />
            <path
              d="M46.61 31.6961C46.5162 31.4121 46.6704 31.1057 46.9544 31.0119C47.2385 30.918 47.5448 31.0722 47.6387 31.3563C47.7325 31.6403 47.5783 31.9467 47.2943 32.0405C47.0102 32.1344 46.7039 31.9802 46.61 31.6961Z"
              stroke="#87E4F9"
              strokeWidth="0.534047"
              strokeMiterlimit="10"
            />
            <path
              d="M48.7819 31.3607C48.6881 31.0766 48.8422 30.7703 49.1263 30.6764C49.4104 30.5826 49.7167 30.7368 49.8105 31.0208C49.9044 31.3049 49.7502 31.6112 49.4661 31.7051C49.1821 31.7989 48.8757 31.6447 48.7819 31.3607Z"
              stroke="#87E4F9"
              strokeWidth="0.534047"
              strokeMiterlimit="10"
            />
          </g>
        </g>
        <g filter="url(#filter2_d_5311_2531)">
          <mask
            id="mask1_5311_2531"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="2"
            y="5"
            width="69"
            height="75"
          >
            <path
              d="M13.6427 60.0582C13.9037 63.6032 25.9547 64.8971 27.6096 67.7894C31.8765 75.2465 40.1627 80.0043 49.3029 79.3314C61.988 78.3976 71.5143 67.3572 70.5805 54.6721C69.6466 41.9869 57.3284 4.28603 44.6433 5.21988C14.8661 4.9691 0.557272 13.3915 2.52784 22.6536C4.09021 29.997 1.21849 53.9299 13.6427 60.0582Z"
              fill="#D9D9D9"
            />
          </mask>
          <g mask="url(#mask1_5311_2531)">
            <path
              d="M56.6261 33.3176L57.8593 34.152C57.8593 34.152 62.2307 25.7526 51.8344 19.2688L56.6261 33.3176Z"
              fill="#0E80D8"
            />
            <path
              d="M92.7168 17.7323C92.7168 17.7323 76.785 11.8812 73.9864 17.9714C71.1878 24.0617 71.4647 27.6222 74.1883 31.1145C76.9119 34.6068 91.8456 62.158 78.9382 66.9818C66.0308 71.8055 58.0169 67.5363 52.1156 64.1147C46.2481 60.7055 34.2555 52.4861 31.1473 45.2017C28.039 37.9174 24.0734 24.5543 29.6616 20.8272C35.2497 17.1001 41.6632 20.5932 41.6632 20.5932C41.6632 20.5932 31.4278 13.6684 25.6687 24.2907C19.9097 34.913 27.5739 51.6244 35.5259 58.9077C43.4779 66.1911 60.9579 78.3345 75.8614 76.4685C90.7648 74.6025 97.2148 72.7274 101.386 66.5615C105.558 60.3956 109.478 59.1348 107.795 45.8359C106.112 32.537 98.5952 19.531 92.7168 17.7323Z"
              fill="#0E80D8"
            />
            <path
              d="M41.6751 20.5589C41.6751 20.5589 31.5781 13.9921 25.6806 24.2564C20.033 34.5734 27.5858 51.5901 35.5378 58.8734C43.4898 66.1568 60.9698 78.3002 75.8733 76.4342C90.7767 74.5682 95.3151 71.6113 99.4867 65.4453C99.4867 65.4453 94.2197 86.203 76.0476 86.4921C62.6299 86.7092 32.0389 76.4707 23.5294 59.7584C15.0198 43.0461 6.90544 21.0003 28.0565 12.4766C45.9057 6.30041 58.1762 24.8495 57.9315 28.6811C57.6868 32.5126 57.2238 32.7281 56.637 33.2828L54.2944 31.2749C54.282 31.3088 54.7656 26.2959 41.6751 20.5589Z"
              fill="#3DA4F3"
            />
            <path
              d="M57.854 34.1507C57.854 34.1507 55.1676 36.2458 52.793 35.3794C52.793 35.3794 55.7865 34.5496 56.6208 33.3164L57.854 34.1507Z"
              fill="#0E80D8"
            />
            <path
              d="M56.6221 33.3173C56.6221 33.3173 55.7878 34.5505 52.7943 35.3803C49.8007 36.2101 45.7344 30.4981 45.9819 29.8197C46.2295 29.1412 47.1555 28.7102 47.9257 29.76C48.6959 30.8098 50.0368 31.8756 51.8567 32.1552C53.6767 32.4349 53.9242 31.7564 54.2795 31.3094L56.6221 33.3173Z"
              fill="#49A2EA"
            />
            <path
              d="M69.3433 78.9351C69.685 77.9983 69.2027 76.9618 68.2659 76.62C67.3291 76.2782 66.2927 76.7606 65.9509 77.6974C65.6091 78.6341 66.0914 79.6706 67.0282 80.0124C67.965 80.3542 69.0015 79.8718 69.3433 78.9351Z"
              stroke="#87E4F9"
              strokeWidth="1.81412"
              strokeMiterlimit="10"
            />
            <path
              d="M60.4917 76.8596C60.8335 75.9228 60.3511 74.8864 59.4144 74.5446C58.4776 74.2028 57.4411 74.6851 57.0993 75.6219C56.7575 76.5587 57.2399 77.5952 58.1767 77.937C59.1134 78.2787 60.1499 77.7964 60.4917 76.8596Z"
              stroke="#87E4F9"
              strokeWidth="1.81412"
              strokeMiterlimit="10"
            />
            <path
              d="M52.8667 79.8438C53.2085 78.907 52.7261 77.8705 51.7894 77.5287C50.8526 77.1869 49.8161 77.6693 49.4743 78.6061C49.1325 79.5428 49.6149 80.5793 50.5517 80.9211C51.4884 81.2629 52.5249 80.7805 52.8667 79.8438Z"
              stroke="#87E4F9"
              strokeWidth="1.81412"
              strokeMiterlimit="10"
            />
            <path
              d="M52.9683 72.1921C53.31 71.2554 52.8277 70.2189 51.8909 69.8771C50.9541 69.5353 49.9177 70.0177 49.5759 70.9544C49.2341 71.8912 49.7164 72.9277 50.6532 73.2695C51.59 73.6113 52.6265 73.1289 52.9683 72.1921Z"
              stroke="#87E4F9"
              strokeWidth="1.81412"
              strokeMiterlimit="10"
            />
            <path
              d="M45.9019 75.7637C46.2436 74.8269 45.7613 73.7904 44.8245 73.4486C43.8877 73.1069 42.8513 73.5892 42.5095 74.526C42.1677 75.4628 42.65 76.4992 43.5868 76.841C44.5236 77.1828 45.5601 76.7005 45.9019 75.7637Z"
              stroke="#87E4F9"
              strokeWidth="1.81412"
              strokeMiterlimit="10"
            />
            <path
              d="M45.2026 68.2039C45.5444 67.2671 45.0621 66.2306 44.1253 65.8888C43.1885 65.547 42.152 66.0294 41.8103 66.9662C41.4685 67.9029 41.9508 68.9394 42.8876 69.2812C43.8244 69.623 44.8609 69.1406 45.2026 68.2039Z"
              stroke="#87E4F9"
              strokeWidth="1.81412"
              strokeMiterlimit="10"
            />
            <path
              d="M38.4995 70.7581C38.8413 69.8213 38.3589 68.7848 37.4222 68.443C36.4854 68.1012 35.4489 68.5836 35.1071 69.5204C34.7653 70.4571 35.2477 71.4936 36.1845 71.8354C37.1213 72.1772 38.1577 71.6948 38.4995 70.7581Z"
              stroke="#87E4F9"
              strokeWidth="1.81412"
              strokeMiterlimit="10"
            />
            <path
              d="M39.6245 63.479C39.9663 62.5422 39.4839 61.5058 38.5472 61.164C37.6104 60.8222 36.5739 61.3045 36.2321 62.2413C35.8903 63.1781 36.3727 64.2146 37.3095 64.5563C38.2463 64.8981 39.2827 64.4158 39.6245 63.479Z"
              stroke="#87E4F9"
              strokeWidth="1.81412"
              strokeMiterlimit="10"
            />
            <path
              d="M32.4956 65.1047C32.8374 64.168 32.355 63.1315 31.4183 62.7897C30.4815 62.4479 29.445 62.9303 29.1032 63.867C28.7614 64.8038 29.2438 65.8403 30.1806 66.1821C31.1173 66.5239 32.1538 66.0415 32.4956 65.1047Z"
              stroke="#87E4F9"
              strokeWidth="1.81412"
              strokeMiterlimit="10"
            />
            <path
              d="M33.2937 57.7482C33.6013 56.9051 33.1672 55.9723 32.3241 55.6647C31.481 55.3571 30.5482 55.7912 30.2406 56.6343C29.933 57.4774 30.3671 58.4103 31.2102 58.7179C32.0533 59.0255 32.9861 58.5913 33.2937 57.7482Z"
              stroke="#87E4F9"
              strokeWidth="1.64992"
              strokeMiterlimit="10"
            />
            <path
              d="M26.6053 58.2691C26.8719 57.5384 26.4957 56.7299 25.765 56.4634C25.0343 56.1968 24.2259 56.573 23.9593 57.3037C23.6927 58.0344 24.0689 58.8428 24.7996 59.1094C25.5303 59.376 26.3387 58.9998 26.6053 58.2691Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M29.1092 52.5706C29.3758 51.8399 28.9996 51.0315 28.2689 50.7649C27.5382 50.4983 26.7298 50.8745 26.4632 51.6052C26.1966 52.3359 26.5728 53.1443 27.3035 53.4109C28.0342 53.6775 28.8426 53.3013 29.1092 52.5706Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M22.8475 51.8223C23.1141 51.0916 22.7379 50.2832 22.0072 50.0166C21.2765 49.75 20.468 50.1262 20.2015 50.8569C19.9349 51.5876 20.3111 52.396 21.0418 52.6626C21.7725 52.9292 22.5809 52.553 22.8475 51.8223Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M25.8124 45.9087C26.0789 45.178 25.7027 44.3696 24.972 44.103C24.2413 43.8364 23.4329 44.2126 23.1663 44.9433C22.8997 45.674 23.2759 46.4825 24.0066 46.7491C24.7373 47.0156 25.5458 46.6394 25.8124 45.9087Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M18.9374 42.6309C19.2039 41.9002 18.8277 41.0918 18.097 40.8252C17.3663 40.5586 16.5579 40.9348 16.2913 41.6655C16.0247 42.3962 16.4009 43.2046 17.1316 43.4712C17.8623 43.7378 18.6708 43.3616 18.9374 42.6309Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M22.9491 37.9456C23.2157 37.2149 22.8394 36.4065 22.1087 36.1399C21.3781 35.8733 20.5696 36.2495 20.303 36.9802C20.0364 37.7109 20.4127 38.5193 21.1433 38.7859C21.874 39.0525 22.6825 38.6763 22.9491 37.9456Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M17.8358 33.0039C18.1024 32.2733 17.7262 31.4648 16.9955 31.1982C16.2648 30.9316 15.4563 31.3079 15.1897 32.0385C14.9231 32.7692 15.2994 33.5777 16.0301 33.8443C16.7607 34.1109 17.5692 33.7346 17.8358 33.0039Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M23.578 30.2176C23.8446 29.4869 23.4683 28.6784 22.7377 28.4118C22.007 28.1453 21.1985 28.5215 20.9319 29.2522C20.6653 29.9829 21.0416 30.7913 21.7722 31.0579C22.5029 31.3245 23.3114 30.9483 23.578 30.2176Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M19.7303 24.9693C19.9969 24.2386 19.6207 23.4301 18.89 23.1636C18.1593 22.897 17.3509 23.2732 17.0843 24.0039C16.8177 24.7346 17.1939 25.543 17.9246 25.8096C18.6553 26.0762 19.4637 25.7 19.7303 24.9693Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M25.3788 23.1861C25.6453 22.4554 25.2691 21.6469 24.5384 21.3804C23.8077 21.1138 22.9993 21.49 22.7327 22.2207C22.4661 22.9514 22.8423 23.7598 23.573 24.0264C24.3037 24.293 25.1122 23.9168 25.3788 23.1861Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M25.4491 16.6763C25.7157 15.9456 25.3394 15.1372 24.6087 14.8706C23.8781 14.604 23.0696 14.9802 22.803 15.7109C22.5364 16.4416 22.9127 17.25 23.6433 17.5166C24.374 17.7832 25.1825 17.407 25.4491 16.6763Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M32.2928 17.4061C32.5594 16.6754 32.1832 15.8669 31.4525 15.6003C30.7218 15.3337 29.9134 15.71 29.6468 16.4406C29.3802 17.1713 29.7564 17.9798 30.4871 18.2464C31.2178 18.513 32.0262 18.1367 32.2928 17.4061Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M37.078 13.7691C37.3446 13.0384 36.9683 12.2299 36.2377 11.9634C35.507 11.6968 34.6985 12.073 34.4319 12.8037C34.1653 13.5344 34.5416 14.3428 35.2722 14.6094C36.0029 14.876 36.8114 14.4998 37.078 13.7691Z"
              stroke="#87E4F9"
              strokeWidth="1.40616"
              strokeMiterlimit="10"
            />
            <path
              d="M39.7643 18.2091C39.9762 17.6283 39.6772 16.9857 39.0964 16.7738C38.5156 16.5619 37.8729 16.861 37.661 17.4418C37.4491 18.0226 37.7482 18.6652 38.329 18.8771C38.9098 19.089 39.5524 18.7899 39.7643 18.2091Z"
              stroke="#87E4F9"
              strokeWidth="1.13845"
              strokeMiterlimit="10"
            />
            <path
              d="M44.944 16.6408C45.1559 16.06 44.8568 15.4174 44.276 15.2055C43.6952 14.9936 43.0526 15.2926 42.8407 15.8734C42.6288 16.4542 42.9279 17.0968 43.5087 17.3087C44.0895 17.5206 44.7321 17.2216 44.944 16.6408Z"
              stroke="#87E4F9"
              strokeWidth="1.13845"
              strokeMiterlimit="10"
            />
            <path
              d="M46.6393 21.487C46.8512 20.9062 46.5522 20.2636 45.9714 20.0516C45.3906 19.8397 44.7479 20.1388 44.536 20.7196C44.3241 21.3004 44.6232 21.943 45.204 22.1549C45.7848 22.3668 46.4274 22.0678 46.6393 21.487Z"
              stroke="#87E4F9"
              strokeWidth="1.13845"
              strokeMiterlimit="10"
            />
            <path
              d="M51.9127 20.7209C52.1246 20.1401 51.8256 19.4974 51.2448 19.2855C50.664 19.0736 50.0214 19.3727 49.8095 19.9535C49.5976 20.5343 49.8966 21.1769 50.4774 21.3888C51.0582 21.6007 51.7008 21.3017 51.9127 20.7209Z"
              stroke="#87E4F9"
              strokeWidth="1.13845"
              strokeMiterlimit="10"
            />
            <path
              d="M52.9765 25.7208C53.1542 25.2337 52.9034 24.6947 52.4162 24.517C51.9291 24.3393 51.3902 24.5901 51.2124 25.0772C51.0347 25.5643 51.2855 26.1033 51.7726 26.281C52.2598 26.4588 52.7987 26.2079 52.9765 25.7208Z"
              stroke="#87E4F9"
              strokeWidth="0.957041"
              strokeMiterlimit="10"
            />
            <path
              d="M57.6991 27.8292C57.8768 27.3421 57.626 26.8031 57.1389 26.6254C56.6518 26.4477 56.1128 26.6985 55.9351 27.1856C55.7574 27.6727 56.0082 28.2117 56.4953 28.3894C56.9824 28.5672 57.5214 28.3163 57.6991 27.8292Z"
              stroke="#87E4F9"
              strokeWidth="0.957041"
              strokeMiterlimit="10"
            />
            <path
              d="M56.0876 31.0855C56.2585 30.6171 56.0174 30.0988 55.549 29.9279C55.0806 29.757 54.5623 29.9982 54.3915 30.4666C54.2206 30.935 54.4617 31.4532 54.9301 31.6241C55.3985 31.795 55.9168 31.5538 56.0876 31.0855Z"
              stroke="#87E4F9"
              strokeWidth="0.898728"
              strokeMiterlimit="10"
            />
            <path
              d="M55.188 34.4073C55.3042 34.0888 55.1402 33.7364 54.8217 33.6202C54.5032 33.504 54.1508 33.668 54.0346 33.9865C53.9184 34.305 54.0824 34.6574 54.4009 34.7736C54.7194 34.8898 55.0718 34.7258 55.188 34.4073Z"
              stroke="#87E4F9"
              strokeWidth="0.619499"
              strokeMiterlimit="10"
            />
            <path
              d="M53.0904 32.9891C53.2066 32.6706 53.0426 32.3182 52.7241 32.202C52.4056 32.0858 52.0532 32.2498 51.937 32.5683C51.8208 32.8868 51.9848 33.2392 52.3033 33.3554C52.6218 33.4716 52.9742 33.3076 53.0904 32.9891Z"
              stroke="#87E4F9"
              strokeWidth="0.619499"
              strokeMiterlimit="10"
            />
            <path
              d="M51.0745 33.869C51.177 33.588 51.0323 33.2771 50.7513 33.1745C50.4702 33.072 50.1593 33.2167 50.0567 33.4977C49.9542 33.7788 50.0989 34.0897 50.38 34.1922C50.661 34.2948 50.9719 34.1501 51.0745 33.869Z"
              stroke="#87E4F9"
              strokeWidth="0.534047"
              strokeMiterlimit="10"
            />
            <path
              d="M49.969 31.8498C50.0715 31.5687 49.9268 31.2578 49.6458 31.1552C49.3648 31.0527 49.0538 31.1974 48.9513 31.4784C48.8487 31.7595 48.9934 32.0704 49.2745 32.173C49.5555 32.2755 49.8665 32.1308 49.969 31.8498Z"
              stroke="#87E4F9"
              strokeWidth="0.534047"
              strokeMiterlimit="10"
            />
            <path
              d="M47.8088 31.4477C47.9114 31.1666 47.7667 30.8557 47.4856 30.7531C47.2046 30.6506 46.8937 30.7953 46.7911 31.0763C46.6886 31.3574 46.8333 31.6683 47.1143 31.7709C47.3954 31.8734 47.7063 31.7287 47.8088 31.4477Z"
              stroke="#87E4F9"
              strokeWidth="0.534047"
              strokeMiterlimit="10"
            />
          </g>
        </g>
        <g filter="url(#filter3_i_5311_2531)">
          <path
            d="M76.5016 52.2001C73.5016 58.5 71.1016 62.1001 70.0513 63.9001C66.8521 72.8198 58.3218 79.2001 48.3013 79.2001C40.0644 79.2001 32.8345 74.889 28.7447 68.4001C28.2136 67.5574 27.4836 66.4597 26.6785 65.215C21.519 57.2379 17.6535 48.006 15.3886 38.7797C14.4534 34.9702 14.2959 31.4984 15.9011 29.7001C20.1315 24.9607 41.4486 33.0001 48.3013 33.0001C55.1539 33.0001 75.8712 23.7607 80.1016 28.5001C83.747 32.5841 79.3816 45 76.5016 52.2001Z"
            fill="url(#paint0_linear_5311_2531)"
            fillOpacity="0.4"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_5311_2531"
          x="25.1797"
          y="31.6495"
          width="46.0664"
          height="47.6533"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1.58832" />
          <feGaussianBlur stdDeviation="1.58832" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_5311_2531" />
        </filter>
        <filter
          id="filter1_d_5311_2531"
          x="25.1836"
          y="4.09255"
          width="64.5365"
          height="75.2102"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4.2" dy="-4.182" />
          <feGaussianBlur stdDeviation="1.596" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5311_2531" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5311_2531" result="shape" />
        </filter>
        <filter
          id="filter2_d_5311_2531"
          x="7.01034"
          y="3.80032"
          width="63.6342"
          height="75.5942"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4.2" dy="-4.182" />
          <feGaussianBlur stdDeviation="1.596" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5311_2531" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5311_2531" result="shape" />
        </filter>
        <filter
          id="filter3_i_5311_2531"
          x="14.6875"
          y="25.5594"
          width="66.8008"
          height="53.6408"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1.58832" />
          <feGaussianBlur stdDeviation="1.58832" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_5311_2531" />
        </filter>
        <linearGradient
          id="paint0_linear_5311_2531"
          x1="47.7016"
          y1="30.9001"
          x2="48.0016"
          y2="79.8001"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.39" stopColor="#00FFA3" stopOpacity="0" />
          <stop offset="1" stopColor="#003F28" />
        </linearGradient>
        <clipPath id="clip0_5311_2531">
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
