import React from "react";
import { NavLink } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link, Stack, Typography } from "@mui/material";
import { themeTokens } from "components/Theme/theme";

export function BackToDashboard() {
  return (
    <Link component={NavLink} underline={"none"} to={"/"} color={themeTokens.color.text.secondary}>
      <Stack direction="row" alignItems="center">
        <ChevronLeftIcon fontSize={"small"} sx={{ paddingRight: "4px", textAlign: "left" }} />
        <Typography variant="overline" fontSize={"0.875rem"} letterSpacing={"0.05rem"} paddingTop={"2px"}>
          Dashboard
        </Typography>
      </Stack>
    </Link>
  );
}
