import React from "react";
import { Box, CircularProgress, Stack, styled, Typography } from "@mui/material";
import { formatMoney } from "utils/formatters/MoneyFormatter";
import type { OrderPreview, OrderPreviewTaxItem } from "client/api/PurchaseApi";
import { TaxRateType } from "client/api/PurchaseApi";
import { themeTokens } from "components/Theme/theme";
import { ChevronDownIcon } from "areas/checkout/components/ChevronDownIcon";
import type { OrderPreviewError } from "areas/purchasing/Checkout";
import { SummaryLine } from "./SummaryLine";

const StyledSummary = styled("summary")(
  ({ theme }) => `
    list-style: none;
    cursor: pointer;
  `
);

const StyledArrowIcon = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const StyledDetails = styled("details")`
  &[open] .faq-arrow {
    transform: rotate(180deg);
  }
`;

const ArrowIcon = () => (
  <StyledArrowIcon className="faq-arrow">
    <ChevronDownIcon />
  </StyledArrowIcon>
);

interface TaxProps {
  taxItem: OrderPreviewTaxItem;
}

const TaxItemLine = (props: TaxProps) => {
  const { taxItem } = props;

  let rateString = "";
  if (taxItem.rateType === TaxRateType.Percentage) {
    rateString = `${taxItem.rate * 100}%`;
  }

  return (
    <Stack direction={"row"} justifyContent={"space-between"} marginTop={1}>
      <Typography
        variant={"body1"}
        fontSize={12}
        sx={{ marginRight: 3, marginLeft: 2, lineHeight: 1.2, "&&": { marginBottom: 0 } }}
        color={themeTokens.color.text.secondary}
      >
        {taxItem.name} &ndash; {rateString}
      </Typography>
      <Typography
        variant={"body1"}
        color={themeTokens.color.text.secondary}
        fontSize={12}
        sx={{ marginLeft: 1, lineHeight: 1.2, "&&": { marginBottom: 0 } }}
      >
        {formatMoney(taxItem.amount, { includeTrailingZeros: true })}
      </Typography>
    </Stack>
  );
};

export const TaxSummary = ({
  isFetchingTax,
  hasMinimumTaxInfo,
  errorReason,
  taxItems,
}: {
  isFetchingTax: boolean;
  hasMinimumTaxInfo: boolean;
  errorReason: OrderPreviewError | null;
  taxItems: OrderPreview["taxItems"];
}) => {
  const taxTotalTestId = "order-summary-tax-total-amount";
  // minimum required tax info missing
  if (!hasMinimumTaxInfo) {
    return <SummaryLine label={"Tax"} value={"$--.--"} valueTestId={taxTotalTestId} />;
  }

  // loading
  if (isFetchingTax) {
    return (
      <Typography>
        <CircularProgress size={16} /> Calculating tax...
      </Typography>
    );
  }

  // error states
  if (errorReason) {
    if (errorReason.statusCode === 400) {
      return (
        <Typography color={themeTokens.color.text.danger} fontSize={12}>
          Tax couldn't be calculated. Please review the country and address you've entered.
        </Typography>
      );
    }
    return (
      <Typography color={themeTokens.color.text.danger} fontSize={12}>
        Tax couldn't be calculated.
      </Typography>
    );
  }

  // no tax
  if (taxItems.length === 0) {
    return <></>;
  }

  // summarize tax items
  const taxSum = taxItems.reduce((acc, val) => acc + val.amount, 0);
  return (
    <StyledDetails sx={{ "&&": { marginBottom: 1 } }}>
      <StyledSummary>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <div>
            <Typography style={{ display: "inline" }} variant={"body1"} color={themeTokens.color.text.primary}>
              Tax
            </Typography>
            <Box style={{ display: "inline-flex", position: "relative", top: "2px", left: "6px" }}>
              <ArrowIcon />
            </Box>
          </div>
          <Typography variant={"body1"} color={themeTokens.color.text.primary} data-testid={taxTotalTestId}>
            {formatMoney(taxSum, { includeTrailingZeros: true })}
          </Typography>
        </Stack>
      </StyledSummary>

      {taxItems.map((item, a) => {
        return <TaxItemLine taxItem={item} key={a} />;
      })}
    </StyledDetails>
  );
};
