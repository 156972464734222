import React from "react";
import { styled } from "@mui/material";

const Container = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
}));

const Overlay = styled("div")<{ scrollbarHeight?: number }>(({ scrollbarHeight }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  height: scrollbarHeight ? `calc(100% - ${scrollbarHeight}px)` : "100%",
  transition: ".5s ease",
  opacity: 0,
  background: "rgba(0, 0, 0, 0.5)",
  "&:hover": {
    opacity: 1,
  },
}));

const IconContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  marginLeft: "auto",
  top: 0,
  right: 0,
  padding: 8,
  color: theme.palette.text.primary,
  opacity: 1,
}));

const HoverTextContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  margin: "auto",
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  color: theme.palette.text.primary,
}));

const HoverText = styled("h2")(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

type HoverOverlayProps = React.PropsWithChildren<{
  onClick?: () => void;
  hoverText?: string;
  hoverIcon?: React.ReactNode;
  disabled?: boolean;
  scrollbarHeight?: number;
}>;

function HoverOverlay(props: HoverOverlayProps) {
  const { disabled, children, onClick, hoverIcon, hoverText, scrollbarHeight } = props;
  if (disabled) {
    return <Container>{children}</Container>;
  }

  return (
    <Container onClick={onClick}>
      {children}
      <Overlay scrollbarHeight={scrollbarHeight}>
        <HoverTextContainer>
          <HoverText>{hoverText}</HoverText>
        </HoverTextContainer>
      </Overlay>
      <IconContainer>{hoverIcon}</IconContainer>
    </Container>
  );
}

export default HoverOverlay;
