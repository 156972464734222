import React from "react";
import type { SxProps, Theme } from "@mui/material";
import { SvgIcon } from "@mui/material";

export function CheckIcon({ sx, fillColor = "#fff" }: { sx?: SxProps<Theme>; fillColor?: string }) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 13"
      fill="none"
      sx={{
        width: "17px",
        height: "13px",
        ...sx,
      }}
    >
      <path
        d="M1.11448 5.83988L1.11448 5.83988C0.761843 6.1867 0.761835 6.75003 1.11449 7.09683L5.98949 11.8909C6.34074 12.2364 6.90922 12.2364 7.2605 11.8909L7.26051 11.8909L15.8855 3.40908L15.7803 3.30213L15.8855 3.40907C16.2382 3.06226 16.2382 2.49892 15.8855 2.15212L14.8249 1.10907C14.4736 0.763645 13.9051 0.763645 13.5539 1.10906L6.625 7.92288L3.44614 4.79682C3.09489 4.45137 2.52637 4.45137 2.17512 4.79682C2.17512 4.79682 2.17512 4.79682 2.17512 4.79682L1.11448 5.83988Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.3"
      />
    </SvgIcon>
  );
}
