import React from "react";
import { Chip, styled } from "@mui/material";

type PricingCardCurrentPlanChipProps = {
  isCurrentPlan: boolean;
};

const PlanChip = styled(Chip)`
  position: absolute;
  top: 14px;
  right: 11px;
`;

export function PricingCardCurrentPlanChip({ isCurrentPlan }: PricingCardCurrentPlanChipProps) {
  if (isCurrentPlan) {
    return <PlanChip label="Current Plan" color="primary" />;
  }
  return null;
}
