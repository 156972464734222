import React from "react";
import type { PropsWithChildren } from "react";
import cn from "classnames";
import styles from "./style.module.less";

export enum SectionHeadingType {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
}

type SectionProps = PropsWithChildren<{
  className?: string;
  sectionHeader?: string;
  headingType?: SectionHeadingType;
  bodyClassName?: string;
}>;

export function Section(props: SectionProps) {
  const renderSectionHeading = () => {
    if (!props.sectionHeader) {
      return null;
    }
    switch (props.headingType) {
      case SectionHeadingType.Heading1:
        return <h1 className={styles.sectionHeader}>{props.sectionHeader}</h1>;
      case SectionHeadingType.Heading2:
        return <h2 className={styles.sectionHeader}>{props.sectionHeader}</h2>;
      case SectionHeadingType.Heading3:
        return <h3 className={styles.sectionHeader}>{props.sectionHeader}</h3>;
      default:
        return <h4 className={styles.sectionHeader}>{props.sectionHeader}</h4>;
    }
  };
  return (
    <section className={cn(props.className, props.sectionHeader ? styles.section : styles.sectionNoHeader)}>
      {renderSectionHeading()}
      <div className={cn(styles.sectionBody, props.bodyClassName)}>{props.children}</div>
    </section>
  );
}
