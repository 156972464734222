export const formatMoney = (
  number: number,
  options?: { removeCurrency?: boolean; includeTrailingZeros?: boolean }
): string => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const decPlaces = number % 1 !== 0 ? 2 : 0;
  let result = "";

  if (options?.removeCurrency) {
    result = formatter
      .formatToParts(number)
      .filter((item) => item.type !== "currency")
      .map(({ value }) => value)
      .join("");
  } else {
    result = formatter.format(number);
  }

  return decPlaces == 2 ? result : options?.includeTrailingZeros ? result : result.slice(0, -3);
};
