import type { ListEntitiesResponse } from "client/api/common/ListEntitiesResponse";
import axiosClient from "../axios-client";

export interface ListUserGrantsDto<T extends UserGrantDto> {
  directUserGrants: Array<T>;
}

export interface UserGrantDto {
  id: string;
  role: RoleDto;
  user: UserDto;
}

export interface CloudSubscriptionOrSubscriptionGroupUserGrantDto extends UserGrantDto {
  grantType: string;
}

export interface CloudSubscriptionUserGrantDto extends UserGrantDto {
  cloudSubscriptionId: string;
  cloudSubscriptionName: string;
}

export interface SubscriptionGroupUserGrantDto extends UserGrantDto {
  subscriptionGroupId: string;
  subscriptionGroupName: string;
}

export interface ListRolesDto {
  roles: RoleDto[];
}

export interface RoleDto {
  id: string;
  name: string;
  description: string;
}

export interface UserDto {
  id: string;
  firstName: string;
  lastName?: string;
  email: string;
}

export interface UserGrantInviteDto {
  id: string;
  role: RoleDto;
  email: string;
}

export class BaseUserGrantsApi<TUserGrant extends UserGrantDto> {
  constructor(private assetUrl: string) {}

  public async listUserGrants(assetId: string | undefined): Promise<ListUserGrantsDto<TUserGrant>> {
    const response = await axiosClient.get<ListUserGrantsDto<TUserGrant>>(this.buildUrl(assetId || ""));
    return response.data;
  }

  public async deleteUserGrant(assetId: string, userGrantId: string): Promise<void> {
    await axiosClient.delete(`${this.buildUrl(assetId)}/${userGrantId}`);
  }

  public async acceptUserGrantInvite(encodedInvite: string): Promise<TUserGrant> {
    const response = await axiosClient.get<TUserGrant>(`${this.assetUrl}/invite/accept/${encodedInvite}`);
    return response.data;
  }

  public async getInvites(assetId: string | undefined): Promise<ListEntitiesResponse<UserGrantInviteDto>> {
    const response = await axiosClient.get<ListEntitiesResponse<UserGrantInviteDto>>(
      `${this.buildInvitesUrl(assetId ?? "")}`
    );
    return response.data;
  }

  public async revokeInvite(assetId: string, inviteId: string): Promise<void> {
    await axiosClient.delete(`${this.buildInvitesUrl(assetId)}/${inviteId}`);
  }

  private buildUrl(assetId: string): string {
    return `${this.assetUrl}/${assetId}/user-grants`;
  }

  private buildInvitesUrl(assetId: string): string {
    return `${this.assetUrl}/${assetId}/invites`;
  }
}

export const cloudSubscriptionUserGrantsApi = new BaseUserGrantsApi<CloudSubscriptionOrSubscriptionGroupUserGrantDto>(
  "cloud-subscriptions"
);

export const subscriptionGroupUserGrantsApi = new BaseUserGrantsApi<CloudSubscriptionOrSubscriptionGroupUserGrantDto>(
  "subscription-groups"
);
