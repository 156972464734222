import React from "react";
import type { LinkProps } from "react-router-dom";
import { Link } from "react-router-dom";
import { styled } from "@mui/material";
import { themeTokens } from "components/Theme/theme";

const StyledLink = styled(Link)(
  ({ fontSize, fontWeight, color }: RouterLinkProps) => `
  font-size: ${fontSize ?? "inherit"};
  font-weight: ${fontWeight ?? 500};
  color: ${color ?? themeTokens.color.text.link.default};
  &:hover {
    color: ${themeTokens.color.text.link.hover};
  }
`
);

type RouterLinkProps = {
  fontSize?: string;
  fontWeight?: number | string;
} & LinkProps;

/**
 * A react router based link
 * @param props
 */
export const RouterLink = (props: RouterLinkProps) => {
  return <StyledLink {...props}>{props.children}</StyledLink>;
};
