import React, { useContext } from "react";
import { PopoverContext } from "./PopoverHelp";

export function PopoverCloseButton({ children }: { children: React.ReactNode }) {
  const { closePopover } = useContext(PopoverContext);
  const handleClick = () => {
    closePopover();
  };

  function isValidEl(child: {} | null | undefined): child is React.ReactElement {
    return React.isValidElement(child);
  }

  const enhancedChildren = React.Children.map(children, (child) => {
    const el = isValidEl(child);
    if (el) {
      const existingClickHandler = child.props.onClick;
      return React.cloneElement(child, {
        onClick: () => {
          if (typeof existingClickHandler === "function") {
            existingClickHandler();
          }
          handleClick();
        },
      });
    }
  });

  return <>{enhancedChildren}</>;
}
