import React from "react";
import { Grid } from "@mui/material";
import { themeTokens } from "components/Theme/theme";

export function GridDivider() {
  return (
    <Grid
      item
      xs={12}
      sx={{
        "--Grid-borderWidth": "1px",
        borderBottom: "var(--Grid-borderWidth) solid",
        borderColor: themeTokens.color.border.secondary,
      }}
    />
  );
}
