import React, { useMemo, useState } from "react";
import { TextField } from "@mui/material";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { Settings, DateTime } from "luxon";
import { Controller, useFormContext } from "react-hook-form";
import type { UseControllerProps } from "react-hook-form/dist/types/controller";
import { getShortTimeZoneName } from "@cpt/shared";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface FormTimePickerProps extends UseControllerProps<any> {
  label: string;
}

export function FormTimePicker(props: FormTimePickerProps) {
  const { setValue, control, trigger } = useFormContext();

  const [time, setTime] = useState<DateTime | null>(props.defaultValue ?? null);

  const localTimeZoneName = useMemo(() => {
    return getShortTimeZoneName(props.defaultValue, { locale: Settings.defaultLocale });
  }, [props.defaultValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={Settings.defaultLocale}>
      <Controller
        name={props.name}
        rules={props.rules}
        shouldUnregister={props.shouldUnregister}
        defaultValue={props.defaultValue}
        control={control}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <TimePicker
            {...rest}
            onChange={(value) => {
              setTime(value);
              setValue(props.name, value);
              trigger(props.name);
            }}
            value={time}
            label={`${props.label} (${localTimeZoneName})`}
            renderInput={(params) => {
              let helperText = null;

              if (time && time.isValid) {
                helperText = `${time.toUTC().toLocaleString(DateTime.TIME_SIMPLE)} (UTC)`;
              }

              if (fieldState.invalid) {
                helperText = fieldState.error?.message;
              }
              return (
                <TextField
                  {...params}
                  variant="outlined"
                  required={!!props.rules?.required}
                  error={fieldState.invalid ?? params.error}
                  helperText={helperText}
                  fullWidth
                />
              );
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
