import React from "react";
import { Chip, CircularProgress, styled } from "@mui/material";
import { designSystemColors } from "@cpt/shared";

const StyledCircularProgress = styled(CircularProgress)`
  &.MuiChip-icon {
    color: ${designSystemColors.blue["500"]};
  }
`;

export function FulfillmentProgressChip() {
  return (
    <Chip
      icon={<StyledCircularProgress size="12.5px" />}
      size={"small"}
      label={"Fulfillment in progress"}
      sx={{
        fontSize: "12px",
        alignSelf: "center",
        justifyContent: "end",
        backgroundColor: "#FFFFFF",
        color: "#282F38",
        border: "1px solid #DEE1E6",
      }}
    />
  );
}
