import React from "react";
import type { SxProps, Theme } from "@mui/material";
import { alpha, Chip } from "@mui/material";
import { designSystemColors } from "@cpt/shared/Theme/designSystemColors";
import { isTemporary } from "utils/isTemporary";
import { isTrial } from "utils/isTrial";
import type { CloudLicensingChannelTypes } from "client/api/CloudSubscriptionApi";
import type { ServerLicensingChannelTypes } from "client/api/ServerLicenseApi";

type LicenseChannelChipProps = {
  channelType: LicensingChannelTypes;
  sx?: SxProps<Theme>;
};

type LicensingChannelTypes = CloudLicensingChannelTypes | ServerLicensingChannelTypes;

function ChannelChipLabel({ channelType }: { channelType: LicensingChannelTypes }) {
  if (isTrial(channelType)) {
    return "Trial";
  }
  if (isTemporary(channelType)) {
    return "Temporary";
  }
  return null;
}

export function LicenseChannelChip(props: LicenseChannelChipProps) {
  const { channelType, sx } = props;

  if (!isTrial(channelType) && !isTemporary(channelType)) {
    return <></>;
  }

  const label = ChannelChipLabel({ channelType });

  return (
    <Chip
      sx={{
        cursor: "inherit",
        backgroundColor: alpha(designSystemColors.blue["300"], 0.16),
        color: designSystemColors.blue["500"],
        fontWeight: 700,
        ...sx,
      }}
      color={"info"}
      size={"small"}
      label={label}
    />
  );
}
