import React from "react";
import { SvgIcon } from "@mui/material";

interface AsteriskIconProps {
  error?: boolean;
  ariaLabel?: string;
}

export function AsteriskIcon(props: AsteriskIconProps) {
  const { error, ariaLabel } = props;
  const fillColor = error ? "#C95340" : "#7C98B4";

  return (
    <SvgIcon
      viewBox="0 0 9 10"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: "12.67px",
        height: "13.82px;",
      }}
      fill="none"
      aria-label={ariaLabel}
    >
      <path
        d="M8.6315 6.31188L5.94478 4.8365L8.6315 3.36112C8.85433 3.23875 8.93304 2.95712 8.80591 2.73697L8.43789 2.09951C8.31078 1.87936 8.02754 1.8067 7.81014 1.93849L5.18908 3.52758L5.25473 0.463133C5.26017 0.208971 5.05564 0 4.80141 0H4.06535C3.81113 0 3.60658 0.208971 3.61204 0.463133L3.67768 3.52758L1.05661 1.93851C0.839237 1.80672 0.555981 1.87938 0.428872 2.09953L0.0608443 2.73699C-0.0662649 2.95714 0.0124415 3.23877 0.235279 3.36114L2.92197 4.8365L0.235261 6.31188C0.0124227 6.43424 -0.0662838 6.71587 0.0608443 6.93603L0.428872 7.57348C0.555981 7.79364 0.839237 7.8663 1.05661 7.73451L3.67768 6.14541L3.61202 9.20986C3.60658 9.46403 3.81113 9.673 4.06535 9.673H4.80142C5.05564 9.673 5.26019 9.46403 5.25475 9.20986L5.18908 6.14541L7.81014 7.73449C8.02752 7.86628 8.31078 7.79362 8.43789 7.57346L8.80591 6.93601C8.93302 6.71586 8.85433 6.43424 8.6315 6.31188Z"
        fill={fillColor}
      />
    </SvgIcon>
  );
}
