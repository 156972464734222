import React from "react";
import { faServer } from "@fortawesome/free-solid-svg-icons";
import { Box, Stack, Typography } from "@mui/material";
import { FontAwesomeSvgIcon } from "@cpt/components";
import { themeTokens } from "components/Theme/theme";

type ServerLicenseHeaderProps = {
  pageTitle: string;
};

export function ServerLicenseHeader(props: ServerLicenseHeaderProps) {
  const { pageTitle } = props;

  return (
    <Box>
      <Stack direction="row" spacing={1} padding={2} alignItems={"center"}>
        <FontAwesomeSvgIcon icon={faServer} sx={{ color: themeTokens.color.text.tertiary }}></FontAwesomeSvgIcon>
        <Typography variant={"h5"} color={themeTokens.color.text.primary} fontWeight={600}>
          {pageTitle}
        </Typography>
      </Stack>
    </Box>
  );
}
