import React from "react";
import { Alert } from "@mui/material";
import type { ContactSalesReason } from "components/Dialogs/ContactSalesDialog/ContactSalesReasons";

export function ContactSalesSuccessAlert(props: { reason?: ContactSalesReason }) {
  const { reason } = props;

  const getReasonText = (reason: ContactSalesReason): string => {
    switch (reason) {
      case "server-license-renewal":
        return "license renewal request";
      case "enterprise":
        return "enterprise inquiry";
      case "usage-estimation":
        return "usage estimation inquiry";
      case "volume-pricing":
        return "volume pricing inquiry";
      default:
        return "inquiry";
    }
  };

  if (!reason) {
    return null;
  }

  return (
    <Alert severity={"success"}>
      {/* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */}
      The Sales team received your {getReasonText(reason)}. They will be in touch in 1&ndash;3 business days.
    </Alert>
  );
}
