import { useRef, useState } from "react";
import type { AxiosError } from "axios";
import Environment from "environment";
import { throttle } from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";

export type AsyncRequestFunction<T, R extends unknown[]> = (...params: R) => Promise<T>;

export interface AsyncResponse<T> {
  loading: boolean;
  refresh: () => void;
  data?: T;
  error?: AxiosError;
}

function useAsyncRequest<T, R extends unknown[]>(func: AsyncRequestFunction<T, R>, ...params: R): AsyncResponse<T> {
  const [data, setData] = useState<T | undefined>(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  // throttles api calls e.g. only 1 api call will be made within the set delay
  const throttled = useRef(
    throttle((isCleaningUp, params) => {
      setLoading(true);
      setError(undefined);
      func(...params)
        .then((result) => {
          if (!isCleaningUp) {
            setData(result);
          }
        })
        .catch((ex) => {
          if (!isCleaningUp) {
            setError(ex);
          }
        })
        .finally(() => {
          if (!isCleaningUp) {
            setLoading(false);
          }
        });
    }, Environment.ApiThrottleDelayMs())
  );

  useDeepCompareEffect(() => {
    let isCleaningUp = false;
    throttled.current(isCleaningUp, params);

    return () => {
      isCleaningUp = true;
    };
  }, [func, params, shouldRefresh]);

  return {
    data,
    refresh: () => setShouldRefresh(!shouldRefresh),
    loading,
    error,
  };
}

export default useAsyncRequest;
