/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type {
  CloudSubscriptionUserGrantDto,
  SubscriptionGroupUserGrantDto,
  UserGrantDto,
} from "client/api/UserGrantApi";

export function isCloudSubscriptionUserGrantDto(
  userGrantDto: UserGrantDto
): userGrantDto is CloudSubscriptionUserGrantDto {
  return (userGrantDto as CloudSubscriptionUserGrantDto).cloudSubscriptionId !== undefined;
}

export function isSubscriptionGroupUserGrantDto(
  userGrantDto: UserGrantDto
): userGrantDto is SubscriptionGroupUserGrantDto {
  return (userGrantDto as SubscriptionGroupUserGrantDto).subscriptionGroupId !== undefined;
}
