import React from "react";
import { Link } from "@mui/material";

const FormattedEmail = ({ email }: { email: string }) => {
  return (
    <Link underline={"none"} fontWeight={500} target="_blank" href={`mailto:${email}`}>
      {email}
    </Link>
  );
};

/**
 * Enhances plain text transforming email addresses in to mailto links
 * @param root0
 * @param root0.message Plain text message
 */
export const EnhanceMessage = ({ message }: { message: string }) => {
  const emailAddresses = ["sales@octopus.com", "support@octopus.com"];
  const emailRegex = new RegExp(`(${emailAddresses.join("|")})`, "g");
  const parts = message.split(emailRegex);

  return (
    <>
      {parts.map((part, index) => {
        if (emailAddresses.includes(part)) {
          return <FormattedEmail email={part} key={`part-${index}`} />;
        }
        return <React.Fragment key={`part-${index}`}>{part}</React.Fragment>;
      })}
    </>
  );
};
