import React from "react";
import type { SxProps, Theme } from "@mui/material";
import { SvgIcon } from "@mui/material";

interface CheckoutLockIconProps {
  error?: boolean;
  size?: string;
  sx?: SxProps<Theme>;
}

export function CheckoutLockIcon(props: CheckoutLockIconProps) {
  const { size = "20px", sx } = props;

  return (
    <SvgIcon
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: size,
        height: size,
        ...sx,
      }}
      fill="none"
    >
      <path
        id="shape"
        d="M7.5 6V8H12.5V6C12.5 4.625 11.375 3.5 10 3.5C8.59375 3.5 7.5 4.625 7.5 6ZM6 8V6C6 3.8125 7.78125 2 10 2C12.1875 2 14 3.8125 14 6V8H15C16.0938 8 17 8.90625 17 10V16C17 17.125 16.0938 18 15 18H5C3.875 18 3 17.125 3 16V10C3 8.90625 3.875 8 5 8H6ZM4.5 10V16C4.5 16.2812 4.71875 16.5 5 16.5H15C15.25 16.5 15.5 16.2812 15.5 16V10C15.5 9.75 15.25 9.5 15 9.5H5C4.71875 9.5 4.5 9.75 4.5 10Z"
      />
    </SvgIcon>
  );
}
