import type { ReactNode } from "react";
import React, { createContext, useContext } from "react";
import type { FeatureFlag } from "contexts/ConfigurationProvider/FeatureFlag";
import useAsyncRequest from "hooks/useAsyncRequest";
import type { ConfigurationResponseDto } from "client/api/ConfigurationApi";
import { getConfiguration, getFeatureFlags } from "client/api/ConfigurationApi";

export interface ConfigurationState {
  configuration: ConfigurationResponseDto | undefined;
  isFeatureFlagEnabled(flag: FeatureFlag): boolean;
  refresh: () => void;
}

interface ConfigurationProviderProps {
  children: ReactNode;
}
const ConfigurationProviderContext = createContext<ConfigurationState>({
  configuration: undefined,
  isFeatureFlagEnabled: (flag: FeatureFlag) => {
    return false;
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refresh: () => {},
});

export const useConfiguration = () => {
  return useContext(ConfigurationProviderContext);
};

export function ConfigurationProvider(props: ConfigurationProviderProps) {
  const { children } = props;
  const { data: configuration, refresh: refreshConfiguration } = useAsyncRequest(getConfiguration);
  const { data: featureFlags = {}, refresh: refreshFeatureFlags } = useAsyncRequest(getFeatureFlags);

  const isFeatureFlagEnabled = (flag: FeatureFlag) => {
    return featureFlags[flag] ?? false;
  };

  const refresh = () => {
    refreshConfiguration();
    refreshFeatureFlags();
  };

  return (
    <ConfigurationProviderContext.Provider value={{ configuration, isFeatureFlagEnabled, refresh }}>
      {children}
    </ConfigurationProviderContext.Provider>
  );
}

export const useFeatureFlag = (flag: FeatureFlag) => {
  const { isFeatureFlagEnabled } = useConfiguration();
  return isFeatureFlagEnabled(flag);
};
