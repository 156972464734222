import React from "react";
import { OpenInNew } from "@mui/icons-material";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import { getHostname } from "utils/formatters/DnsFormatters";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { CloudSubscriptionStatusIcon } from "components/Dashboard/CloudSubscriptions/CloudSubscriptionStatusIcon";
import { useContactSalesDialog } from "components/Dialogs/ContactSalesDialog/ContactSalesDialogProvider";
import { themeTokens } from "components/Theme/theme";
import { UserCanAccessCloudInstance } from "areas/cloudSubscriptions/components/UserCanAccessCloudInstance";
import { ContactSalesSuccessAlert } from "areas/serverLicenses/detail/components/ContactSalesSuccessAlert";

interface CloudSubscriptionHeaderProps {
  pageName: string;
  actions?: React.ReactNode[];
}

export function CloudSubscriptionHeader(props: CloudSubscriptionHeaderProps) {
  const { pageName, actions } = props;
  const { entity: cloudSubscription } = useEntity<CloudSubscriptionDto>();
  const { submittedData } = useContactSalesDialog();

  const hostname = cloudSubscription && getHostname(cloudSubscription.dnsPrefix);

  if (!cloudSubscription) return <></>;

  return (
    <>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Box>
              <Stack direction="row" alignItems="center" spacing={1}>
                <CloudSubscriptionStatusIcon cloudSubscription={cloudSubscription} />
                <Typography variant={"h5"} color={themeTokens.color.text.secondary}>
                  {cloudSubscription.friendlyName}
                </Typography>
                <Typography variant={"h5"} color={themeTokens.color.text.primary} fontWeight={600}>
                  {pageName}
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item md={6} display="flex" flexDirection="row" justifyContent="end" alignItems="center">
            <Box>
              <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
                {actions}
                <UserCanAccessCloudInstance subscription={cloudSubscription}>
                  <Button
                    variant="outlined"
                    disableRipple={true}
                    target="_blank"
                    href={`https://${hostname}`}
                    size={"small"}
                    endIcon={<OpenInNew />}
                  >
                    {hostname}
                  </Button>
                </UserCanAccessCloudInstance>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {submittedData && (
        <Box m={2} mt={0}>
          <ContactSalesSuccessAlert reason={submittedData.reason} />
        </Box>
      )}
    </>
  );
}
