import React, { useState } from "react";
import type { SelectChangeEvent } from "@mui/material";
import { Box, Grid, List, ListItem, Typography } from "@mui/material";
import type { DateTime } from "luxon";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import { IsStripeSubscriptionLicensingChannel } from "utils/CloudSubscriptionLicensingChannelTypeHelpers";
import { ExternalUriMap } from "utils/ExternalUriMap";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { UpgradePlan } from "client/api/UpgradePlan";
import { PricingCardCurrentPlanChip } from "components/PricingCard/PricingCardCurrentPlanChip";
import {
  FeatureItemDivider,
  FeatureListDivider,
  FeatureListItemText,
} from "components/PricingCard/PricingCardFeatureListElements";
import { PricingCardFeatureListItem } from "components/PricingCard/PricingCardFeatureListItem";
import { PricingCardPriceSelector } from "components/PricingCard/PricingCardPriceSelector";
import { PricingCardTitle } from "components/PricingCard/PricingCardTitle";
import type { CatalogProduct } from "areas/cloudSubscriptions/detail/CloudSubscriptionUpgrade";
import { PlanTestId } from "areas/cloudSubscriptions/detail/CloudSubscriptionUpgrade";
import { PricingCardAction, getUpgradeAction } from "./PricingCardAction";

const PricingCardPriceCode = UpgradePlan.Cloud_Annually_Starter;
const PlanName = "Starter";
const planStyles = {
  borderRadius: "10px",
  background: "#FFF",
  boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.08)",
};
const currentPlanStyles = { borderRadius: "10px", background: "#FFF", border: "1px solid #D4DCE3" };

export function StarterPricingCard({ catalog }: { catalog: CatalogProduct }) {
  const { entity: cloudSubscription } = useEntity<CloudSubscriptionDto>();
  const [selectedProjects, setSelectedProjects] = useState(catalog?.prices[0]?.projectQuantity || "");

  if (!cloudSubscription) return <></>;

  const upgradeAction = getUpgradeAction(PricingCardPriceCode, cloudSubscription.licensingChannel);
  const isCurrentPlan = upgradeAction === "no-change";

  const changedProductSelectionHandler = (e: SelectChangeEvent<unknown>) => {
    if (typeof e.target.value === "string" || typeof e.target.value === "number") {
      setSelectedProjects(e.target.value.toString());
    }
  };

  let nextRenewalDate: DateTime | undefined = undefined;
  if (IsStripeSubscriptionLicensingChannel(cloudSubscription.licensingChannel)) {
    nextRenewalDate = cloudSubscription.licensingChannel?.nextRenewalDate;
  }

  const features = [
    [
      <FeatureListItemText>10 projects, tenants, and machines</FeatureListItemText>,
      <FeatureListItemText>Unlimited users</FeatureListItemText>,
      <FeatureListItemText>Default of 5 concurrent tasks</FeatureListItemText>,
      <FeatureListItemText>1TB file storage</FeatureListItemText>,
      <FeatureListItemText>100GB database storage</FeatureListItemText>,
    ],
    [
      <FeatureListItemText>Runbook automation</FeatureListItemText>,
      <FeatureListItemText>Environment progression</FeatureListItemText>,
      <FeatureListItemText>SSO and RBAC</FeatureListItemText>,
    ],
  ];

  return (
    <Grid
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={isCurrentPlan ? currentPlanStyles : planStyles}
      data-testid={PlanTestId.Starter}
    >
      <Grid container flexDirection="column" alignItems="stretch">
        <Grid container padding="1.3rem" sx={{ position: "relative" }} data-testid={`${PlanTestId.Starter}-header`}>
          <PricingCardCurrentPlanChip isCurrentPlan={isCurrentPlan} />
          <PricingCardTitle title={PlanName} />
          <PricingCardPriceSelector
            catalog={catalog}
            selectedProjects={selectedProjects}
            onChange={changedProductSelectionHandler}
          />
          <Grid container>
            <Grid item xs={12}>
              <PricingCardAction
                cloudSubscriptionId={cloudSubscription.id}
                action={upgradeAction}
                targetPlan={UpgradePlan.Cloud_Annually_Starter}
                targetPlanName={PlanName}
                nextRenewalDate={nextRenewalDate}
                projectQuantity={selectedProjects}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <List disablePadding={true}>
            {features.map((list, listIndex) => (
              <ListItem key={`${listIndex}`} disablePadding sx={{ display: "block" }}>
                <List
                  sx={{
                    paddingTop: "0px",
                    paddingBottom: "4px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {listIndex < features.length && <FeatureListDivider />}
                  {listIndex === 0 && (
                    <>
                      <PricingCardFeatureListItem
                        feature={
                          <Typography variant="body1" flexGrow="1">
                            <Box
                              component="a"
                              href={ExternalUriMap.octopusComPricingFeaturesPath}
                              target="_blank"
                              fontWeight="fontWeightRegular"
                              sx={{ textDecoration: "underline", color: "#11181D" }}
                            >
                              All core features
                            </Box>
                          </Typography>
                        }
                      />
                      <FeatureItemDivider />
                    </>
                  )}
                  {list.map((feature, index) => (
                    <React.Fragment key={`${listIndex}-${index}`}>
                      <PricingCardFeatureListItem feature={feature} />
                      {index < list.length - 1 && <FeatureItemDivider />}
                    </React.Fragment>
                  ))}
                </List>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
}
