import React from "react";
import CloudIcon from "@mui/icons-material/Cloud";
import HelpIcon from "@mui/icons-material/Help";
import type { SxProps, Theme } from "@mui/material";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import { DateTime, Duration } from "luxon";
import { CloudSubscriptionStatus } from "utils/CloudSubscription/CloudSubscriptionStatus";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { AssumedInstanceProvisioningState } from "client/api/CloudSubscriptionApi";
import { themeTokens } from "components/Theme/theme";

interface CloudSubscriptionProps {
  cloudSubscription: CloudSubscriptionDto;
  size?: "large" | "small" | "inherit" | "medium";
  sx?: SxProps<Theme>;
}

const getAssumedProvisioningState = (cloudSubscription: CloudSubscriptionDto) => {
  if (cloudSubscription.isInstanceProvisioned) return AssumedInstanceProvisioningState.Succeeded;

  const now = DateTime.utc();

  return now.diff(cloudSubscription.createdDate, "minute") <= Duration.fromISO("PT10M") // InstanceProvisioningFailureTimeouts.InitialProvision
    ? AssumedInstanceProvisioningState.Provisioning
    : AssumedInstanceProvisioningState.Failed;
};

export function CloudSubscriptionStatusIcon(props: CloudSubscriptionProps) {
  const { cloudSubscription, size = "large", sx } = props;

  const assumedProvisioningState = getAssumedProvisioningState(cloudSubscription);

  let color: string = themeTokens.color.icon.link.default;
  let icon = <CloudIcon fontSize={size} />;
  let tooltipText = "This cloud subscription is active";

  switch (cloudSubscription.cloudSubscriptionStatus) {
    case CloudSubscriptionStatus.Subscribed:
      if (assumedProvisioningState == AssumedInstanceProvisioningState.Succeeded) break; //it's still provisioning and we don't assume it's failed

      icon = <CircularProgress size={size == "medium" ? "24px" : undefined} />;
      tooltipText = "This cloud subscription is still provisioning";
      break;
    case CloudSubscriptionStatus.Lapsed:
      color = themeTokens.color.icon.danger;
      tooltipText = "This cloud subscription has a payment issue";
      break;

    case CloudSubscriptionStatus.Cancelled:
      color = themeTokens.color.icon.disabled;
      tooltipText = "This cloud subscription has been cancelled";
      break;

    case CloudSubscriptionStatus.Terminated:
      color = "";
      tooltipText = "This cloud subscription has been deleted";
      break;

    case CloudSubscriptionStatus.None:
    default:
      icon = <HelpIcon fontSize={size} />;
      color = themeTokens.color.icon.warning;
      tooltipText = "This cloud subscription is in an unknown state, please contact support";
      break;
  }

  return (
    <Tooltip title={tooltipText}>
      <Box sx={{ color, height: "35px", ...sx }}>{icon}</Box>
    </Tooltip>
  );
}
