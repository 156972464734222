import React from "react";
import { Box, Divider, Typography, styled } from "@mui/material";

export const FeatureListItemText = styled(Typography)(
  ({ theme }) => `
  font-size: 0.9375rem;
  line-height: 1.4;
  margin-top: 3px;
  margin-right: -8px;
  white-space: normal;
  flex-grow: 1;
  display: flex;
  font-family: ${theme.typography.body1.fontFamily};
  font-weight: ${theme.typography.fontWeightRegular};
  p {
    line-height: 1.4;
  }
`
);

const StyledMiddleDivider = styled(Divider)`
  border-color: #e6e8ea;
  margin-bottom: 5px;
`;
const StyledFullWidthDivider = styled(Divider)`
  border-color: #e6e8ea;
  margin-bottom: 5px;
`;

const MiddleLiDivider = () => {
  return (
    <Box component="li">
      <StyledMiddleDivider aria-hidden="true" variant="middle" />
    </Box>
  );
};

export const FeatureListDivider = () => {
  return (
    <Box component="li">
      <StyledFullWidthDivider aria-hidden="true" variant="fullWidth" />
    </Box>
  );
};

export const FeatureItemDivider = styled(MiddleLiDivider)(({ theme }) => ({
  marginLeft: "1.3rem",
  marginRight: "1.3rem",
  borderColor: "#E6E8EA",
}));
