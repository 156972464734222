import { colorScales } from "@octopusdeploy/design-system-tokens";
import { themeTokens } from "components/Theme/theme";

export const LightStyle: { [key: string]: React.CSSProperties } = {
  "hljs-comment": {
    color: colorScales.slate[300],
  },
  "hljs-quote": {
    color: colorScales.slate[300],
  },
  "hljs-variable": {
    color: colorScales.slate[800],
  },
  "hljs-template-variable": {
    color: colorScales.slate[800],
  },
  "hljs-tag": {
    color: colorScales.slate[800],
  },
  "hljs-name": {
    color: colorScales.slate[800],
  },
  "hljs-selector-id": {
    color: colorScales.slate[800],
  },
  "hljs-selector-class": {
    color: colorScales.slate[800],
  },
  "hljs-regexp": {
    color: colorScales.slate[800],
  },
  "hljs-deletion": {
    color: colorScales.slate[800],
  },
  "hljs-code": {
    color: colorScales.slate[800],
  },
  "hljs-attr": {
    color: colorScales.blue[600],
  },
  "hljs-number": {
    color: colorScales.orange[600],
  },
  "hljs-built_in": {
    color: colorScales.orange[600],
  },
  "hljs-builtin-name": {
    color: colorScales.orange[600],
  },
  "hljs-literal": {
    color: colorScales.orange[600],
  },
  "hljs-type": {
    color: colorScales.orange[600],
  },
  "hljs-params": {
    color: colorScales.orange[600],
  },
  "hljs-meta": {
    color: colorScales.orange[600],
  },
  "hljs-link": {
    color: colorScales.orange[600],
  },
  "hljs-attribute": {
    color: colorScales.orange[600],
  },
  "hljs-string": {
    color: colorScales.green[600],
  },
  "hljs-symbol": {
    color: colorScales.green[600],
  },
  "hljs-bullet": {
    color: colorScales.green[600],
  },
  "hljs-property": {
    color: colorScales.green[600],
  },
  "hljs-addition": {
    color: colorScales.green[600],
  },
  "hljs-title": {
    color: "#007faa",
  },
  "hljs-section": {
    color: "#007faa",
  },
  "hljs-keyword": {
    color: "#7928a1",
  },
  "hljs-selector-tag": {
    color: "#7928a1",
  },
  hljs: {
    display: "block",
    overflowX: "auto",
    background: themeTokens.color.background.secondary.default,
    color: colorScales.slate[800],
    padding: "0.5em",
  },
  "hljs-emphasis": {
    fontStyle: "italic",
  },
  "hljs-strong": {
    fontWeight: "bold",
  },
};
