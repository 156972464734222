import React from "react";
import type { LinkProps } from "@mui/material";
import { Link as MuiLink, styled } from "@mui/material";
import { themeTokens } from "components/Theme/theme";

const StyledLink = styled(MuiLink)(
  ({ fontWeight, color }) => `
  cursor: pointer;
  font-weight: ${fontWeight ?? 500};
  color: ${color ?? themeTokens.color.text.link.default};
  &:hover {
    color: ${themeTokens.color.text.link.hover};
  }
  &:focus {
    outline-color: revert;
  }
`
);

/**
 * A link with an href
 * @param props
 */
export const Link = (props: LinkProps) => {
  return <StyledLink {...props}>{props.children}</StyledLink>;
};
