import React from "react";
import type { SxProps, Theme } from "@mui/material";
import { Alert, Link } from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import { ExternalUriMap } from "utils/ExternalUriMap";

type OldControlCenterBannerProps = {
  sx?: SxProps<Theme>;
};

export function OldControlCenterBanner(props: OldControlCenterBannerProps) {
  const { sx } = props;
  return (
    <Alert severity={"info"} sx={sx}>
      <AlertTitle>Having trouble finding a license or instance?</AlertTitle>
      If it hasn't been purchased or renewed recently, it may still be in{" "}
      <Link
        underline={"none"}
        fontWeight={500}
        href={`${ExternalUriMap.octopusComControlCenterV1RootPath}`}
        target="_blank"
      >
        Control Center V1
      </Link>
      .
    </Alert>
  );
}
