import React, { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton, Link, Paper, Typography } from "@mui/material";
import { DateTime, Duration } from "luxon";
import { DateText } from "@cpt/components";
import useLocalStorage from "@cpt/hooks/useLocalStorage";
import { ExternalUriMap } from "utils/ExternalUriMap";
import { ServerLicensesRoutesMap } from "areas/serverLicenses/ServerLicensesRoutesMap";

type RenewSuccessBannerProps = {
  id: string;
  nextRenewalDate: DateTime;
};

export type RenewedProducts = Record<string, string>;
export const RENEWED_PRODUCTS_KEY = "renewed_products";

export function RenewSuccessBanner(props: RenewSuccessBannerProps) {
  const { id, nextRenewalDate } = props;

  const [renewedProducts, setRenewedProducts] = useLocalStorage<RenewedProducts>(RENEWED_PRODUCTS_KEY, {});

  const removeFromLocalStorage = () => {
    const updated = {
      ...renewedProducts,
    };

    delete updated[id];

    //remove this item from
    setRenewedProducts(updated);
  };

  const daysTillRenewal = useMemo(() => {
    const diff = nextRenewalDate.diffNow("days");

    return Math.ceil(diff.days);
  }, [nextRenewalDate]);

  const daysTillRenewalText = `${daysTillRenewal} day${daysTillRenewal !== 1 ? "s" : ""}`;

  //if there is no record for this id, don't render anything
  const renewDate = renewedProducts[id];
  if (!renewDate) {
    return null;
  }

  //if we upgrade more than 1 day ago, remove from local storage and don't render
  const diffFromNow = DateTime.now().diff(DateTime.fromISO(renewDate), "milliseconds").as("milliseconds");
  const oneDay = Duration.fromObject({ days: 1 }).as("milliseconds");
  if (diffFromNow > oneDay) {
    removeFromLocalStorage();
    return null;
  }

  return (
    <Paper sx={{ p: 2, position: "relative" }}>
      <IconButton
        aria-label="close"
        size="small"
        sx={{ position: "absolute", top: "16px", right: "16px" }}
        onClick={removeFromLocalStorage}
      >
        <CloseIcon />
      </IconButton>
      <Grid container>
        <Grid item xs>
          <Typography variant="h6" fontWeight="bold" mb={2} mr={{ xs: 4, lg: 0 }}>
            <CheckCircleIcon color={"success"} sx={{ verticalAlign: "sub", mr: 1 }} />
            Annual auto-renewal is set up
          </Typography>
          <Typography mb={2}>
            <b>Your license will be automatically renewed and charged in {daysTillRenewalText}</b> (
            <DateText date={nextRenewalDate} />
            ). You can review your license details on the{" "}
            <RouterLink to={ServerLicensesRoutesMap.detail(id).finance} style={{ fontWeight: 500 }}>
              Finance
            </RouterLink>{" "}
            page. Upon renewal, we’ll send a reminder email to update your license key XML.
          </Typography>
          <Typography>
            This Server license has been moved to Control Center V2. If you have licenses that haven't been purchased or
            renewed recently, they may be in{" "}
            <Link
              underline={"none"}
              fontWeight={500}
              href={`${ExternalUriMap.octopusComControlCenterV1RootPath}`}
              target="_blank"
            >
              Control Center V1
            </Link>
            .
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
