import React from "react";
import type { ReactElement } from "react";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import { IsSubscribedOrLapsed } from "utils/CloudSubscription/CloudSubscriptionStatus";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { RbacPermissions } from "components/authorization/RbacPermissions";

type UserCanAccessCloudInstanceProps = React.PropsWithChildren<{
  subscription: CloudSubscriptionDto;
  withoutAccess?: ReactElement;
}>;

export function UserCanAccessCloudInstance(props: UserCanAccessCloudInstanceProps) {
  const { subscription } = props;
  const { hasPermissionForAsset } = useUserContext();

  const isSubscriptionActive = IsSubscribedOrLapsed(subscription?.cloudSubscriptionStatus);

  const isInstanceUser = hasPermissionForAsset(
    RbacPermissions.CloudSubscription.AuthenticateToInstanceAsUser,
    subscription?.id,
    subscription?.subscriptionGroup?.id
  );
  const isInstanceAdmin = hasPermissionForAsset(
    RbacPermissions.CloudSubscription.AuthenticateToInstanceAsAdmin,
    subscription?.id,
    subscription?.subscriptionGroup?.id
  );
  const canAccessCloudInstance = isSubscriptionActive && (isInstanceUser || isInstanceAdmin);

  return <>{canAccessCloudInstance ? props.children : props.withoutAccess}</>;
}
