import React from "react";
import { NavLink } from "react-router-dom";
import { Link, Stack, Typography } from "@mui/material";
import { envConfig } from "utils/envConfig";
import { Help } from "components/PageHeading/Help";
import { UserInfo } from "components/PageHeading/UserInfo";
import { themeTokens } from "components/Theme/theme";
import { CloudSubscriptionsRoutesMap } from "areas/cloudSubscriptions/CloudSubscriptionRoutesMap";

export function ControlCenterHeader() {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" paddingBottom={4}>
      <Stack direction={"row"}>
        <Link href={`${envConfig.OctopusComDomain}`} paddingRight={1}>
          <div className={"octopus-logo"} />
        </Link>
        <Link component={NavLink} underline={"none"} to={CloudSubscriptionsRoutesMap.root}>
          <Typography variant={"h5"} paddingTop={"4px"}>
            Control Center
            <Typography
              component="span"
              sx={{
                color: themeTokens.color.text.link.default,
                display: "inline",
                marginLeft: "0.5rem",
                fontWeight: "normal",
                fontSize: "1.25rem",
              }}
            >
              V2
            </Typography>
          </Typography>
        </Link>
      </Stack>
      <Stack direction="row" alignItems="center">
        <UserInfo />
        <Help />
      </Stack>
    </Stack>
  );
}
