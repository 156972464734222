import axiosClient from "client/axios-client";
import type { RbacPermission } from "components/authorization/RbacPermissions";

export type UserDto = {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  profileImage: string | undefined;
  permissions: Record<string, RbacPermission[]>;
};

const BASE_URL = "user";

export const getUserInfo = async (): Promise<UserDto> => {
  const response = await axiosClient.get<UserDto>(BASE_URL, {});
  return response.data;
};
