import { ServerLicensingChannelTypesArr, ServerLicensingChannelTypesKeys } from "client/api/ServerLicenseApi";
import type {
  ServerLicensingChannel,
  ServerLicensingChannelBase,
  ServerLicensingChannelTypes,
  StripeSubscriptionServerLicensingChannelDto,
  TrialServerLicensingChannelDto,
} from "client/api/ServerLicenseApi";
import type { UpgradePlan } from "client/api/UpgradePlan";

export function IsServerLicenseStripeSubscriptionLicensingChannel(
  licensingChannel: ServerLicensingChannel
): licensingChannel is StripeSubscriptionServerLicensingChannelDto {
  return licensingChannel.channelType === ServerLicensingChannelTypesKeys.Stripe;
}

export function isServerLicenseTrialLicensingChannel(
  licensingChannel: ServerLicensingChannel
): licensingChannel is TrialServerLicensingChannelDto {
  return licensingChannel.channelType === ServerLicensingChannelTypesKeys.Trial;
}

export function isServerLicenseChannelType(
  licensingChannelType: string
): licensingChannelType is ServerLicensingChannelTypes {
  const arr: string[] = ServerLicensingChannelTypesArr;
  return arr.includes(licensingChannelType);
}

export function hasStatus(
  licensingChannel: ServerLicensingChannelBase & { status?: string }
): licensingChannel is Required<ServerLicensingChannelBase & { status: string }> {
  return typeof licensingChannel.status === "string";
}

export function hasPriceCode(
  licensingChannel: ServerLicensingChannelBase & { priceCode?: string }
): licensingChannel is Required<ServerLicensingChannelBase & { priceCode: UpgradePlan }> {
  return typeof licensingChannel.priceCode === "string";
}
