import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import { Box, IconButton, Chip, CircularProgress, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import type { CloudSubscriptionOrSubscriptionGroupUserGrantDto, UserGrantDto } from "client/api/UserGrantApi";
import { themeTokens } from "components/Theme/theme";

export type UserGrantRowProps<TUserGrant extends UserGrantDto> = {
  userGrant: TUserGrant;
  showDelete: boolean;
  onDeleteClicked: () => void | Promise<void>;
  loading?: boolean;
};

export function UserGrantRow<TUserGrant extends CloudSubscriptionOrSubscriptionGroupUserGrantDto>(
  props: UserGrantRowProps<TUserGrant>
) {
  const { userGrant, showDelete, onDeleteClicked, loading } = props;

  const friendlyRoleName = (
    <Box>
      {userGrant.role.name}
      {(userGrant.grantType === "SubscriptionGroupUserGrant" ||
        userGrant.grantType === "SubscriptionGroupUserGrantProxy") && (
        <Tooltip title="This user is inherited from the Subscription Group. To manage this user, navigate back to the Dashboard and use the Access Controls for your Subscription Group.">
          <Chip sx={{ marginLeft: 1 }} color={"info"} variant={"outlined"} size={"small"} label={"Inherited"} />
        </Tooltip>
      )}
    </Box>
  );

  return (
    <TableRow>
      <TableCell>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <PersonIcon sx={{ color: themeTokens.color.text.secondary, fontSize: 30, marginRight: "0.5rem" }} />
          <Typography variant="body1" sx={{ ml: 1, fontWeight: "bold" }}>
            {userGrant.user.firstName} {userGrant.user.lastName}
            <Typography variant={"caption"} fontSize={"smaller"} display={"block"}>
              {userGrant.user.email}
            </Typography>
          </Typography>
        </Box>
      </TableCell>
      <TableCell>{friendlyRoleName}</TableCell>
      <TableCell align={"right"}>
        {showDelete && <DeleteUser onDeleteClicked={onDeleteClicked} loading={loading} />}
      </TableCell>
    </TableRow>
  );
}

type DeleteUserProps = {
  onDeleteClicked: () => void | Promise<void>;
  loading?: boolean;
};

function DeleteUser({ onDeleteClicked, loading }: DeleteUserProps) {
  return loading ? (
    <CircularProgress size={18} sx={{ marginRight: 1 }} />
  ) : (
    <IconButton component="label" onClick={onDeleteClicked} aria-label="delete-user">
      <DeleteIcon />
    </IconButton>
  );
}
