import React from "react";
import { Grid, Table, TableBody, TableRow, TableCell, Typography } from "@mui/material";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import type { IntLimitFeatureDto } from "client/api/FeatureApi";
import type { ServerLicenseDto } from "client/api/ServerLicenseApi";

export type LicensedEntitlementsViewProps = { license: ServerLicenseDto | CloudSubscriptionDto };

export function LicensedEntitlementsView(props: LicensedEntitlementsViewProps) {
  const { license } = props;

  const EntitlementRow = ({ item, label }: { item: IntLimitFeatureDto | undefined; label: string }) => {
    let limitText: string = "Unlimited";
    if (!!item?.limit && !item?.isUnlimited) {
      limitText = item.limit.toFixed(0);
    }
    return (
      <TableRow>
        <TableCell sx={{ padding: 0, paddingRight: "12px", border: "none" }}>
          <Typography>{label}</Typography>
        </TableCell>
        <TableCell sx={{ padding: 0, border: "none", width: "99%" }}>
          <Typography>{limitText}</Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Grid item sm={3} sx={{ padding: 3 }}>
        <Typography fontWeight={700}>Entitlements</Typography>
      </Grid>
      <Grid item sm={9} sx={{ padding: 3 }}>
        <Table>
          <TableBody>
            <EntitlementRow item={license.machineLimit} label="Machines:" />
            <EntitlementRow item={license.projectLimit} label="Projects:" />
            <EntitlementRow item={license.tenantLimit} label="Tenants:" />
          </TableBody>
        </Table>
      </Grid>
    </>
  );
}
