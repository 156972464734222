import type { PropsWithChildren } from "react";
import React, { useRef } from "react";
import { Fade } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import cn from "classnames";
import styles from "./style.module.less";

export type Placement =
  | "bottom-end"
  | "bottom-start"
  | "left-end"
  | "left-start"
  | "right-end"
  | "right-start"
  | "top-end"
  | "top-start";

type ContextualHelpProps = PropsWithChildren<{
  trigger: "click" | "hover";
  button?: React.ReactNode;
  startOpened?: boolean;
  placement: Placement;
  absolutePosition?: boolean; // this helps keep the icon interactive even when the parent element would otherwise take precedence
  onOpen?: () => void;
  forceHide?: boolean;
}>;

export const PopoverContext = React.createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closePopover: () => {},
});

const Popover = ({
  trigger,
  button,
  children,
  placement,
  absolutePosition = false,
  startOpened = false,
  onOpen,
  forceHide,
}: ContextualHelpProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const iconContainerRef = useRef<HTMLDivElement>(null);

  const toggleOpen = (target: EventTarget & HTMLElement) => {
    const isOpen = anchorEl !== null;
    setAnchorEl(isOpen ? null : target);
    if (!isOpen) onOpen?.();
  };

  const handleClick = (event: React.FormEvent<HTMLElement>) => {
    if (trigger !== "click") return;

    toggleOpen(event.currentTarget);
  };

  const handleSpace = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.keyCode === 32) {
      event.preventDefault();
      handleClick(event);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.keyCode === 32) {
      event.preventDefault();
    } else if (event.keyCode === 13) {
      event.preventDefault();
      handleClick(event);
    }
  };

  const handleMouseIn = (event: React.FormEvent<HTMLElement>) => {
    if (trigger !== "hover") return;

    if (anchorEl) {
      iconContainerRef.current?.blur();
    }

    toggleOpen(event.currentTarget);
  };

  const handleMouseOut = (event: React.FormEvent<HTMLElement>) => {
    if (trigger !== "hover") return;

    setAnchorEl(null);
    iconContainerRef.current?.blur();
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  let open = startOpened ? true : Boolean(anchorEl);
  if (forceHide) {
    open = false;
  }

  const id = open ? "transitions-popper" : undefined;

  const offsetValue = getOffsetValue(placement);

  const closePopover = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={styles.globalContainer}>
        <div
          className={cn(styles.iconContainer, { [styles.absolute]: absolutePosition })}
          aria-haspopup="true"
          aria-label="Display contextual help"
          role="button"
          tabIndex={0}
          aria-pressed="false"
          onClick={handleClick}
          onKeyUp={handleSpace}
          onKeyDown={handleKeyDown}
          onMouseEnter={handleMouseIn}
          onMouseLeave={handleMouseOut}
          ref={iconContainerRef}
        >
          <div className={styles.circle}>{button || <em className={cn("fa-solid fa-info", styles.infoIcon)} />}</div>
        </div>
        <Popper
          id={id}
          className={cn(styles.popper)}
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          modifiers={[
            { name: "offset", enabled: true, options: { offset: offsetValue } },
            {
              name: "flip",
              enabled: false,
              options: {
                altBoundary: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
            {
              name: "arrow",
              enabled: true,
              options: {
                element: arrowRef,
              },
            },
          ]}
          transition
          tabIndex={1}
          role="dialog"
          aria-modal="true"
        >
          {({ TransitionProps }) => (
            <>
              <div className={styles.arrow} ref={(ref) => setArrowRef(ref)} />
              <Fade {...TransitionProps} timeout={0}>
                <div className={cn(styles.paper, styles[placement])}>
                  <PopoverContext.Provider value={{ closePopover }}>{children}</PopoverContext.Provider>
                </div>
              </Fade>
            </>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

const getOffsetValue = (placement: Placement) => {
  switch (placement) {
    case "left-end":
      return [35, 15];
    case "left-start":
      return [-35, 15];
    case "right-end":
      return [35, 15];
    case "right-start":
      return [-30, 17];
    case "top-end":
      return [35, 15];
    case "top-start":
      return [-35, 15];
    case "bottom-end":
      return [35, 15];
    case "bottom-start":
      return [-35, 15];
  }
};

export default Popover;
