// TODO: replace this with the export from @octopusdeploy/design-system-components
// when that package can be installed. Context: https://octopusdeploy.slack.com/archives/C02PAUNL6J0/p1683699335495959
import type { PropsWithChildren } from "react";
import React from "react";
import type { OctopusTheme } from "@octopusdeploy/design-system-tokens";
import { darkTheme, lightTheme } from "@octopusdeploy/design-system-tokens";

export type ThemeName = "light" | "dark";

export interface GlobalThemeProps {
  themeName: ThemeName;
}

export function GlobalThemeApplier({ themeName }: PropsWithChildren<GlobalThemeProps>) {
  React.useEffect(() => {
    const theme: OctopusTheme = themeName === "light" ? lightTheme : darkTheme;
    const themeCssVariableProps = Object.entries(theme).map(
      ([cssVariableName, value]) => `--${cssVariableName}: ${value};`
    );

    const serializedCss = `:root {
${themeCssVariableProps.join("\n")}
}`;

    // We manually inject the styles ourselves rather than using injectGlobals from emotion
    // injectGlobals does not have a way to remove or replace previously injected styles, so changing themes causes a new global style to be added
    let styleElement = document.querySelector(`#${globalStyleNodeId}`);
    if (styleElement) {
      styleElement.innerHTML = serializedCss;
    } else {
      styleElement = document.createElement("style");
      styleElement.setAttribute("id", globalStyleNodeId);
      styleElement.innerHTML = serializedCss;
      document.head.appendChild(styleElement);
    }
  }, [themeName]);

  return null;
}

const globalStyleNodeId = "octopus-global-theme";
